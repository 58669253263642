.bar__requisites {
  margin-bottom: 8px;
  background: var(--back__tab-hover);
  color: var(--back);
  display: grid;
  grid-template-columns: 12px repeat(6, auto) 12px;
  grid-template-rows: 42px;
  grid-template-areas: ". area-1 area-2 area-3 area-4 area-5 area-6 .";
  align-items: center;
}

.bar__requisites > :nth-child(1) {
  grid-area: area-1;
	display: grid;
	grid-template-columns: 80px 14px auto;
	grid-template-areas:
		"area-1 area-2 area-3";
	align-items: center;
	justify-items: start;
}

.bar__requisites > :nth-child(1) > :nth-child(1) {
  grid-area: area-1;
  justify-self: end;
}

.bar__requisites > :nth-child(1) > :nth-child(2) {
  grid-area: area-2;
}

.bar__requisites > :nth-child(1) > :nth-child(3) {
  grid-area: area-3;
}

.bar__requisites > :nth-child(2){
  grid-area: area-2;
}

.bar__requisites > :nth-child(3){
  grid-area: area-3;
}

.bar__requisites > :nth-child(4){
  grid-area: area-4;
}

.bar__requisites > :nth-child(5){
  grid-area: area-5;
}

.bar__requisites > :nth-child(6){
  grid-area: area-6;
}


.bar__requisites > :nth-child(2),
.bar__requisites > :nth-child(3),
.bar__requisites > :nth-child(4),
.bar__requisites > :nth-child(5) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar__requisites > :nth-child(6) {
  justify-self: end;
  display: grid;
  grid-template-columns: 56px 20px 12px 20px;
  grid-template-areas: "area-1 area-2 area-3 area-4";
  align-items: center;
  justify-items: start;
}

.bar__requisites > :nth-child(6) > :nth-child(1) {
  grid-area: area-1;
}

.bar__requisites > :nth-child(6) > :nth-child(2) {
  grid-area: area-2;
}

.bar__requisites > :nth-child(6) > :nth-child(3) {
  grid-area: area-3;
}

.bar__requisites > :nth-child(6) > :nth-child(4) {
  grid-area: area-4;
}

.bar__requisites > :nth-child(6) > :nth-child(1) {
  font-family: 'thin';
}

.bar__requisites > :nth-child(6) > :nth-child(3) {
  justify-self: center;
}

.bar__requisites > :nth-child(6) > :nth-child(4) {
  justify-self: end;
}

