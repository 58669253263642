/*==================================/
  Страница Профиля (.page__profile)
/==================================*/

/*-----------------------------/
  Области основного содержания
/-----------------------------*/

.page__profile main {
  display: grid;
  grid-template-columns: auto 413px 414px 413px auto; /* =1240px */
  grid-template-rows: 34px max-content;
  grid-template-areas:
    ". child-2  child-4  child-6  ."
    ". child-n7 child-n7 child-n7 .";
}

/* :nth-child 1/3/5 — радио-кнопки, скрыты. */
/* :nth-child 2/4/6 — ярлыки вкладок. */
.page__profile main > :nth-child(2) {
  grid-area: child-2;
  justify-self: start;
}
.page__profile main > :nth-child(4) {
  grid-area: child-4;
  justify-self: center;
}
.page__profile main > :nth-child(6) {
  grid-area: child-6;
  justify-self: end;
}

/* :nth-child с 7 — содержание текущей вкладки. */
.page__profile main > :nth-child(n + 7) {
  grid-area: child-n7;
}

/*-----------------------------------------------/
  Вкладка «Учётные данные» (profile__content--1)	!!! ПЕРЕДЕЛАТЬ !!!
/-----------------------------------------------*/

/*--- Области секторов (наборы полей) и область кнопок ---*/

/*
	Ширина колонки = (1240px минус 24px отступы слева-справа) делим на 3 колонки.

	!!! Отступы лучше сделать областями-колонками «.»
*/

.page__profile #profile__content--1 {
  /* display: grid; добавляется в role-contractor-content.css при выводе вкладки. */
  grid-template-columns: 302px 302px 302px;
  grid-template-rows: max-content max-content max-content max-content max-content;
  row-gap: 16px;
  grid-template-areas:
    "child-1 child-1 child-1"
    "child-2 child-2 child-2"
    "child-3 child-3 child-3"
    "child-4 child-4 child-4"
    "child-5 child-6 child-7";
  justify-content: center;
}

.page__profile #profile__content--1 > :nth-child(1) {
  grid-area: child-1;
}
.page__profile #profile__content--1 > :nth-child(2) {
  grid-area: child-2;
}
.page__profile #profile__content--1 > :nth-child(3) {
  grid-area: child-3;
}
.page__profile #profile__content--1 > :nth-child(4) {
  grid-area: child-4;
}

.page__profile #profile__content--1 > :nth-child(5) {
  grid-area: child-5;
}
.page__profile #profile__content--1 > :nth-child(6) {
  grid-area: child-6;
  justify-self: center;
}
.page__profile #profile__content--1 > :nth-child(7) {
  grid-area: child-7;
  justify-self: end;
} /* Кнопки. */

/*--- Области внутри Секторов ---*/

.page__profile #profile__content--1 > :nth-child(1),
.page__profile #profile__content--1 > :nth-child(2),
.page__profile #profile__content--1 > :nth-child(3),
.page__profile #profile__content--1 > :nth-child(4) {
  display: grid;
  grid-template-columns: 108px 174px 118px 194px 118px 194px;
  row-gap: 12px;
  align-items: center;
}

/* «Аккаунт» */
/* «Ответственный от организации» */

.page__profile #profile__content--1 > :nth-child(1),
.page__profile #profile__content--1 > :nth-child(2) {
  grid-template-areas:
    "nth-child-1 nth-child-1 nth-child-1 nth-child-1 nth-child-1 nth-child-1"
    "nth-child-2 nth-child-3 nth-child-4 nth-child-5 nth-child-6 nth-child-7";
}

.page__profile #profile__content--1 > :nth-child(1) > :nth-child(1),
.page__profile #profile__content--1 > :nth-child(2) > :nth-child(1) {
  grid-area: nth-child-1;
}
.page__profile #profile__content--1 > :nth-child(1) > :nth-child(2),
.page__profile #profile__content--1 > :nth-child(2) > :nth-child(2) {
  grid-area: nth-child-2;
}
.page__profile #profile__content--1 > :nth-child(1) > :nth-child(3),
.page__profile #profile__content--1 > :nth-child(2) > :nth-child(3) {
  grid-area: nth-child-3;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(1) > :nth-child(4),
.page__profile #profile__content--1 > :nth-child(2) > :nth-child(4) {
  grid-area: nth-child-4;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(1) > :nth-child(5),
.page__profile #profile__content--1 > :nth-child(2) > :nth-child(5) {
  grid-area: nth-child-5;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(1) > :nth-child(6),
.page__profile #profile__content--1 > :nth-child(2) > :nth-child(6) {
  grid-area: nth-child-6;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(1) > :nth-child(7),
.page__profile #profile__content--1 > :nth-child(2) > :nth-child(7) {
  grid-area: nth-child-7;
  justify-self: end;
}

/* «Реквизиты организации» */

.page__profile #profile__content--1 > :nth-child(3) {
  grid-template-areas:
    "nth-child-1  nth-child-1  nth-child-1  nth-child-1  nth-child-1  nth-child-1 "
    "nth-child-2  nth-child-3  nth-child-3  nth-child-3  nth-child-3  nth-child-3 "
    "nth-child-4  nth-child-5  nth-child-6  nth-child-7  nth-child-8  nth-child-9 "
    "nth-child-10 nth-child-11 nth-child-12 nth-child-13 .            .           "
    "nth-child-14 nth-child-15 nth-child-15 nth-child-15 nth-child-15 nth-child-15";
}

.page__profile #profile__content--1 > :nth-child(3) > :nth-child(1) {
  grid-area: nth-child-1;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(2) {
  grid-area: nth-child-2;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(3) {
  grid-area: nth-child-3;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(4) {
  grid-area: nth-child-4;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(5) {
  grid-area: nth-child-5;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(6) {
  grid-area: nth-child-6;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(7) {
  grid-area: nth-child-7;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(8) {
  grid-area: nth-child-8;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(9) {
  grid-area: nth-child-9;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(10) {
  grid-area: nth-child-10;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(11) {
  grid-area: nth-child-11;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(12) {
  grid-area: nth-child-12;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(13) {
  grid-area: nth-child-13;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(14) {
  grid-area: nth-child-14;
}
.page__profile #profile__content--1 > :nth-child(3) > :nth-child(15) {
  grid-area: nth-child-15;
  justify-self: end;
}

/* «Подписант» */

.page__profile #profile__content--1 > :nth-child(4) {
  grid-template-areas:
    "nth-child-1  nth-child-1  nth-child-1  nth-child-1  nth-child-1  nth-child-1 "
    "nth-child-2  nth-child-3  nth-child-4  nth-child-5  nth-child-6  nth-child-7 "
    "nth-child-8  nth-child-9  nth-child-9  nth-child-10 nth-child-11 nth-child-11"
    "nth-child-12 nth-child-13 nth-child-13 nth-child-13 nth-child-13 nth-child-13"
    ".            nth-child-14 nth-child-14 nth-child-14 nth-child-15 nth-child-16";
}

.page__profile #profile__content--1 > :nth-child(4) > :nth-child(1) {
  grid-area: nth-child-1;
}

.page__profile #profile__content--1 > :nth-child(4) > :nth-child(2) {
  grid-area: nth-child-2;
}
.page__profile #profile__content--1 > :nth-child(4) > :nth-child(3) {
  grid-area: nth-child-3;
  justify-self: end;
}

.page__profile #profile__content--1 > :nth-child(4) > :nth-child(4) {
  grid-area: nth-child-4;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(4) > :nth-child(5) {
  grid-area: nth-child-5;
  justify-self: end;
}

.page__profile #profile__content--1 > :nth-child(4) > :nth-child(6) {
  grid-area: nth-child-6;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(4) > :nth-child(7) {
  grid-area: nth-child-7;
  justify-self: end;
}

.page__profile #profile__content--1 > :nth-child(4) > :nth-child(8) {
  grid-area: nth-child-8;
}
.page__profile #profile__content--1 > :nth-child(4) > :nth-child(9) {
  grid-area: nth-child-9;
  justify-self: end;
}

.page__profile #profile__content--1 > :nth-child(4) > :nth-child(10) {
  grid-area: nth-child-10;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(4) > :nth-child(11) {
  grid-area: nth-child-11;
  justify-self: end;
}

.page__profile #profile__content--1 > :nth-child(4) > :nth-child(12) {
  grid-area: nth-child-12;
}
.page__profile #profile__content--1 > :nth-child(4) > :nth-child(13) {
  grid-area: nth-child-13;
  justify-self: end;
}

.page__profile #profile__content--1 > :nth-child(4) > :nth-child(14) {
  grid-area: nth-child-14;
}
.page__profile #profile__content--1 > :nth-child(4) > :nth-child(15) {
  grid-area: nth-child-15;
  justify-self: end;
}
.page__profile #profile__content--1 > :nth-child(4) > :nth-child(16) {
  grid-area: nth-child-16;
  justify-self: end;
}

/*----------------------------------------/
  Вкладка «Прорабы» (profile__content--2)
/----------------------------------------*/

/*--- Области вкладки: кнопки / подписи колонок / область строк Прорабов ---*/

.page__profile #profile__content--2 {
  /* display: grid; добавляется в role-contractor-content.css при выводе вкладки. */
  grid-template-columns: 130px 100px 170px 250px 206px; /* =930px минус отступы слева-справа / 5. */
  /* grid-template-rows: max-content max-content; */
  row-gap: 8px;
  grid-template-areas:
    "nth-child-1 nth-child-2 .           .           .          "
    "nth-child-3 nth-child-3 nth-child-4 nth-child-5 nth-child-6"
    "nth-child-7 nth-child-7 nth-child-7 nth-child-7 nth-child-7";
  justify-content: center;
}

.page__profile #profile__content--2 > :nth-child(1) {
  grid-area: nth-child-1;
}
.page__profile #profile__content--2 > :nth-child(2) {
  grid-area: nth-child-2;
}
.page__profile #profile__content--2 > :nth-child(3) {
  grid-area: nth-child-3;
}
.page__profile #profile__content--2 > :nth-child(4) {
  grid-area: nth-child-4;
}
.page__profile #profile__content--2 > :nth-child(5) {
  grid-area: nth-child-5;
}
.page__profile #profile__content--2 > :nth-child(6) {
  grid-area: nth-child-6;
}
.page__profile #profile__content--2 > :nth-child(7) {
  grid-area: nth-child-7;
}

/*--- Области в строке Прораба ---*/

.page__profile #profile__content--2 > :nth-child(7) .line__foreman {
  display: grid;
  grid-template-columns: 230px 170px 250px 206px; /* =930px минус отступы слева-справа / 4. */
  grid-template-areas: "nth-child-1 nth-child-2 nth-child-3 nth-child-4";
  align-items: center;
}

.page__profile #profile__content--2 .line__foreman > :nth-child(1) {
  grid-area: nth-child-1;
}
.page__profile #profile__content--2 .line__foreman > :nth-child(2) {
  grid-area: nth-child-2;
}
.page__profile #profile__content--2 .line__foreman > :nth-child(3) {
  grid-area: nth-child-3;
}
.page__profile #profile__content--2 .line__foreman > :nth-child(4) {
  grid-area: nth-child-4;
}

/*-------------------------------------------------/
  Вкладка «Банковские счета» (profile__content--3)
/-------------------------------------------------*/

/*--- Области вкладки: кнопки / подписи колонок / область строк Счетов ---*/

.page__profile #profile__content--3 {
  /* display: grid; добавляется в role-contractor-content.css при выводе вкладки. */
  grid-template-columns: 130px 230px 205px 205px 105px 101px; /* =930px минус отступы слева-справа / 6. */
  /* grid-template-rows: max-content max-content; */
  row-gap: 8px;
  grid-template-areas:
    "nth-child-1 nth-child-2 .           .           .           .          "
    "nth-child-3 nth-child-3 nth-child-4 nth-child-5 nth-child-6 nth-child-7"
    "nth-child-8 nth-child-8 nth-child-8 nth-child-8 nth-child-8 nth-child-8";
  justify-content: center;
}

.page__profile #profile__content--3 > :nth-child(1) {
  grid-area: nth-child-1;
}
.page__profile #profile__content--3 > :nth-child(2) {
  grid-area: nth-child-2;
}
.page__profile #profile__content--3 > :nth-child(3) {
  grid-area: nth-child-3;
}
.page__profile #profile__content--3 > :nth-child(4) {
  grid-area: nth-child-4;
}
.page__profile #profile__content--3 > :nth-child(5) {
  grid-area: nth-child-5;
}
.page__profile #profile__content--3 > :nth-child(6) {
  grid-area: nth-child-6;
}
.page__profile #profile__content--3 > :nth-child(7) {
  grid-area: nth-child-7;
  justify-self: center;
}
.page__profile #profile__content--3 > :nth-child(8) {
  grid-area: nth-child-8;
}

/*--- Области в строке Счёта ---*/

.page__profile #profile__content--3 > :nth-child(8) .line__bank {
  display: grid;
  grid-template-columns: 360px 205px 205px 105px 101px; /* =930px минус отступы слева-справа / 5. */
  grid-template-areas: "nth-child-1 nth-child-2 nth-child-3 nth-child-4 nth-child-5";
  align-items: center;
}

.page__profile #profile__content--3 .line__bank > :nth-child(1) {
  grid-area: nth-child-1;
}
.page__profile #profile__content--3 .line__bank > :nth-child(2) {
  grid-area: nth-child-2;
}
.page__profile #profile__content--3 .line__bank > :nth-child(3) {
  grid-area: nth-child-3;
}
.page__profile #profile__content--3 .line__bank > :nth-child(4) {
  grid-area: nth-child-4;
}
.page__profile #profile__content--3 .line__bank > :nth-child(5) {
  grid-area: nth-child-5;
  justify-self: end;
}

/*------------------------------------/
  Панель вкладок основного содержания
/------------------------------------*/

/* Индикаторы радиокнопок вкладок скрыты (переключение по подписям). */
.page__profile main > input[type="radio"] {
  display: none;
}

.page__profile main > label {
  display: block;
  width: 308px; /* <=930px От ширины зависит расстояние между вкладками. */
  height: 34px;
  background: var(--text-head);
  padding-top: 7px;
  font-family: "thin";
  text-align: center;
  color: var(--back);
  cursor: pointer;

  transition: all 0.3s ease-in-out;
}

.page__profile main > label:hover {
  background: var(--back__tab-hover);
}

.page__profile main > input[type="radio"]:checked + label {
  background: var(--back-main);
  padding-top: 11px;
  font-family: "bold";
  color: var(--text-head);
}

/*--- Содержание, открываемое по вкладкам ---*/

/* Блоки содержания изначально скрыты. */
.page__profile main > [id^="profile__content--"] {
  /* z-index: -10; */
  display: none;
  background: var(--back-main);
  /* padding: 12px 16px 14px; */
}

/* Отображение содержания, связанного с выбранной вкладкой. */
.page__profile #profile__tab--1:checked ~ #profile__content--1,
.page__profile #profile__tab--2:checked ~ #profile__content--2,
.page__profile #profile__tab--3:checked ~ #profile__content--3 {
  display: grid; /* Области определяются в role-contractor-layout.css */
  padding-top: 12px;
  /* padding-bottom: 12px; */
}

.page__profile #profile__content--1 > :nth-child(n) {
  margin-bottom: 12px;
}

/*-------------------------/
  Вкладка «Учётные данные»
/-------------------------*/

/*--- Статус Учётных данных ---*/

/*
	Имя статуса выводится через псевдоэлемент <span>.
	Статус определяется классом <body>:
		status__profile--on-verify       —   «На проверке» (данные изменены, Сметчик ещё не проверил)
		status__profile--not-confirmed   —   «Не подтверждено» (Сметчик не принял новые данные)
		status__profile--confirmed       —   «Подтверждено» (Сметчик принял новые данные)
*/

/* Позиционирование статуса относительно ярлыка вкладки. */
.page__profile [for="profile__tab--1"] {
  position: relative;
}

.page__profile [for="profile__tab--1"] > span::before,
.page__profile [for="profile__tab--1"] > span::after {
  display: block;
  position: absolute;
  font-family: "bold";
  font-size: 12px;
}

/* Индикатор: Общее. */
.page__profile.status__profile--on-verify
  [for="profile__tab--1"]
  > span::before,
.page__profile.status__profile--not-confirmed
  [for="profile__tab--1"]
  > span::before,
.page__profile.status__profile--confirmed
  [for="profile__tab--1"]
  > span::before {
  content: "!";
  top: -4px;
  left: -4px;
  width: 12px;
  height: 18px;
  color: var(--back);
}

/* Индикатор: статусы «На проверке» и «Не подтверждено». */
.page__profile.status__profile--on-verify
  [for="profile__tab--1"]
  > span::before,
.page__profile.status__profile--not-confirmed
  [for="profile__tab--1"]
  > span::before {
  background: var(--red-site);
}

/* Индикатор: Статус «Подтверждено». */
.page__profile.status__profile--confirmed
  [for="profile__tab--1"]
  > span::before {
  background: var(--green-site);
}

/* Текст статуса (только если вкладка текущая). */

/* Анимация (начальное состояние). */
.page__profile.status__profile--on-verify [for="profile__tab--1"] > span::after,
.page__profile.status__profile--not-confirmed
  [for="profile__tab--1"]
  > span::after,
.page__profile.status__profile--confirmed
  [for="profile__tab--1"]
  > span::after {
  content: "";
  opacity: 0;
  top: -10px;
  left: -50px;
  transition: all 1s ease-in-out;
}

/* Общее (выводим под текст заголовка). */
.page__profile.status__profile--on-verify
  :checked
  + [for="profile__tab--1"]
  > span::after,
.page__profile.status__profile--not-confirmed
  :checked
  + [for="profile__tab--1"]
  > span::after,
.page__profile.status__profile--confirmed
  :checked
  + [for="profile__tab--1"]
  > span::after {
  opacity: 1;
  top: 26px;
  left: 94px;
  width: 120px;
  height: 18px;
}

/* Статус «На провекре». */
.page__profile.status__profile--on-verify
  :checked
  + [for="profile__tab--1"]
  > span::after {
  content: "На проверке";
  color: var(--error-dark);
}

/* Статус «Не подтверждено». */
.page__profile.status__profile--not-confirmed
  :checked
  + [for="profile__tab--1"]
  > span::after {
  content: "Не подтверждено!";
  color: var(--error-dark);
}

/* Статус «Подтверждено». */
.page__profile.status__profile--confirmed
  :checked
  + [for="profile__tab--1"]
  > span::after {
  content: "Подтверждено";
  color: var(--accept-dark);
}

/*--- Заголовки секторов ---*/

.page__profile legend {
  background: var(--back__head-section);
  padding-top: 2px;
  padding-bottom: 3px;
  color: var(--text__head-section);
}

.page__profile legend {
  width: 100%;
  padding-left: 6px;
}

/*--- Поля ---*/

/* Адреса (длинные). */
.page__profile input[name="company-name"],
.page__profile input[name*="address"] {
  width: 796px;
}

/* «Должность» и «Основание» (средние). */
.page__profile input[name="office-job"],
.page__profile input[name*="office-basis"] {
  width: 290px;
}

/*---------------------------------------/
  Вкладки «Прорабы» и «Банковские счета»
/---------------------------------------*/

/*--- Кнопки ---*/

/* Текст кнопки при разблокировке. */
.page__profile button[class*="control__lock--"][class*="unlocking-"]::before {
  content: "Разблокировать";
}

/*--- Строка подписей колонок ---*/

.page__profile #profile__content--2 > span,
.page__profile #profile__content--3 > span {
  font-family: "thin";
}

/*--- Строки Прорабов/Счетов ---*/

/* Контейнер, несущий элементы строки. */
.page__profile #profile__content--2 .container__line,
.page__profile #profile__content--3 .container__line {
  display: block;
}

/* Индикатор радиокнопки строки: скрыт (переключение кликом по строке). */
.page__profile #profile__content--2 [type="radio"],
.page__profile #profile__content--3 [type="radio"] {
  display: none;
}

.page__profile
#profile__content--2
.container__line
.line__foreman.blocked{
  background: var(--back);
}

.page__profile
#profile__content--2
.container__line
.line__foreman.checked,
.page__profile
#profile__content--3
.container__line
.line__bank.checked
{
  background: grey;
  color: white;
}

/* Чередование фона строк («Зебра»). */
.page__profile
  #profile__content--3
  .container__line
  .line__bank.checked {
  /*background: var(--back);*/
}

/* Расстояние под последней строкой. */
.page__profile #profile__content--2 .container__line:last-child,
.page__profile #profile__content--3 .container__line:last-child {
  margin-bottom: 18px;
}

/* Cтрока Прораба/Счета. */
.page__profile #profile__content--2 .line__foreman,
.page__profile #profile__content--3 .line__bank {
  margin-left: -4px;
  margin-right: -4px;
  padding: 2px 4px 3px;
  cursor: pointer;

  /* Анимация наведения и нажатия на строку. */
  transition: all 0.3s ease-in-out;
}

/* Строки блокированных Прорабов/Счетов. */
.page__profile #profile__content--2 .line__foreman.foreman-locked,
.page__profile #profile__content--3 .line__bank.bank-locked {
  color: var(--gray-77);
}

/* Наведение на строку. */
.page__profile #profile__content--2 .container__line .line__foreman:hover,
.page__profile #profile__content--3 .container__line .line__bank:hover {
  background: var(--back__line-hover);
  color: var(--text__line-hover);
}

/* Выбранная строка. */
/*.page__profile #profile__content--2 [type="radio"]:checked + .line__foreman,*/
/*.page__profile #profile__content--3 [type="radio"]:checked + .line__bank {*/
/*  background: grey;*/
/*  color: white;*/
/*}*/

/*--- Вкладка «Банковские счета» ---*/

/* Счёт — не «эскроу». */
.page__profile .line__bank :last-child:not(.escrow)::after {
  content: "\2014";
}

/* Счёт — «эскроу». */
.page__profile .line__bank .escrow::after {
  content: "эскроу";
  font-family: "bold-Italic";
  font-size: 12px;
  color: var(--accept-dark);
}
