/*
	Страница «Карточка объекта»
	.page__building--card
*/



/*==================/
  Панель реквизитов
/==================*/

.page__building--card .bar__requisites {
	margin-bottom: 8px;
	background: var(--back__tab-hover);
	color: var(--back);
}

/*-----------/
  Компоновка
/-----------*/

.page__building--card .bar__requisites {
	display: grid;
	grid-template-columns: 12px repeat(6, auto) 12px;
	grid-template-rows: 42px;
	grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-6 .";
	align-items: center;
}

.page__building--card .bar__requisites > :nth-child(1) { grid-area: area-1; }   /* Даты.*/
.page__building--card .bar__requisites > :nth-child(2) { grid-area: area-2; }   /* Всего.*/
.page__building--card .bar__requisites > :nth-child(3) { grid-area: area-3; }   /* Остаток.*/
.page__building--card .bar__requisites > :nth-child(4) { grid-area: area-4; }   /* В работе.*/
.page__building--card .bar__requisites > :nth-child(5) { grid-area: area-5; }   /* Выполнено.*/
.page__building--card .bar__requisites > :nth-child(6) { grid-area: area-6; }   /* Сметы.*/

/*--- Даты ---*/

.page__building--card .bar__requisites > :nth-child(1) {
	display: grid;
	grid-template-columns: 72px 20px auto;
	/* grid-template-rows: auto; */
	grid-template-areas:
		"area-1 area-2 area-3";
	align-items: center;
	justify-items: start;
}

.page__building--card .bar__requisites > :nth-child(1) > :nth-child(1) { grid-area: area-1; }
.page__building--card .bar__requisites > :nth-child(1) > :nth-child(2) { grid-area: area-2; }
.page__building--card .bar__requisites > :nth-child(1) > :nth-child(3) { grid-area: area-3; }

/* Компоновка. */
.page__building--card .bar__requisites > :nth-child(2),
.page__building--card .bar__requisites > :nth-child(3),
.page__building--card .bar__requisites > :nth-child(4),
.page__building--card .bar__requisites > :nth-child(5) {
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* Заголовки. */
.page__building--card .bar__requisites > :nth-child(2) > :nth-child(1),
.page__building--card .bar__requisites > :nth-child(3) > :nth-child(1),
.page__building--card .bar__requisites > :nth-child(4) > :nth-child(1),
.page__building--card .bar__requisites > :nth-child(5) > :nth-child(1) {
	font-family: 'thin';
}

/* Символ рубля. */
.page__building--card .bar__requisites > :nth-child(2) > :nth-child(2)::after,
.page__building--card .bar__requisites > :nth-child(3) > :nth-child(2)::after,
.page__building--card .bar__requisites > :nth-child(4) > :nth-child(2)::after,
.page__building--card .bar__requisites > :nth-child(5) > :nth-child(2)::after {
	padding-left: 4px;
	font-family: 'thin';
	font-size: 13px;
}

/*--- Сметы ---*/

.page__building--card .bar__requisites > :nth-child(6) {
	justify-self: end;
	display: grid;
	grid-template-columns: 56px 20px 12px 20px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		"area-1 area-2 area-3 area-4";
	align-items: center;
	justify-items: start;
}

.page__building--card .bar__requisites > :nth-child(6) > :nth-child(1) { grid-area: area-1; }
.page__building--card .bar__requisites > :nth-child(6) > :nth-child(2) { grid-area: area-2; }
.page__building--card .bar__requisites > :nth-child(6) > :nth-child(3) { grid-area: area-3; }
.page__building--card .bar__requisites > :nth-child(6) > :nth-child(4) { grid-area: area-4; }

.page__building--card .bar__requisites > :nth-child(6) > :nth-child(1) {
	font-family: 'thin';
}

.page__building--card .bar__requisites > :nth-child(6) > :nth-child(3) {
	justify-self: center;
}

.page__building--card .bar__requisites > :nth-child(6) > :nth-child(4) {
	justify-self: end;
}





/*=================/
  Статусная панель
/=================*/

.page__building--card .bar__status {
	margin-bottom: 8px;
	background: var(--text-head);
	color: var(--back);
}

/*-----------/
  Компоновка
/-----------*/

.page__building--card .bar__status {
	display: grid;
	grid-template-columns: 12px 490px 220px auto 12px;
	grid-template-rows: 42px;
	grid-template-areas:
		". area-1 area-2 . .";
	align-items: center;
}

.page__building--card .bar__status > :nth-child(1) { grid-area: area-1; }   /* Кнопка. */
.page__building--card .bar__status > :nth-child(2) { grid-area: area-2; }   /* Статус. */

/*--- Статус ---*/

.page__building--card .text__status--building {
	justify-self: center;
	border: solid 1px var(--back);
	padding: 1px 6px 2px;
}

/* Статус «Подтверждено». */
.page__building--card.status__building--confirmed .text__status--building {
	border-color: var(--accept-light);
}
.page__building--card.status__building--confirmed .text__status--building::after {
	color: var(--accept-light);
}

/* Статус «На проверке». */
.page__building--card.status__building--on-verify .text__status--building {
	background: var(--back);
	border-color: var(--error-light-2);
}

 /* Статус «Не подтверждено». */
.page__building--card.status__building--not-confirmed .text__status--building {
	border-color: var(--error-light-2);
	background: var(--error-dark);
}
.page__building--card.status__building--not-confirmed .text__status--building::after {
	color: var(--back);
}

/*--- Кнопка ---*/

.page__building--card .bar__status > button {
	width: 172px;
}




/*===============/
  Область данных
/===============*/

.page__building--card .area__data {
	background: var(--back-main);
	padding-top: 6px;
	padding-bottom: 6px;
}

/*-----------/
  Компоновка
/-----------*/

.page__building--card .area__data {
	display: grid;
	grid-template-columns: 12px 600px auto 600px 12px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		". area-1 . area-2 .";
}

.page__building--card .area__data > .area__data--left  { grid-area: area-1; }   /* Левая сторона.*/
.page__building--card .area__data > .area__data--right { grid-area: area-2; }   /* Правая сторона.*/

/*--------------/
  Левая сторона
/--------------*/

.page__building--card .area__data--left {
	border-right: solid 1px var(--gray-77);
}

/*--- Компоновка ---*/

.page__building--card .area__data--left {
	display: grid;
	grid-template-columns: 140px auto;
	grid-template-rows: repeat(7, 24px);
	grid-template-areas:
		"area-1  area-2 "
		"area-3  area-4 "
		"area-5  area-6 "
		"area-7  area-8 "
		"area-9  area-10"
		"area-11 area-12"
		"area-13 area-14";
	align-items: center;
}

.page__building--card .area__data--left > :nth-child(1)  { grid-area: area-1;  }   /* «Названние».*/
.page__building--card .area__data--left > :nth-child(2)  { grid-area: area-2;  }   /* Названние.*/
.page__building--card .area__data--left > :nth-child(3)  { grid-area: area-3;  }   /* «Тип объекта».*/
.page__building--card .area__data--left > :nth-child(4)  { grid-area: area-4;  }   /* Тип объекта.*/
.page__building--card .area__data--left > :nth-child(5)  { grid-area: area-5;  }   /* «Адрес».*/
.page__building--card .area__data--left > :nth-child(6)  { grid-area: area-6;  }   /* Адрес.*/
.page__building--card .area__data--left > :nth-child(7)  { grid-area: area-7;  }   /* «Застройщик».*/
.page__building--card .area__data--left > :nth-child(8)  { grid-area: area-8;  }   /* Застройщик.*/
.page__building--card .area__data--left > :nth-child(9)  { grid-area: area-9;  }   /* «Генподрядчик».*/
.page__building--card .area__data--left > :nth-child(10) { grid-area: area-10; }   /* Генподрядчик.*/
.page__building--card .area__data--left > :nth-child(11) { grid-area: area-11; }   /* «Заказчик».*/
.page__building--card .area__data--left > :nth-child(12) { grid-area: area-12; }   /* Заказчик.*/
.page__building--card .area__data--left > :nth-child(13) { grid-area: area-13; }   /* «Геокоординаты».*/
.page__building--card .area__data--left > :nth-child(14) { grid-area: area-14; }   /* Геокоординаты.*/

/* Надписи. */
.page__building--card .area__data--left > :nth-child(2n+1) {
	font-family: 'thin';
}

/*---------------/
  Правая сторона
/---------------*/

/*--- Компоновка ---*/

.page__building--card .area__data--right {
	display: grid;
	grid-template-columns: 140px auto;
	grid-template-rows: 24px 48px auto;
	grid-template-areas:
		"area-1 area-2"
		"area-3 area-3"
		"area-4 area-4";
	align-items: center;
}

.page__building--card .area__data--right > :nth-child(1) { grid-area: area-1; }   /* «Роль на Объекте».*/
.page__building--card .area__data--right > :nth-child(2) { grid-area: area-2; }   /* Роль на Объекте.*/
.page__building--card .area__data--right > :nth-child(3) { grid-area: area-3; }   /* «Прорабы».*/
.page__building--card .area__data--right > :nth-child(4) { grid-area: area-4; }   /* Список Прорабов.*/

.page__building--card .area__data--right > :nth-child(1) {
	font-family: 'thin';
}

/* «Прорабы».*/
.page__building--card .area__data--right > :nth-child(3) {
	align-self: end;
	width: 100%;
	background: var(--back);
	padding: 3px 0 4px;
	text-align: center;
	font-family: 'thin';
	font-size: 16px;
}

/*--- Список Прорабов ---*/

/* Строка Прораба. */
.page__building--card .foreman_row:not(:last-child) {
	border-bottom: solid 1px var(--back);
	padding-bottom: 4px;
}

/* Компоновка. */
.page__building--card .foreman_row {
	display: grid;
	grid-template-columns: auto 240px 140px;
	grid-template-rows: repeat(2, 18px);
	grid-template-areas:
		"area-1 area-3 area-4"
		"area-2 area-3 area-4";
}

.page__building--card .foreman_row > :nth-child(1) { grid-area: area-1; }   /* Фамилия.*/
.page__building--card .foreman_row > :nth-child(2) { grid-area: area-2; }   /* Имя Отчество.*/
.page__building--card .foreman_row > :nth-child(3) { grid-area: area-3; }   /* E-mail.*/
.page__building--card .foreman_row > :nth-child(4) { grid-area: area-4; }   /* Телефон.*/

.page__building--card .foreman_row > :nth-child(1) {
	align-self: end;
	line-height: 10px;
}

.page__building--card .foreman_row > :nth-child(2) {
	align-self: start;
	font-size: 12px;
}

.page__building--card .foreman_row > :nth-child(3) {
	align-self: center;
	justify-self: center;
}

.page__building--card .foreman_row > :nth-child(4) {
	align-self: center;
	justify-self: end;
}

