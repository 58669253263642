.areaDataLeft {
    grid-area: area-1;
    display: grid;
    grid-template-columns: 140px auto;
    grid-template-rows: repeat(6, 24px);
    grid-template-areas:
        "area-1 area-2 "
        "area-3 area-4 "
        "area-5 area-6 "
        "area-7 area-8 "
        "area-9 area-10"
        "area-11 area-12";
    align-items: center;
    border-right: solid 1px var(--gray-77);
}

.areaDataLeft > :nth-child(1) {
    grid-area: area-1;
}
.areaDataLeft > :nth-child(2) {
    grid-area: area-2;
}
.areaDataLeft > :nth-child(3) {
    grid-area: area-3;
}
.areaDataLeft > :nth-child(4) {
    grid-area: area-4;
}
.areaDataLeft > :nth-child(5) {
    grid-area: area-5;
}
.areaDataLeft > :nth-child(6) {
    grid-area: area-6;
}
.areaDataLeft > :nth-child(7) {
    grid-area: area-7;
}
.areaDataLeft > :nth-child(8) {
    grid-area: area-8;
}
.areaDataLeft > :nth-child(9) {
    grid-area: area-9;
}
.areaDataLeft > :nth-child(10) {
    grid-area: area-10;
}
.areaDataLeft > :nth-child(11) {
    grid-area: area-11;
}
.areaDataLeft > :nth-child(12) {
    grid-area: area-12;
}
.areaDataLeft > :nth-child(13) {
    grid-area: area-13;
}
.areaDataLeft > :nth-child(14) {
    grid-area: area-14;
}

.areaDataLeft > :nth-child(2n+1) {
    font-family: 'thin';
}