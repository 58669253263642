/*=============================================/
  Страница списка Нарядов (.page__order--list)
/=============================================*/

/*----------------/
  Панель фильтров
/----------------*/

/* При прокрутке прижимается к верхней панели, но не прокручивается. */

/*--- Компоновка ---*/

.page__order--list .order-list__filter-bar {
  display: grid;
  grid-template-columns: 210px 210px 300px 84px auto 132px 60px; /* = строке Наряда. */
  grid-column-gap: 8px;
  grid-template-rows: 36px;
  grid-template-areas: "child-1 . . . child-2 child-3 .";
  align-items: center;
}

.page__order--list .order-list__filter-bar > :nth-child(1) {
  grid-area: child-1;
} /* Объект. */
.page__order--list .order-list__filter-bar > :nth-child(2) {
  grid-area: child-2;
} /* Бригадир. */
.page__order--list .order-list__filter-bar > :nth-child(3) {
  grid-area: child-3;
} /* Статус. */
/* Неименованные области — место под другие фильтры. */

/*--- Позиционирование ---*/

.page__order--list .order-list__filter-bar {
  position: sticky;
  z-index: 1; /* Перекрываем прокручиваемое содержание. */
  top: 76px;

  width: 1240px; /* Ширина контента. */
  margin-top: -2px;
  margin-bottom: 14px;

  border-top: solid 1px var(--back);
  border-bottom: solid 1px var(--back);
  background: var(--back__tab-hover);
  padding-left: 6px;
  color: var(--back);
}

/* Поле раскрывающегося списка. */
.page__order--list .order-list__filter-bar select {
  height: 20px;
  padding-right: 4px;
  text-align: center;
  font-size: 12px;
}

/* Раскраска пунктов в списке. */
.page__order--list
  .order-list__filter-bar
  [name="filter__status"]
  > :nth-child(3),
.page__order--list
  .order-list__filter-bar
  [name="filter__status"]
  > :nth-child(5),
.page__order--list
  .order-list__filter-bar
  [name="filter__status"]
  > :nth-child(7),
.page__order--list
  .order-list__filter-bar
  [name="filter__status"]
  > :nth-child(11),
.page__order--list
  .order-list__filter-bar
  [name="filter__status"]
  > :nth-child(13),
.page__order--list
  .order-list__filter-bar
  [name="filter__status"]
  > :nth-child(15) {
  color: var(--error-dark);
}

/*--------------/
  Строка Наряда
/--------------*/

/*--- Компоновка ---*/

.page__order--list .order-list__item {
  display: grid;
  grid-template-columns: 210px 210px 300px 84px auto 132px 60px; /* = панели фильтров. */
  grid-column-gap: 8px;
  /* grid-template-rows: auto; */
  grid-template-areas:
    "child-1 child-3 child-4 child-5 child-7 child-9 child-10"
    "child-2 child-3 child-4 child-6 child-8 child-9 child-10";
}

.page__order--list .order-list__item > :nth-child(1) {
  grid-area: child-1;
} /* Объект. */
.page__order--list .order-list__item > :nth-child(2) {
  grid-area: child-2;
} /* Адрес. */

.page__order--list .order-list__item > :nth-child(3) {
  grid-area: child-3;
} /* Смета. */
.page__order--list .order-list__item > :nth-child(4) {
  grid-area: child-4;
} /* Наряд. */

.page__order--list .order-list__item > :nth-child(5) {
  grid-area: child-5;
} /* Трудоёмкость. */
.page__order--list .order-list__item > :nth-child(6) {
  grid-area: child-6;
} /* Стоимость. */

.page__order--list .order-list__item > :nth-child(7) {
  grid-area: child-7;
} /* Даты. */
.page__order--list .order-list__item > :nth-child(8) {
  grid-area: child-8;
} /* Бригада. */

.page__order--list .order-list__item > :nth-child(9) {
  grid-area: child-9;
} /* Статус. */
.page__order--list .order-list__item > :nth-child(10) {
  grid-area: child-10;
} /* Споры. */

/*--- Выравнивание по вертикали ---*/

.page__order--list .order-list__item > :nth-child(1),
.page__order--list .order-list__item > :nth-child(5),
.page__order--list .order-list__item > :nth-child(7) {
  align-self: end;
}

.page__order--list .order-list__item > :nth-child(2),
.page__order--list .order-list__item > :nth-child(6),
.page__order--list .order-list__item > :nth-child(8) {
  align-self: start;
}

.page__order--list .order-list__item > :nth-child(3),
.page__order--list .order-list__item > :nth-child(4),
.page__order--list .order-list__item > :nth-child(9),
.page__order--list .order-list__item > :nth-child(10) {
  align-self: center;
}

/*--- Выравнивание по горизонтали ---*/

.page__order--list .order-list__item > :nth-child(5),
.page__order--list .order-list__item > :nth-child(6),
.page__order--list .order-list__item > :nth-child(9),
.page__order--list .order-list__item > :nth-child(10) {
  justify-self: center;
}

/*--- Вложенный grid: Споры ---*/

.page__order--list .order-list__item > :nth-child(10) {
  display: grid;
  grid-template-columns: 24px 12px 24px;
  /* grid-template-rows: auto; */
  grid-template-areas:
    "child-1 child-1 child-1"
    "child-2 child-3 child-4";
}

.page__order--list .order-list__item > :nth-child(10) > :nth-child(1) {
  grid-area: child-1;
  align-self: end;
  justify-self: center;
} /* «Споры». */
.page__order--list .order-list__item > :nth-child(10) > :nth-child(2) {
  grid-area: child-2;
  align-self: start;
  justify-self: end;
} /* Число. */
.page__order--list .order-list__item > :nth-child(10) > :nth-child(3) {
  grid-area: child-3;
  align-self: start;
  justify-self: center;
} /* «/». */
.page__order--list .order-list__item > :nth-child(10) > :nth-child(4) {
  grid-area: child-4;
  align-self: start;
  justify-self: start;
} /* Число. */

/*--- Блок строки Наряда ---*/

.page__order--list .order-list__item {
  margin-bottom: 6px;
  border: solid 1px var(--gray-81);
  background: var(--back-main);
  padding: 4px 0 4px 6px;
}

/*--- Адрес ---*/

.page__order--list .order-list__item > :nth-child(2) {
  font-family: "thin";
  font-size: 12px;
}

/*--- Трудоёмкость/Продолжительность ---*/

.page__order--list .order-list__item > :nth-child(5) {
  display: flex;
  flex-direction: row;
}

/* Буквы «ч/д» и «дн.» */
.page__order--list .order-list__item > :nth-child(5) > :nth-child(2) {
  padding-left: 6px;
  font-family: "thin";
}

/*--- Стоимость ---*/

.page__order--list .order-list__item > :nth-child(6) {
  font-size: 12px;
}

/*--- Даты ---*/

.page__order--list .order-list__item > :nth-child(7) {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Тире. */
.page__order--list .order-list__item > :nth-child(7) > :nth-child(2) {
  padding-right: 4px;
  padding-left: 4px;
}

/*--- Бригадир ---*/

.page__order--list .order-list__item > :nth-child(8) {
  padding-right: 0; /* Убираем отступ класса «limit__text--length». */
  text-align: center;
  font-size: 12px;
}

/*--- Статус ---*/

/* Блок (статус/кнопки). */
.page__order--list .order-list__item > :nth-child(9) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Текст во псевдоэлементе по центру. */
.page__order--list [class*="status__order--"] .text__status--order {
  text-align: center;
  line-height: 14px;
}

/* Раскраска статусов.*/
.page__order--list .status__order--overdue-launch .text__status--order::before,
.page__order--list .status__order--overdue-brigade .text__status--order::before,
.page__order--list .status__order--overdue-done .text__status--order::before {
  color: var(--error-dark);
}

/*--- Кнопки в строке (под Статусом) ---*/

.page__order--list .order-list__item button {
  display: none;
  width: 132px;
  border: solid 1px var(--gray-87); /* Граница на фоне выделяемой строки. */
  margin-top: 4px;
}

.page__order--list button[name="control__order--work-accept"] {
  margin-top: 2px;
}

/* Вывод кнопок в контексте статусов. */
.page__order--list .status__order--accept [name="control__order--launch"],
.page__order--list
  .status__order--overdue-launch
  [name="control__order--launch"],
.page__order--list .status__order--prepaid [name="control__order--work"],
.page__order--list
  .status__order--work-done
  [name="control__order--work-repair"],
.page__order--list
  .status__order--work-done
  [name="control__order--work-accept"] {
  display: flex; /* flex — исходное значение. */
}

/*--- Споры ---*/

.page__order--list .order-list__item > :nth-child(10) > :nth-child(1) {
  font-size: 12px;
}
