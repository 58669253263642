.container {
  margin-bottom: 6px;
  border: solid 1px var(--gray-67);
  background: var(--back__head-section);
  padding: 3px 6px;
  color: var(--back__tab-hover);
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.activeContainer {
  border: solid 1px var(--gray-67);
  background: var(--back__head-section);
  padding: 3px 6px;
  color: var(--back__tab-hover);
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.openMenu {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  border: solid 1px var(--gray-67);
  background: var(--back-main);
  padding-top: 6px;
  padding-bottom: 4px;
  height: 200px;
  overflow: auto;
}

.textarea {
  display: block;
  margin: 8px 0 12px 26px;
  resize: none;
  width: 554px;
  height: 50px;
  border: solid 1px var(--gray-77);
  padding: 3px 4px;
  font-size: 12px;
  line-height: 14px;
}

.blockMessage {
  border-bottom: 0.1px solid gray;
}

.blockMessage:last-child {
  border-bottom: none;
}

.file {
  padding-left: 14px;
  display: flex;
  flex-direction: column;
}

.date {
  font-family: 'thin-italic';
  color: rgb(16, 62, 81);
  font-size: 12px;
}

.contractor {
  font-family: 'bold-italic';
  font-size: 12px;
  margin-left: 10px;
}

.size {
  width: 610px;
}