.containerStyle {
    padding: 5px;
    overflow-y: auto;
}

.noteArea {
    width: 70%;
}

.noteLabel {
    font-size: 12px;
    margin-left: 18px;
    font-weight: bold;
}

.fileArea {
    display: flex;
    margin-bottom: 6px;
    line-height: 24px;
    justify-content: space-between;
}