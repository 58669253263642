/*==========================================/
  Страницы регистрации (.page__register--…)
/==========================================*/

/*-----------------------------/
  Компоновка содержания (main)
/-----------------------------*/

[class*="page__register--"] > main {
  display: grid;
  grid-template-columns: auto 404px;
  grid-template-rows: 52px repeat(2, auto);
  grid-template-areas:
    "child-1 child-3"
    "child-2 child-3"
    "child-4 child-4";
}

[class*="page__register--"] > main > :nth-child(1) {
  grid-area: child-1;
} /* Заголовок. */
[class*="page__register--"] > main > :nth-child(2) {
  grid-area: child-2;
} /* Форма регистрации. */
[class*="page__register--"] > main > :nth-child(3) {
  grid-area: child-3;
  align-self: center;
} /* Картинка. */
[class*="page__register--"] > main > :nth-child(4) {
  grid-area: child-4;
} /* Информация. */

/*-----------------/
  Элементы (общие)
/-----------------*/

/*--- Область основного содержания ---*/

[class*="page__register--"] > main {
  margin-bottom: auto;
  background: var(--back-main);
  padding: 12px 22px 22px;
  color: var(--text);
}

/*--- Заголовок основного содержания ---*/

[class*="page__register--"] > main > h2 {
  font-size: 22px;
}

/*--- Заголовок в форме (подпись к полю) ---*/

[class*="page__register--"] form h3 {
  font-size: 15px;
  line-height: 15px;
}

/*--- Ширина кнопки ---*/

[class*="page__register--"] form button {
  width: 172px;
}

/*--- Картинка в правой области ---*/

[class*="page__register--"] .pic__back {
  mix-blend-mode: multiply;
  text-align: end;
}

[class*="page__register--"] .pic__back img {
  width: 88%;
}

/*--- Информационный бокс в нижней области ---*/

[class*="page__register--"] .info__box {
  margin-top: 26px;
  border-left: solid 4px var(--text-head);
  padding: 16px 16px 0;
  background: hsl(var(--blue), 28%, 91%);
}

/* Заголовок раздела в блоке информации. */
[class*="page__register--"] .info__box h3 {
  font-size: 17px;
  line-height: 17px;
}

/* Абзац в блоке информации. */
[class*="page__register--"] .info__box p {
  margin-top: 8px;
  margin-bottom: 18px;
}

/*----------------------------------------------------/
  Страница регистрации: 1-й этап (.page__register--1)
/----------------------------------------------------*/

/*--- Форма регистрации ---*/

.page__register--1 [name="register__form"] {
  display: grid;
  grid-template-columns: 172px auto;
  grid-template-rows: repeat(2, 32px) repeat(3, 56px);
  grid-template-areas:
    "child-1 child-2"
    "child-1 child-3"
    "child-4 child-5"
    "child-6 child-7"
    "child-8 child-9";
  align-items: center;
}

.page__register--1 [name="register__form"] > :nth-child(1) {
  grid-area: child-1;
} /* «Тип профиля». */
.page__register--1 [name="register__form"] > :nth-child(2) {
  grid-area: child-2;
  align-self: end;
} /* Радио-кнопка. */
.page__register--1 [name="register__form"] > :nth-child(3) {
  grid-area: child-3;
  align-self: start;
} /* Радио-кнопка. */
.page__register--1 [name="register__form"] > :nth-child(4) {
  grid-area: child-4;
} /* «Мобильный телефон». */
.page__register--1 [name="register__form"] > :nth-child(5) {
  grid-area: child-5;
} /* Телефон. */
.page__register--1 [name="register__form"] > :nth-child(6) {
  grid-area: child-6;
} /* «Соглашение пользователя». */
.page__register--1 [name="register__form"] > :nth-child(7) {
  grid-area: child-7;
} /* Чекбокс. */
.page__register--1 [name="register__form"] > :nth-child(8) {
  grid-area: child-8;
} /* «Код регистрации». */
.page__register--1 [name="register__form"] > :nth-child(9) {
  grid-area: child-9;
} /* Кнопка. */

/* Заголовки. */
.page__register--1 [name="register__form"] > :nth-child(1),
.page__register--1 [name="register__form"] > :nth-child(4),
.page__register--1 [name="register__form"] > :nth-child(6) {
  width: 78px; /* Ширина для вывода в две строки. */
}

/* Радио-кнопка «Подрядчик». */
.page__register--1 [name="register__form"] > :nth-child(2) {
  margin-bottom: 2px;
}

/* Поле номера телефона: Выравнивание текста. */
.page__register--1 [type="tel"] {
  text-align: center;
}

/*--- Выравнивание картинки ---*/

.page__register--1 .pic__back img {
  margin-bottom: -26px;
}

/*----------------------------------------------------/
  Страница регистрации: 2-й этап (.page__register--2)
/----------------------------------------------------*/

/*--- Форма регистрации ---*/

.page__register--2 [name="register__form"] {
  display: grid;
  grid-template-columns: 172px auto;
  grid-template-rows: auto;
  grid-template-areas:
    "child-1 child-1"
    "child-2 child-2"
    "child-3 child-4";
  align-items: center;
}

.page__register--2 [name="register__form"] > :nth-child(1) {
  grid-area: child-1;
} /* Набор полей 1. */
.page__register--2 [name="register__form"] > :nth-child(2) {
  grid-area: child-2;
} /* Набор полей 2. */
.page__register--2 [name="register__form"] > :nth-child(3) {
  grid-area: child-3;
} /* «Регистрация». */
.page__register--2 [name="register__form"] > :nth-child(4) {
  grid-area: child-4;
} /* Кнопка. */

/*--- Наборы полей: «Первичные данные» / «Учётная запись» ---*/

.page__register--2 [class^="register__set--"] {
  display: grid;
  grid-template-columns: 172px auto;
  grid-template-rows: 6px 38px 38px 38px;
  grid-template-areas:
    "child-1 child-1"
    "child-2 child-3"
    "child-4 child-5"
    "child-6 child-7";
  align-items: center;
}

.page__register--2 [class^="register__set--"] > :nth-child(1) {
  grid-area: child-1;
} /* Легенда. */
.page__register--2 [class^="register__set--"] > :nth-child(2) {
  grid-area: child-2;
} /* «Профиль» / «Логин». */
.page__register--2 [class^="register__set--"] > :nth-child(3) {
  grid-area: child-3;
} /* Роль / Логин. */
.page__register--2 [class^="register__set--"] > :nth-child(4) {
  grid-area: child-4;
} /* «Телефон» / «Пароль». */
.page__register--2 [class^="register__set--"] > :nth-child(5) {
  grid-area: child-5;
} /* Телефон / Пароль. */
.page__register--2 [class^="register__set--"] > :nth-child(6) {
  grid-area: child-6;
} /* «Код …» / «Пароль …» */
.page__register--2 [class^="register__set--"] > :nth-child(7) {
  grid-area: child-7;
} /* Код / Пароль (проверка). */

/* Набор полей «Первичные данные». */
.page__register--2 .register__set--primary {
  margin-top: -4px;
}

/* Набор полей «Учётная запись». */
.page__register--2 .register__set--account {
  margin-top: 16px;
  margin-bottom: 32px;
}

/* Название набора полей. */
.page__register--2 [class^="register__set--"] legend {
  font-size: 17px;
}
