.container {
  margin-bottom: 6px;
  border: solid 1px var(--gray-67);
  background: var(--text-head);
  padding: 3px 6px;
  color: var(--back__tab-hover);
  font-size: 16px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.status {
  color: white;
  font-size: 14px;
  display: inline-block;
  border: solid 1px var(--back);
  padding: 2px 5px 3px;
}

.noChange {
  font-size: 12px;
  color: rgb(196, 196, 196);
  font-family: 'thin';
}

.calculationCp {
  font-family: 'thin';
  color: white;
  font-size: 12px;
}

.foreman {
  color: white;
  font-size: 12px;
}

.breakdown {
  color: rgb(220, 244, 254);
  text-decoration: none;
  border-bottom: 0.5px dotted white;
  font-size: 14px;
}

.select {
  color: var(--input-text-normal);
  width: 116px;
  height: 18px;
  padding-left: 0;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 280px;
  align-items: center;
}

.button {
  border: 1px solid white;
  width: 136px;
}

.size {
  width: 610px;
}