/*
	Страница «Список бригад»
	.page__brigade--list
*/



/*==============================================/
  *
/==============================================*/

/*--------------------------/
  Общая компоновка колонок
/--------------------------*/

.page__brigade--list .bar__captions,
.page__brigade--list .bar__filters,
.page__brigade--list .row__brigade {
	display: grid;
	grid-template-columns: 0 auto 160px 50px 260px 80px 60px 0;
	grid-column-gap: 6px;
	grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-8 .";
	align-items: center;
} 

.page__brigade--list .bar__captions > :nth-child(1),
.page__brigade--list .bar__filters > :nth-child(1),
.page__brigade--list .row__brigade > :nth-child(1) { grid-area: area-1; }   /* Бригадир. */

.page__brigade--list .bar__captions > :nth-child(2),
.page__brigade--list .bar__filters > :nth-child(2),
.page__brigade--list .row__brigade > :nth-child(2) { grid-area: area-2; }   /* Телефон. */

.page__brigade--list .bar__captions > :nth-child(3),
.page__brigade--list .bar__filters > :nth-child(3),
.page__brigade--list .row__brigade > :nth-child(3) { grid-area: area-3; }   /* Кол-во. */

.page__brigade--list .bar__captions > :nth-child(4),
.page__brigade--list .bar__filters > :nth-child(4),
.page__brigade--list .row__brigade > :nth-child(4) { grid-area: area-4; }   /* Специализация. */

.page__brigade--list .bar__captions > :nth-child(5),
.page__brigade--list .bar__filters > :nth-child(5),
.page__brigade--list .row__brigade > :nth-child(5) { grid-area: area-5; }   /* Наряды. */

.page__brigade--list .bar__captions > :nth-child(6),
.page__brigade--list .bar__filters > :nth-child(6),
.page__brigade--list .row__brigade > :nth-child(6) { grid-area: area-8; }   /* Рейтинг. */

/*--------------------------/
  Панель заголовков колонок
/--------------------------*/

/* При прокрутке прижимается к верхней панели, но не прокручивается. */

/* Высота панели и выравнивание. */
.page__brigade--list .bar__captions {
	grid-template-rows: 42px;
	justify-items: center;
}

/*--- Позиционирование ---*/

.page__brigade--list .bar__captions {
	position: sticky;
	z-index: 1;      /* Перекрываем прокручиваемое содержание. */
	top: 76px;

	width: 1240px;   /* Ширина контента. */
	margin-bottom: 6px;

	border-top: solid 1px var(--back);
	border-bottom: solid 1px var(--back);
	background: var(--back__tab-hover);
	text-align: center;
	font-family: 'thin';
	line-height: 16px;
	color: var(--back);
}

/*----------------/
  Панель фильтров
/----------------*/

/* При прокрутке прижимается к верхней панели, но не прокручивается. */

/* Выравнивание. */
.page__brigade--list .bar__filters {
	justify-items: center;
}

/*--- Позиционирование ---*/

.page__brigade--list .bar__filters {
	position: sticky;
	z-index: 1;      /* Перекрываем прокручиваемое содержание. */
	top: 120px;

	width: 1240px;   /* Ширина контента. */
	margin-bottom: 6px;

	border-bottom: solid 1px var(--back);
	background: var(--back);
}

/*--- Элементы ---*/

.page__brigade--list .bar__filters > :nth-child(n) {
	width: 100%;
}

.page__brigade--list .bar__filters > :nth-child(2),
.page__brigade--list .bar__filters > :nth-child(3),
.page__brigade--list .bar__filters > :nth-child(4),
.page__brigade--list .bar__filters > :nth-child(6) {
	padding-left: 0;
	text-align: center;
}

.page__brigade--list .bar__filters input,
.page__brigade--list .bar__filters select {
	height: 20px;
	font-size: 12px;
}

.page__brigade--list .bar__filters input {
	padding-bottom: 1px;
}

/* Поле раскрывающегося списка. */
.page__brigade--list .bar__filters select {
	padding-right: 4px;
	text-align: center;
}

/*---------------/
  Строка Бригады
/---------------*/

.page__brigade--list .row__brigade > :nth-child(2),
.page__brigade--list .row__brigade > :nth-child(3),
.page__brigade--list .row__brigade > :nth-child(5),
.page__brigade--list .row__brigade > :nth-child(6) {
	justify-self: center;
}

/* Специализация. */
.page__brigade--list .row__brigade > :nth-child(4) {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	line-height: 14px;
}

.page__brigade--list .row__brigade > :nth-child(4) span{
	text-align: center;
}

/* Наряды. */
.page__brigade--list .row__brigade > :nth-child(5) {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	width: 72px;
}

/*--- Блок строки Наряда ---*/

.page__brigade--list .row__brigade {
	margin-bottom: 6px;
	border: solid 1px var(--gray-81);
	background: var(--back-main);
	padding: 4px 0;
}

/* Заблокированный Пользователь. */
.page__brigade--list .row__brigade.brigade__blocked {
	background: var(--gray-87);
	color: var(--gray-57);
}
.page__brigade--list .row__brigade.brigade__blocked:hover {
	background: var(--button-back-normal);
	color: var(--button-text-normal);
}
