/*
	Общие стили «неавторизованных» страниц (доступны без авторизации).

	Ширина контента 1240px (без прокрутки для монитора с шириной разрешения 1280px).
	Общая ширина колонок рабочих областей =1240px (рабочие области — фиксированные).
	Боковые нерабочие области (неопределённые «.») =auto.
	Это выравнивает «main» по центру и компенсируют изменение ширины окна браузера.
*/

/*=======================/
  Каркас страницы (#root)
/=======================*/

/*
	Компоновка основных областей страницы.
	Экранное представление разбито на четыре ключевые «строки»:
		nav-top   — верхняя панель навигации, «липкая»;
		header    — шапка страницы;
		main      — основное содержание, изменяемая высота =auto;
		footer    — нижняя панель ссылок и информации, «липкая».
*/

#root.non-authorized {
  display: grid;
  grid-template-columns: auto 1240px auto;
  grid-template-rows: 32px 108px auto 32px;
  grid-template-areas:
    "nav-top nav-top nav-top"
    "header  header  header "
    ".       main    .      "
    "footer  footer  footer ";
}

#root.non-authorized > nav {
  grid-area: nav-top;
}
#root.non-authorized > header {
  grid-area: header;
}
#root.non-authorized > main {
  grid-area: main;
}
#root.non-authorized > footer {
  grid-area: footer;
}

/*===================================/
  Верхняя панель навигации (nav-top)
/===================================*/

/* Непрокручиваемая область, прилипание к верху окна браузера. */

/*--- Компоновка ---*/

#root.non-authorized > nav {
  display: grid;
  grid-template-columns: auto 170px 720px 350px auto; /* =1240px */
  grid-template-areas: ". child-1 child-2 child-3 .";
  align-content: center;
}

#root.non-authorized > nav > :nth-child(1) {
  grid-area: child-1;
} /* Ссылки слева. */
#root.non-authorized > nav > :nth-child(2) {
  grid-area: child-2;
} /* Ссылки в центре. */
#root.non-authorized > nav > :nth-child(3) {
  grid-area: child-3;
} /* Ссылки справа. */

/*--- Позиционарование ---*/

#root.non-authorized > nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1; /* Перекрываем элементы «въезжающие» под панель. */
  height: 32px; /* Высота панели; высота строки grid`а определена выше. */
  background: var(--back-nav);
}

/*--- Выравнивание ссылок в областях панели ---*/

#root.non-authorized > nav > :nth-child(1),
#root.non-authorized > nav > :nth-child(2) {
  text-align: left;
}
#root.non-authorized > nav > :nth-child(3) {
  text-align: right;
}

/*========================/
  Шапка страницы (header)
/========================*/

/*--- Компоновка ---*/

#root.non-authorized header {
  display: grid;
  grid-template-columns: auto 168px 26px 1046px auto; /* =1240px */
  grid-template-areas: ". child-1 child-2 child-3 .";
  align-content: center;
  justify-items: start;
}

#root.non-authorized > header > :nth-child(1) {
  grid-area: child-1;
} /* Логотип. */
#root.non-authorized > header > :nth-child(2) {
  grid-area: child-2;
} /* Разделитель. */
#root.non-authorized > header > :nth-child(3) {
  grid-area: child-3;
} /* Слоган. */

/*--- Логотип ---*/

.logo__box {
  position: relative;
}

.logo__box > p {
  position: absolute;
  width: 142px; /* Ширина полосы; возможность центрирования. */
  text-align: center;
  text-shadow: var(--shadow);
}

/* Текст слов «Монтаж» и «Оплата». */
.logo__box > :first-of-type,
.logo__box > :last-of-type {
  font-family: "logo-text";
  font-size: 20px;
  color: var(--text-head);
}

/* Строка слова «Монтаж». */
.logo__box > :first-of-type {
  top: -1px;
  letter-spacing: 0;
}

/* Строка слова «Платформа». */
.logo__box > :nth-of-type(2) {
  top: 26px;
  height: 21px; /* Высотаа полосы. */
  background: var(--orange-site);
  padding-left: 3px; /* Центрирование: Компенсация «letter-spacing». */
  font-family: "thin";
  font-size: 16px;
  letter-spacing: 3px; /* Растягиваем слово. */
  color: var(--back);
}

/* Строка слова «Оплата». */
.logo__box > :last-of-type {
  top: 51px;
  padding-left: 3px; /* Центрирование: Компенсация «letter-spacing». */
  letter-spacing: 3.3px; /* Растягиваем слово. */
}

/*--- Разделитель ---*/

#root.non-authorized > header > :nth-child(2) {
  width: 4px;
  height: 72px;
  background: var(--orange-site);
}

/*--- Заголовок в «шапке» ---*/

/* Слоган в «шапке». */
#root.non-authorized > header > :nth-child(3) {
  text-shadow: var(--shadow);
}

/* Вторая строка слогана. */
#root.non-authorized > header > :nth-child(3) span {
  display: block;
  text-indent: 46px;
}
#root.non-authorized > header > :nth-child(3) span::before {
  margin-right: 12px;
}

/*=========================/
  Подвал страницы (footer)
/=========================*/

/* Непрокручиваемая область, прилипание к низу окна браузера. */

/*--- Компоновка ---*/

#root.non-authorized footer {
  display: grid;
  grid-template-columns: auto 412px 416px 412px auto; /* =1240px */
  grid-template-areas: ". child-1 child-2 child-3 .";
  align-content: center;
}

#root.non-authorized footer :nth-child(1) {
  grid-area: child-1;
} /* Ссылки слева. */
#root.non-authorized footer :nth-child(2) {
  grid-area: child-2;
} /* Адрес в центре. */
#root.non-authorized footer :nth-child(3) {
  grid-area: child-3;
} /* Ссылки справа. */

/*--- Позиционарование ---*/

#root.non-authorized > footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 32px; /* Высота панели; высота области grid`а определена в layout. */
  z-index: 1; /* Перекрываем элементы «въезжающие» под панель. */
  background: var(--back-nav);
}

/*--- Выравнивание элементов в областях панели ---*/

#root.non-authorized > footer > :nth-child(1) {
  text-align: left;
}
#root.non-authorized > footer > :nth-child(2) {
  text-align: center;
}
#root.non-authorized > footer > :nth-child(3) {
  text-align: right;
}
