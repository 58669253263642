.errorContainer {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
}

.errorText {
    text-align: center;
}