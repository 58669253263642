// .form-field {
//     display: block;
//     width: 400px;
//     position: relative;
//     border: 2px solid orange;
//     border-radius: 4px;
//     margin-bottom: 1rem;
//     // &:hover,
//     // &:active,
//     // &:focus {
//     //     label {}
//     //     input {
//     //         &::placeholder {
//     //             color: transparent;
//     //         }
//     //     }
//     // }
//     input {
//         width: 100%;
//         height: 100%;
//         padding: 0.5rem;
//         box-sizing: border-box;
//     }
// }

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.items {
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.descItem {
  width: 172px;
}

.name {
  font-size: 17px;
}