.container {
  display: flex;
  justify-content: space-between;
}

.activeTab {
  border: 2px solid var(--text-head);
  border-bottom: none;
  font-family: 'bold';
  width: 618px;
  height: 38px;
  color: var(--text-head);
  cursor: pointer;
}

.tab {
  background: var(--text-head);
  border-color: var(--back);
  font-family: 'thin';
  width: 618px;
  height: 36px;
  color: var(--back);
  cursor: pointer;
}

.tab:hover {
  opacity: .7;
  cursor: pointer;
}

.headerContainer {
  display: flex;
  margin-bottom: 6px;
}

.subitems {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.header {
  display: block;
  border: 2px solid var(--text-head);
  box-sizing: border-box;
  background: var(--back-main);
  padding-top: 12px;
  min-height: 30px;
}

.headerSystem {
  width: 1720px;
  height: 26px;
  display: flex;
  border: solid 1px var(--gray-67);
  background: var(--back__head-section);
  padding: 3px 6px;
  font-size: 16px;
}

.openContainer {
  margin: 2px 8px 8px 28px;
  border: 1px solid gray;
}

.firstHeader {
  border-bottom: solid 1px var(--gray-81);
  background: var(--back__head-section);
  display: flex;
  justify-content: space-between;
  padding-top: 2px;
  padding-bottom: 3px;
  color: var(--back__tab-hover);
  font-family: 'thin';
}

.mapSubitems {
  display: flex;
  justify-content: space-between;
  padding: 4px 0 4px 0;
}

.mapSubitems:nth-child(even) {
  background-color: var(--back-main);
}

.mapSubitems:nth-child(odd) {
  background-color: var(--gray-91);
}

.mapOneItem:last-child {
  padding-right: 4px;
  display: flex;
  justify-content: flex-end;
}

.label {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}