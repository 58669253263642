/*=================================/
  Добавление Счёта (.adding__bank)
/=================================*/

/*--- Размер окна контейнера ---*/

/*
	Основной размер окна определяем в переменных в контексте темы.
	Ширина окна --window-width должна учитывать:
	columns {сумма всех колонок} + margin-left 20px + поле справа 20px.
	Высота окна --window-height должна учитывать:
	общая высота строк вложенной формы + заголовок + отступ снизу.
*/
#modal__box .adding__bank {
  --window-width: 480px;
  --window-height: 386px;
}

/* Фон окна. */
#modal__box .adding__bank {
  background: var(--back-estimate-offer);
}

/*--- Области формы ---*/

#modal__box .adding__bank form {
  display: grid;
  grid-template-columns: 140px 300px;
  grid-template-rows: 24px 48px repeat(4, 32px) 52px;
  grid-template-areas:
    "Label-01  Label-01 "
    "input-02  input-02 "
    "Label-03  input-03 "
    "Label-04  input-04 "
    "Label-05  input-05 "
    "Label-06  input-06 "
    "Bttn-07-1 Bttn-07-2";
  margin-left: 20px;
}

#modal__box .adding__bank form > :nth-child(1) {
  grid-area: Label-01;
}
#modal__box .adding__bank form > :nth-child(2) {
  grid-area: input-02;
  align-self: start;
}

#modal__box .adding__bank form > :nth-child(3) {
  grid-area: Label-03;
}
#modal__box .adding__bank form > :nth-child(4) {
  grid-area: input-03;
}

#modal__box .adding__bank form > :nth-child(5) {
  grid-area: Label-04;
}
#modal__box .adding__bank form > :nth-child(6) {
  grid-area: input-04;
}

#modal__box .adding__bank form > :nth-child(7) {
  grid-area: Label-05;
}
#modal__box .adding__bank form > :nth-child(8) {
  grid-area: input-05;
}

#modal__box .adding__bank form > :nth-child(9) {
  grid-area: Label-06;
}
#modal__box .adding__bank form > :nth-child(10) {
  grid-area: input-06;
}

#modal__box .adding__bank form > :nth-child(11) {
  grid-area: Bttn-07-1;
}
#modal__box .adding__bank form > :nth-child(12) {
  grid-area: Bttn-07-2;
  justify-self: end;
}

/* Ширина полей зависит от ширины окна. */
#modal__box .adding__bank input {
  width: auto;
}

/* Выравниваем чекбокс относительно подписи. */
#modal__box .adding__bank [type="checkbox"] {
  margin-top: 10px;
}
