/*=======================================/
  Создание Объекта (.editing__building)
/=======================================*/

/*--- Размер окна контейнера ---*/

/*
	Основной размер окна определяем в переменных в контексте темы.
	Ширина окна --window-width должна учитывать:
	columns {сумма всех колонок} + margin-left 20px + поле справа 20px.
	Высота окна --window-height должна учитывать:
	общая высота строк вложенной формы + заголовок + отступ снизу.
*/


#modal__box .editing__building {
  --window-width: 580px;
  --window-height: 420px;
  transition-duration: 0.1s;
}


#modal__box .editing__building-collapse {
  --window-height: 290px;
}

/* Фон окна. */
#modal__box .editing__building {
  background: var(--back);
}

/*--- Области формы ---*/

#modal__box .editing__building form {
  display: grid;
  grid-template-columns: 140px 200px 200px;
  grid-template-rows: repeat(7, 32px) 64px;
  grid-template-areas:
    "Label-01  input-01   input-01 "
    "Label-02  input-02   input-02 "
    "collapser collapser collapser "
    "Label-03  input-03   input-03 "
    "Label-04  input-04   input-04 "
    "Label-05  input-05   input-05 "
    "Label-06  input-06   input-06 "
    "Label-07  select-07  select-07"
    "Bttn-13-1 .          Bttn-13-2";
  margin-left: 20px;
}

#modal__box .editing__building-collapse form {
  grid-template-rows: repeat(4, 32px) 64px;
  grid-template-areas:
  "Label-01  input-01   input-01 "
  "Label-02  input-02   input-02 "
  "collapser collapser collapser "
  "Label-07  select-07  select-07"
  "Bttn-13-1 .          Bttn-13-2";
}

#modal__box .editing__building form > :nth-child(1) {
  grid-area: Label-01;
}
#modal__box .editing__building form > :nth-child(2) {
  grid-area: input-01;
}

#modal__box .editing__building form > :nth-child(3) {
  grid-area: Label-02;
}
#modal__box .editing__building form > :nth-child(4) {
  grid-area: input-02;
}

#modal__box .editing__building form > :nth-child(5) {
  grid-area: collapser;
}

#modal__box .editing__building form > :nth-child(6) {
  grid-area: Label-03;
}
#modal__box .editing__building form > :nth-child(7) {
  grid-area: input-03;
}

#modal__box .editing__building form > :nth-child(8) {
  grid-area: Label-04;
}
#modal__box .editing__building form > :nth-child(9) {
  grid-area: input-04;
}

#modal__box .editing__building form > :nth-child(10) {
  grid-area: Label-05;
}
#modal__box .editing__building form > :nth-child(11) {
  grid-area: input-05;
}

#modal__box .editing__building form > :nth-child(12) {
  grid-area: Label-06;
}
#modal__box .editing__building form > :nth-child(13) {
  grid-area: input-06;
}

#modal__box .editing__building form > :nth-child(14) {
  grid-area: Label-07;
}
#modal__box .editing__building form > :nth-child(15) {
  grid-area: select-07;
}



#modal__box .editing__building form > :nth-child(16) {
  grid-area: Bttn-13-1;
}
#modal__box .editing__building form > :nth-child(17) {
  grid-area: Bttn-13-2;
  justify-self: end;
}


#modal__box .editing__building form > :nth-child(16)
#modal__box .editing__building form > :nth-child(17) {
  align-self: end;
}

#modal__box .editing__building form input,
#modal__box .editing__building form select {
  width: auto;
}


#modal__box .editing__building span.control__adding--estimate-type {
  border-bottom: dotted 1px;
  color: var(--link-normal);
  cursor: pointer;
}

#modal__box .editing__building span.control__adding--estimate-type:hover {
  border-bottom-style: solid;
  color: var(--link-hover);
}

#modal__box .editing__building form > :nth-child(5) {
  background: var(--back__head-section);
  padding-top: 2px;
  padding-bottom: 3px;
  text-align: center;
  color: var(--text__head-section);
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

#modal__box .editing__building form > :nth-child(5) span{
  margin-right: 5px;
}

