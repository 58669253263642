/*
	Запрос Разбивки.
	#jobplan__query
*/



/*============/
  Ширина окна
/============*/

#jobplan__query {
	z-index: -10;
	transition-property: z-index;
	transition-delay: 1.5s;
}

[class*="modal__open-"] #jobplan__query {
	z-index: 100;
	transition-delay: 0.1s;
}

#jobplan__query .modal__back {
	position: fixed;
	inset: 0;
	background: var(--gray-47);
  
	opacity: 0;
	transition-property: opacity;
	transition-duration: 0.9s;
	transition-timing-function: linear;
	transition-delay: 0.5s;
}

[class*="modal__open-"] #jobplan__query .modal__back {
	opacity: 0.7;
	transition-delay: 0.1s;
}

#jobplan__query .modal__window {
	position: fixed;
	inset: 30% 0 0 50%;
	opacity: 0;
	z-index: -10;
  
	width: calc(var(--window-width) / 10);
	height: calc(var(--window-height) / 10);
  
	box-shadow: var(--shadow-modal);
  
	transition-property: all;
	transition-duration: 0.7s;
	transition-timing-function: linear;
	transition-delay: 0.4s;
}

#jobplan__query .modal__window * {
	opacity: 0;
	transition-property: opacity;
	transition-duration: 0.4s;
	transition-timing-function: linear;
	transition-delay: 0.1s;
}
  
#jobplan__query .modal__window h3 {
	background: var(--back-nav);
	padding-top: 6px;
	padding-bottom: 7px;
	text-align: center;
	color: var(--back);
	text-shadow: var(--shadow-contour);
}
  

#jobplan__query .modal__window {
	display: grid;
	grid-template-columns: 12px auto 12px;
	grid-template-rows: auto auto minmax(0, 1fr);
	grid-template-areas:
		"area-1 area-1 area-1"
		"area-2 area-2 area-2"
		".      area-3 .     ";

	margin-bottom: 126px;
	box-shadow: var(--shadow-modal);
	background: var(--back);
	width: 580px;
}

#jobplan__query .modal__window--title {
	grid-area: area-1;
}

#jobplan__query .jobplan__query {
	--window-width: 580px;
	--window-height: 640px;
}

#jobplan__query .modal__window--notice {
	width: 100%;
	margin-bottom: 16px;
	border-bottom: solid 1px var(--gray-83);
	padding-top: 12px;
	padding-bottom: 6px;
	text-align: center;
	grid-area: area-2;
}

#jobplan__query .modal__window--notice.notify {
	background: var(--blue48_89);
}

#jobplan__query .modal__window--notice > p {
    margin-bottom: 6px;
    line-height: 16px;
}

#jobplan__query .modal__window--content {
    grid-area: area-3;
}

/* Заголовок сектора. */
#jobplan__query .modal__window--content .header__sector {
	margin-top: 12px;
	margin-bottom: 12px;
	width: 100%;
	background: var(--gray-87);
	padding-top: 4px;
	padding-bottom: 4px;
	text-align: center;
	font-family: 'thin';
	font-size: 16px;
	text-shadow: var(--button-text-disable-shade);
}

#jobplan__query .modal__window--content > hr {
	margin-top: 16px;
	margin-bottom: 16px;
	border-bottom: solid 1px var(--gray-83);
}



/*====================/
  Основное содержание
/====================*/

/*----------------------/
  Набор полей: Разбивка
/----------------------*/

#jobplan__query .modal__content--jobplan,
.modal__content--files,
.modal__content--payment,
.modal__content--amount,
.modal__content--apply {
	margin-bottom: 8px;
	padding: 0;
}

/*--- Компоновка ---*/

#jobplan__query .modal__content--jobplan {
	display: grid;
	grid-template-columns: auto 60px 140px 86px 120px;
	grid-template-rows: 32px 48px;
	grid-template-areas:
		"area-1 area-2 area-2 area-2 area-2"
		"area-3 area-4 area-5 area-6 area-7";
	align-items: center;
}

#jobplan__query .modal__content--jobplan > :nth-child(1) { grid-area: area-1; }   /* «Прораб». */
#jobplan__query .modal__content--jobplan > :nth-child(2) { grid-area: area-2; }   /* Выпадающий список. */

#jobplan__query .modal__content--jobplan > :nth-child(3) { grid-area: area-3; }   /* «Плановые …» */
#jobplan__query .modal__content--jobplan > :nth-child(4) { grid-area: area-4; }   /* «Начало:» */
#jobplan__query .modal__content--jobplan > :nth-child(5) { grid-area: area-5; }   /* Дата. */
#jobplan__query .modal__content--jobplan > :nth-child(6) { grid-area: area-6; }   /* «Окончание:» */
#jobplan__query .modal__content--jobplan > :nth-child(7) { grid-area: area-7; }   /* Дата. */

/* Выпадающий список по ширине окна. */
#jobplan__query .modal__content--jobplan > select {
	width: auto;
}

/* «Плановые …» */
#jobplan__query .modal__content--jobplan > :nth-child(3) {
	font-size: 12px;
	line-height: 12px;
}

/* «Начало:» / «Окончание:» */
#jobplan__query .modal__content--jobplan > :nth-child(4),
#jobplan__query .modal__content--jobplan > :nth-child(6) {
	font-family: 'thin';
	/* font-size: 12px; */
}

/* Поля дат. */
#jobplan__query .modal__content--jobplan > [type="date"] {
	width: 120px;
}

/*-------------------/
  Набор полей: Файлы
/-------------------*/

/*--- Бокс добавления файла ---*/

/* Компоновка. */

#jobplan__query .box__files--load {
	display: grid;
	grid-template-columns: 116px auto;
	grid-template-rows: 20px 42px 18px;
	grid-template-areas:
		"area-1 area-1"
		"area-2 area-3"
		"area-4 area-4";
	align-items: center;
}

#jobplan__query .box__files--load > :nth-child(1) { grid-area: area-1; }   /* Добавление файла. */
#jobplan__query .box__files--load > :nth-child(2) { grid-area: area-2; }   /* «Комментарий:» */
#jobplan__query .box__files--load > :nth-child(3) { grid-area: area-3; }   /* Комментарий. */
#jobplan__query .box__files--load > :nth-child(4) { grid-area: area-4; }   /* Кнопка. */

/* Добавление файла. */
#jobplan__query .box__files--load > :nth-child(1) {
	font-size: 12px;
}

/* «Комментарий:» */
#jobplan__query .box__files--load > :nth-child(2) {
	justify-self: end;
	margin-top: 2px;
	margin-right: 8px;
	font-size: 12px;
}

/* Комментарий. */
#jobplan__query .box__files--load > textarea {
	resize: none;
	width: auto;
	height: 34px;
	border: solid 1px var(--gray-77);
	padding: 2px 4px;
	font-size: 12px;
	line-height: 14px;
}

/* Поле комментария в фокусе. */
#jobplan__query .box__files--load > textarea:focus {
	border: solid 1px var(--gray-57);
}

/* Поле комментария не заполнено. */
#jobplan__query .box__files--load > textarea::placeholder {
	font-family: 'thin-italic';
	color: var(--gray-77);
}

/* Кнопка добавления файла. */
#jobplan__query .box__files--load > button {
	width: 108px;
}

/*--- Бокс добавленных файлов: Строка файла ---*/

/* Отступ строки первого файла от кнопки «Добавить». */
#jobplan__query .box__files--selected > :nth-child(1) {
	margin-top: 16px;
}

/* Строка последнего файла. */
#jobplan__query .box__files--selected > :last-child {
	margin-bottom: 0;
}

/* Компоновка. */
#jobplan__query .row__file {
	display: grid;
	grid-template-columns: 16px auto 24px;
	grid-template-rows: 20px auto;
	grid-template-areas:
		"area-1 . ."
		".       area-2     .";
	align-items: center;
}

#jobplan__query .row__file > :nth-child(1) { grid-area: area-1; }   /* Маркер. */
#jobplan__query .row__file > :nth-child(2) { grid-area: area-2; }   /* Имя файла. */
#jobplan__query .row__file > :nth-child(3) { grid-area: area-3; }   /* Комментарий. */
#jobplan__query .row__file > :nth-child(4) { grid-area: area-4; }   /* Кнопка. */

#jobplan__query .row__file {
	margin-left: 8px ;
	margin-bottom: 8px;
	line-height: 16px;
}

/* Маркер. */
#jobplan__query .row__file > :nth-child(1) {
	justify-self: start;
}

/* Комментарий <textarea>. */
#jobplan__query .row__file textarea {
	margin-top: 2px;
	resize: none;
	width: 493px;
	height: 34px;
	border: solid 1px var(--gray-77);
	padding: 2px 4px;
	font-size: 12px;
	line-height: 14px;
}

/* Поле комментария в фокусе. */
#jobplan__query .row__file textarea:focus {
	border: solid 1px var(--gray-57);
}

/* Поле комментария не заполнено. */
#jobplan__query .row__file textarea::placeholder {
	font-family: 'thin-italic';
	color: var(--gray-77);
}

/* Кнопка. */
#jobplan__query .row__file > :nth-child(4) {
	justify-self: end;
}

/* Кнопка удаления файла. */
#jobplan__query .row__file button {
	width: 76px;
}

/*---------------------------/
  Набор полей: Авансирование
/---------------------------*/

#jobplan__query .modal__content--payment {
	margin-bottom: 16px;
}

/*--- Компоновка: Набор ---*/

#jobplan__query .modal__content--payment {
	display: grid;
	grid-template-columns: 170px auto;
	grid-template-rows: 22px 22px;
	grid-template-areas:
		"area-1 area-2"
		"area-1 area-3";
	align-items: center;
}

#jobplan__query .modal__content--payment > :nth-child(1) { grid-area: area-1; }   /* «Форма …» */
#jobplan__query .modal__content--payment > :nth-child(2) { grid-area: area-2; }   /* Эскроу. */
#jobplan__query .modal__content--payment > :nth-child(3) { grid-area: area-3; }   /* Платформа. */

/* «Форма …» */
#jobplan__query .modal__content--payment > :nth-child(1) {
	font-size: 12px;
	line-height: 14px;
}

/*--- Компоновка: Пункт ---*/

#jobplan__query .modal__content--payment > :nth-child(n+2) {
	display: grid;
	grid-template-columns: 24px 112px auto;
	/* grid-template-rows: auto; */
	grid-template-areas:
		"area-1 area-2 area-3";
	align-items: center;
}

#jobplan__query .modal__content--payment > :nth-child(n+2) > input  { grid-area: area-1; }   /* Радио-кнопка. */
#jobplan__query .modal__content--payment > :nth-child(n+2) > span   { grid-area: area-2; }   /* Форма. */
#jobplan__query .modal__content--payment > :nth-child(n+2) > select { grid-area: area-3; }   /* Счёт. */

/* Радио-кнопка. */
#jobplan__query .modal__content--payment input {
	margin-top: 8px;
}

/* Форма авнасирования: Не выбрана. */
#jobplan__query .modal__content--payment span {
	font-family: 'thin';
	cursor: pointer;
}

/* Форма авнасирования: Выбрана. */
#jobplan__query .modal__content--payment :checked + span {
	font-family: 'bold';
}

/*--- Вывод поля счёта (select) ---*/

/* Поле счёта изначально не выводится. */
#jobplan__query .modal__content--payment select {
	display: none;
}

/* Поле счёта выводится, если выбрана радио-кнопка. */
#jobplan__query .modal__content--payment :checked ~ select {
	display: inline-block;
	width:248px;
	height: 24px;
	font-size: 12px;
}

/* Поле, если нет счёта (выводится служебный текст). */
#jobplan__query .modal__content--payment :checked ~ .query__bank--no {
	background: var(--red24_89);
	color: var(--red48_51);
	font-family: 'bold';
	font-size: 11px;
}

/* Пункт служебного текста не выводится, если есть счёт. */
#jobplan__query .modal__content--payment :checked ~ select [disabled] {
	display: none;
}

/*-----------------------/
  Набор полей: Стоимость
/-----------------------*/

/*--- Компоновка ---*/

#jobplan__query .modal__content--amount {
	display: grid;
	grid-template-columns:  170px auto;
	grid-template-rows: 22px 22px;
	grid-template-areas:
		"area-1 area-2"
		"area-1 area-3";
	align-items: center;
}

#jobplan__query .modal__content--amount > :nth-child(1) { grid-area: area-1; }   /* «Стоимость …» */
#jobplan__query .modal__content--amount > :nth-child(2) { grid-area: area-2; }   /* С проверкой. */
#jobplan__query .modal__content--amount > :nth-child(3) { grid-area: area-3; }   /* Без проверки. */

/* «Стоимость …» */
#jobplan__query .modal__content--amount > :nth-child(1) {
	font-size: 12px;
	line-height: 14px;
}

#jobplan__query .modal__content--amount > :nth-child(2):hover,
#jobplan__query .modal__content--amount > :nth-child(3):hover {
	cursor: pointer;
}

/* С проверкой / Без проверки */
#jobplan__query .modal__content--amount > :nth-child(2),
#jobplan__query .modal__content--amount > :nth-child(3) {
	display: grid;
	grid-template-columns: 24px 166px 182px 4px;
	grid-template-rows: auto;
	grid-template-areas:
	"area-1 area-2 area-3 .";
	align-items: center;
}

#jobplan__query .modal__content--amount > :nth-child(2) > :nth-child(1),
#jobplan__query .modal__content--amount > :nth-child(3) > :nth-child(1) { grid-area: area-1; }   /* Кнопка. */
#jobplan__query .modal__content--amount > :nth-child(2) > :nth-child(2),
#jobplan__query .modal__content--amount > :nth-child(3) > :nth-child(2) { grid-area: area-2; }   /* Надпись. */
#jobplan__query .modal__content--amount > :nth-child(2) > :nth-child(3),
#jobplan__query .modal__content--amount > :nth-child(3) > :nth-child(3) { grid-area: area-3; }   /* Сумма. */

/* Сумма. */
#jobplan__query .modal__content--amount > :nth-child(2) > :nth-child(3),
#jobplan__query .modal__content--amount > :nth-child(3) > :nth-child(3) {
	justify-self: end;
}

#jobplan__query .modal__content--amount input {
	margin-top: 10px;
}
#jobplan__query .modal__content--amount input ~ span {
	font-family: 'thin';
}
#jobplan__query .modal__content--amount input:checked ~ span {
	font-family: 'bold';
}

/*--------------------/
  Набор полей: Запрос
/--------------------*/

/*--- Компоновка ---*/

#jobplan__query .modal__content--apply {
	display: grid;
	grid-template-columns:  150px 272px auto;
	/* grid-template-rows: auto; */
	grid-template-areas:
	"area-1 area-2 area-4"
	"area-1 area-3 area-4";
}

#jobplan__query .modal__content--apply > :nth-child(1) { grid-area: area-1; }   /* Кнопка. */
#jobplan__query .modal__content--apply > :nth-child(2) { grid-area: area-2; }   /* Текст. */
#jobplan__query .modal__content--apply > :nth-child(3) { grid-area: area-3; }   /* Флаг. */
#jobplan__query .modal__content--apply > :nth-child(4) { grid-area: area-4; }   /* Кнопка. */

/* Кнопка «Запросить Разбивку». */
#jobplan__query .modal__content--apply [name="close__apply"] {
	height: 46px;
}

/* Текст. */
#jobplan__query .modal__content--apply > :nth-child(2) {
	font-size: 13px;
	line-height: 14px;
}

/* Ссылка. */
#jobplan__query .modal__content--apply a {
	border-bottom: dotted 1px;
}

/* Флаг. */
#jobplan__query .modal__content--apply input + span {
	margin-left: 4px;
	cursor: pointer;
}
#jobplan__query .modal__content--apply input:checked + span {
	font-family: 'bold';
}

/* Кнопка «Отмена». */
#jobplan__query .modal__content--apply > :nth-child(4) {
	align-self: end;
	justify-self: end;
}










/* !!! OLD !!! */

/*--- Запрос Разбивки ---*/


/*--- Кнопка «Отмена» ---*/

#modal__box .jobplan__query form > button {
	margin-top: -28px;
	float: right;
}
