.paginationWrap {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: solid 1px var(--gray-81);
  background: var(--back-main);

  li {
    display: inline-flex;

    &:not(:first-child) {
      border-left: 1px solid var(--gray-81);
    }
  }

  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: auto;
    min-width: 30px;
    padding: 0 3px;
    border-radius: 0;
    background: none;
    color: var(--text);
    text-shadow: none;
    transition: all 0.2s;

    &:hover {
      background: var(--button-back-normal);
      color: var(--button-text-normal);
      text-shadow: var(--button-text-normal-shade);
    }

    &.active {
      background: var(--back-nav);
      color: var(--gray-light);
      text-shadow: var(--button-text-normal-shade);
    }
  }
}