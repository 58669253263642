.filters {
  display: grid;
  grid-template-columns: 0 200px 200px auto 120px 150px 130px 70px 90px 0;
  grid-column-gap: 6px;
  /* grid-template-rows: auto; */
  grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-6 area-7 area-8 .";
  align-items: center;
  justify-items: center;
  position: sticky;
  z-index: 1;      /* Перекрываем прокручиваемое содержание. */
  top: 120px;

  width: 1240px;   /* Ширина контента. */
  margin-bottom: 6px;

  border-bottom: solid 1px var(--back);
  background: var(--back);

  & > *:nth-child(1) { grid-area: area-1; }   /* Объект/Адрес. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Смета. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Наряд. */
  & > *:nth-child(4) { grid-area: area-4; }   /* Сроки. */
  & > *:nth-child(5) { grid-area: area-5; }   /* Бригадир. */
  & > *:nth-child(6) { grid-area: area-6; }   /* Статус. */
  & > *:nth-child(7) { grid-area: area-7; }   /* «н/р». */
  & > *:nth-child(8) { grid-area: area-8; }   /* Ответчики. */
  

  /*--- Элементы ---*/

  & > *:nth-child(n) {
    width: 100%;
  }

  /* Сроки. */
  .date input[type="text"] {
    text-align: center;
    font-size: 10px;
  }

  & input,
  & select {
    height: 20px;
    font-size: 12px;
  }

  & input {
    padding-bottom: 1px;
  }

  /* Поле раскрывающегося списка. */
  & select {
    padding-right: 4px;
    text-align: center;
  }

  /* Раскраска пунктов в списке статусов. */
  & [name="filter__status"] > :nth-child(3),
  & [name="filter__status"] > :nth-child(5),
  & [name="filter__status"] > :nth-child(7),
  & [name="filter__status"] > :nth-child(11),
  & [name="filter__status"] > :nth-child(13),
  & [name="filter__status"] > :nth-child(15) {
    color: var(--error-dark);
  }

  /* «н/р». */
  & > *:nth-child(7) {
    text-align: center;
    font-family: 'thin';
    cursor: pointer;
  }
  & > *:nth-child(7) > input {
    height: auto;
  }
}