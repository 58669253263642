.replaceFormanModal {
  width: 480px;

  &Notification {
    width: 100%;
    border-bottom: solid 1px var(--gray-83);
    background: var(--blue48_89);
    padding-top: 12px;
    padding-bottom: 6px;
    text-align: center;
  }

  &Username {
    text-align: center;
    font-family: 'bold';
    font-size: 16px;
  }

  &Content {
    padding: 16px 12px;

    hr {
      border-bottom: solid 1px var(--gray-83);
      height: 10px;
      margin-bottom: 10px;
    }
  }

  &Text {
    text-align: center;
  }

  &SelectLabel {
    justify-self: center;
    margin-bottom: 4px;
  }

  &Select {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 136px;
    }
  }
}