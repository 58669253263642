.rate {
  width: 480px;

  &Notification {
    width: 100%;
    border-bottom: solid 1px var(--gray-83);
    background: var(--blue48_89);
    padding-top: 12px;
    padding-bottom: 6px;
    text-align: center;
  }

  &Content {
    display: grid;
    grid-template-columns: 152px auto;
    grid-template-rows: repeat(3, 32px) auto 32px repeat(2, auto);
    grid-template-areas:
		"area-1  area-2 "
		"area-3  area-4 "
		"area-5  area-6 "
		"area-7  area-7 "
		"area-8  area-9 "
		"area-10 area-10"
		"area-11 area-12";
    align-items: center;
    padding: 16px 12px;

    & > *:nth-child(1)  { grid-area: area-1;  }   /* «Рекомендуемая ставка». */
    & > *:nth-child(2)  { grid-area: area-2;  }   /* Рекомендуемая ставка. */
    & > *:nth-child(3)  { grid-area: area-3;  }   /* «Текущая ставка». */
    & > *:nth-child(4)  { grid-area: area-4;  }   /* Текущая ставка. */
    & > *:nth-child(5)  { grid-area: area-5;  }   /* «Новая ставка». */
    & > *:nth-child(6)  { grid-area: area-6;  }   /* Новая ставка. */

    & > *:nth-child(7)  { grid-area: area-7;  }   /* «Код подтверждения». */
    & > *:nth-child(8)  { grid-area: area-8;  }   /* Кнопка СМС. */
    & > *:nth-child(9)  { grid-area: area-9;  }   /* Поле СМС. */

    & > *:nth-child(10) { grid-area: area-10; }   /* Линия. */

    & > *:nth-child(11) { grid-area: area-11; }   /* Кнопка. */
    & > *:nth-child(12) { grid-area: area-12; }   /* Кнопка. */

    /* «Рекомендуемая ставка». */
    & > *:nth-child(1) {
      line-height: 15px;
    }

    /* Поля по ширине окна. */
    & input {
      width: auto;
    }

    /* Кнопка «Отмена». */
    & > *:nth-child(12) {
      justify-self: end;
    }

    /* Ширина кнопок. */
    & button {
      width: 136px;
    }
  }

  &CodeTitle {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;
    background: var(--gray-87);
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: center;
    font-family: 'thin';
    font-size: 16px;
    text-shadow: var(--button-text-disable-shade);
  }
}