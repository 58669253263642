.waitIcon {
  position: fixed;
  width: 100px;
  height: 100px;
  background-size: contain;
  background-image: url(./waiting-icon.jpg);
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  z-index: 999;
}

.waitIconDisabled{
  display: none;
}

.percentPanel {
  position: fixed;
  left: calc(50% - 100px);
  top: calc(50% + 50px);
  width: 200px;
  height: 20px;
  background-color: white;
  border: 1px gray solid;
  border-radius: 5px;
  z-index: 999;
}

.progressBar {
  width: 0%;
  height: 100%;
  background-color: dodgerblue;
}

.percentText {
  white-space: nowrap;
  margin-left: 8px;
}