/*============================================/
    Блокировка Прораба (.locking__foreman)
  Разблокировка Прораба (.unlocking__foreman)
/============================================*/

/*--- Размер окна контейнера ---*/

/*
	Основной размер окна определяем в переменных в контексте темы.
	Ширина окна --window-width должна учитывать:
	columns {сумма всех колонок} + margin-left 20px + поле справа 20px.
	Высота окна --window-height должна учитывать:
	общая высота строк вложенной формы + заголовок + отступ снизу.
*/
#modal__box .locking__foreman,
#modal__box .unlocking__foreman {
  --window-width: 440px;
}

#modal__box .locking__foreman {
  --window-height: 214px;
}
#modal__box .unlocking__foreman {
  --window-height: 196px;
}

/* Фон окна. */
#modal__box .locking__foreman,
#modal__box .unlocking__foreman {
  background: var(--error-light);
}

#modal__box .locking__foreman h3,
#modal__box .unlocking__foreman h3 {
  background: var(--error-dark);
  color: var(--error-light);
}

#modal__box .locking__foreman div,
#modal__box .unlocking__foreman div {
  margin-bottom: 4px;
  border-bottom: none;
  background: var(--error-light);
  color: var(--error-dark);
}

#modal__box .locking__foreman p,
#modal__box .unlocking__foreman p {
  margin-bottom: 20px;
  text-align: center;
  font-family: "bold-italic";
  font-size: 16px;
}

#modal__box .locking__foreman p + button,
#modal__box .unlocking__foreman p + button {
  float: left;
  margin-left: 20px;
}

#modal__box .locking__foreman button + button,
#modal__box .unlocking__foreman button + button {
  float: right;
  margin-right: 20px;
}
