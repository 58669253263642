.buildingSection {
  margin: 0 12px 6px;
  border: solid 1px var(--gray-85);
  background: var(--orange08_93);
}

.buildingRow {
  display: grid;
  grid-template-columns: 6px 28px auto 160px repeat(4, 140px) 12px;
  grid-template-rows: 42px;
  grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-6 area-7 .";
  align-items: center;

  & > *:nth-child(1) { grid-area: area-1; }   /* Переключатель. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Объект. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Дата. */
  & > *:nth-child(4) { grid-area: area-4; }   /* Оплата Платформе. */
  & > *:nth-child(5) { grid-area: area-5; }   /* Оплата Бригады. */
  & > *:nth-child(6) { grid-area: area-6; }   /* Акт Платформа. */
  & > *:nth-child(7) { grid-area: area-7; }   /* Акт Бригада. */

  /* Переключатель, Объект. */
  & > *:nth-child(1),
  & > *:nth-child(2) {
    justify-self: start;
  }

  /* Переключатель: выравнивание. */
  & > *:nth-child(1) > span {
    top: 2px;
  }

  /* Объект. */
  & > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  /* Объект: Название. */
  & > *:nth-child(2) > a {
    border-bottom: dotted 1px;
  }

  /* Объект: Адрес. */
  & > *:nth-child(2) > span {
    font-family: 'thin-italic';
    font-size: 12px;
  }

  /* Дата. */
  & > *:nth-child(3) {
    justify-self: center;
  }

  /* Колонки с числами. */
  & > *:nth-child(n+4) {
    justify-self: end;
  }
}