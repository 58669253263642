/*====================================/
  Создание Цепочки (.creating__chain)
/====================================*/

/*--- Размер окна контейнера ---*/

/*
	Основной размер окна определяем в переменных в контексте темы.
	Ширина окна --window-width должна учитывать:
	columns {сумма всех колонок} + margin-left 20px + поле справа 20px.
	Высота окна --window-height должна учитывать:
	общая высота строк вложенной формы + заголовок + отступ снизу.
*/
#modal__box .creating__chain {
  --window-width: 480px;
  --window-height: 274px;
}

/* Фон окна. */
#modal__box .creating__chain {
  background: var(--back-estimate-offer);
}

/*--- Области формы ---*/

#modal__box .creating__chain form {
  display: grid;
  grid-template-columns: 80px 120px 28px 92px 120px;
  grid-template-rows: repeat(2, 32px) 64px;
  grid-template-areas:
    "child-1 child-2 child-2 child-2 child-2"
    "child-3 child-4 .       child-5 child-6"
    "child-7 .       .       .       child-8";
  margin-left: 20px;
}

#modal__box .creating__chain form > :nth-child(1) {
  grid-area: child-1;
} /* Название Цепочки. */
#modal__box .creating__chain form > :nth-child(2) {
  grid-area: child-2;
} /* Название Цепочки, поле. */
#modal__box .creating__chain form > :nth-child(3) {
  grid-area: child-3;
} /* Дата начала. */
#modal__box .creating__chain form > :nth-child(4) {
  grid-area: child-4;
} /* Дата начала, поле. */
#modal__box .creating__chain form > :nth-child(5) {
  grid-area: child-5;
} /* Дата окончания. */
#modal__box .creating__chain form > :nth-child(6) {
  grid-area: child-6;
} /* Дата окончания, поле. */
#modal__box .creating__chain form > :nth-child(7) {
  grid-area: child-7;
} /* Кнопка подтверждения. */
#modal__box .creating__chain form > :nth-child(8) {
  grid-area: child-8;
  justify-self: end;
} /* Кнопка отмены. */

/* Ширина полей зависит от ширины окна. */
#modal__box .creating__chain input {
  width: auto;
}

#modal__box .creating__chain > :nth-child(2) p {
  margin-bottom: 0;
}

#modal__box .creating__chain > :nth-child(2) > :nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px; /* В расчёте на 3 строки текста. */
  padding-right: 20px;
  padding-left: 20px;
}

#modal__box .creating__chain > :nth-child(2) > :nth-child(1),
#modal__box .creating__chain > :nth-child(2) > :nth-child(2) span {
  font-family: "bold";
}
