/*
	Страница «Карточка сметы»
	.page__estimate--card
*/

/*========================/
  Непрокручиваемые панели
/========================*/

/* При прокрутке прижимаются к верхней панели. */

/*--- Позиционирование ---*/

.page__estimate--card .bar__requisites,
.page__estimate--card .bar__status {
	position: sticky;
	z-index: 1;      /* Перекрываем прокручиваемое содержание. */
	width: 1240px;   /* Ширина контента. */
	border-top: solid 1px var(--back);
	border-bottom: solid 1px var(--back);
	color: var(--back);
}

/* Панель реквизитов. */
.page__estimate--card .bar__requisites {
	top: 76px;   /* = высота панели вкладок. */
	margin-bottom: 8px;
	background: var(--back__tab-hover);
	padding-left: 6px;
}

/* Статусная панель. */
.page__estimate--card .bar__status {
	top: 123px;
	margin-bottom: 8px;
	min-height: 44px;
	border-bottom: solid 2px var(--back);
	background: var(--text-head);
	padding: 4px 6px 5px;
}





/*==================/
  Панель реквизитов
/==================*/

/* При прокрутке прижимается к верхней панели, но не прокручивается. */

/*-----------/
  Компоновка
/-----------*/

.page__estimate--card .bar__requisites {
	display: grid;
	grid-template-columns: 348px 388px 288px auto;
	grid-column-gap: 8px;
	grid-template-rows: 24px 22px;
	grid-template-areas:
		"area-1 area-3 area-5 area-6"
		"area-2 area-4 area-5 area-6";
	/* align-items: center; */
}

.page__estimate--card .bar__requisites > :nth-child(1) { grid-area: area-1; align-self: end;    }   /* Объект. */
.page__estimate--card .bar__requisites > :nth-child(2) { grid-area: area-2; align-self: start;  }   /* Адрес. */
.page__estimate--card .bar__requisites > :nth-child(3) { grid-area: area-3; align-self: end;    }   /* Смета. */
.page__estimate--card .bar__requisites > :nth-child(4) { grid-area: area-4; align-self: start;  }   /* Тип сметы. */
.page__estimate--card .bar__requisites > :nth-child(5) { grid-area: area-5; align-self: center; }   /* Примечание. */
.page__estimate--card .bar__requisites > :nth-child(6) { grid-area: area-6; }   /* Даты. */

/*---------/
  Элементы
/---------*/

/*--- Черта под ссылкой ---*/

.page__estimate--card .bar__requisites .link-light {
	border-bottom: dotted 1px;
}

/* Иначе черта не видна (overflow: hidden). */
.page__estimate--card .bar__requisites > :nth-child(1) {
	padding-bottom: 1px;
}

/*--- Шрифты ---*/

.page__estimate--card .bar__requisites > :nth-child(2),
.page__estimate--card .bar__requisites > :nth-child(4),
.page__estimate--card .bar__requisites > :nth-child(5),
.page__estimate--card .bar__requisites > :nth-child(6) > :nth-child(1) {
	font-family: 'thin';
}
.page__estimate--card .bar__requisites > :nth-child(2),
.page__estimate--card .bar__requisites > :nth-child(4),
.page__estimate--card .bar__requisites > :nth-child(5) {
	font-size: 12px;
}

/*--- Примечание ---*/

.page__estimate--card .bar__requisites > :nth-child(5) {
	height: 36px;
	border: solid 1px var(--gray-57);
	padding: 3px 4px;
	line-height: 14px;
	overflow: auto;
}

/*--- Даты ---*/

.page__estimate--card .bar__requisites > :nth-child(6) {
	display: grid;
	grid-template-columns: 78px auto 78px;
	grid-template-areas:
		"area-1 area-1 area-1"
		"area-2 area-3 area-4";
}

.page__estimate--card .bar__requisites > :nth-child(6) > :nth-child(1) { grid-area: area-1; align-self: end;   justify-self: center; }   /* Надпись. */
.page__estimate--card .bar__requisites > :nth-child(6) > :nth-child(2) { grid-area: area-2; align-self: start; justify-self: end;    }   /* Дата. */
.page__estimate--card .bar__requisites > :nth-child(6) > :nth-child(3) { grid-area: area-3; align-self: start; justify-self: center; }   /* Тире. */
.page__estimate--card .bar__requisites > :nth-child(6) > :nth-child(4) { grid-area: area-4; align-self: start; justify-self: start;  }   /* Дата. */





/*=================/
  Статусная панель
/=================*/

/* При прокрутке прижимается к панели реквизитов, но не прокручивается. */

/*-----------/
  Компоновка 202px 282px   90px 70px 160px 120px auto
/-----------*/

.page__estimate--card .bar__status {
	display: grid;
	grid-template-columns: 202px 282px 90px 160px 120px 80px 130px auto;
	grid-column-gap: 8px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		"area-1 area-2 area-3 area-4 area-5 area-6 area-7 area-8";
	align-items: center;
}

.page__estimate--card .bar__status > :nth-child(1) { grid-area: area-1; }   /* Статус. */
.page__estimate--card .bar__status > :nth-child(2) { grid-area: area-2; }   /* Кнопки. */
.page__estimate--card .bar__status > :nth-child(3) { grid-area: area-3; }   /* Расчёт КП. */
.page__estimate--card .bar__status > :nth-child(4) { grid-area: area-4; }   /* Прораб. */
.page__estimate--card .bar__status > :nth-child(5) { grid-area: area-5; }   /* Авансирование. */
.page__estimate--card .bar__status > :nth-child(6) { grid-area: area-6; }   /* Разбивка (ссылка). */
.page__estimate--card .bar__status > :nth-child(7) { grid-area: area-7; }   /* Вариант расчёта. */
.page__estimate--card .bar__status > :nth-child(8) { grid-area: area-8; }   /* Стоимость. */

.page__estimate--card .bar__status > :nth-child(6) {
	text-align: center;
}

/*---------/
  Элементы
/---------*/

/*--- Выравнивание в областях ---*/

.page__estimate--card .bar__status > :nth-child(1),
.page__estimate--card .bar__status > :nth-child(2),
.page__estimate--card .bar__status > :nth-child(3),
.page__estimate--card .bar__status > :nth-child(4),
.page__estimate--card .bar__status > :nth-child(5),
.page__estimate--card .bar__status > :nth-child(7) {
	display: flex;
	align-items: center;
}

/* Область кнопок. */
.page__estimate--card .bar__status > :nth-child(2) {
	flex-direction: row;
	justify-content: space-around;
}

/* Другие области. */
.page__estimate--card .bar__status > :nth-child(3),
.page__estimate--card .bar__status > :nth-child(4),
.page__estimate--card .bar__status > :nth-child(5),
.page__estimate--card .bar__status > :nth-child(7) {
	flex-direction: column;
}

/*--- Шрифт (надписи) ---*/

.page__estimate--card .bar__status > :nth-child(3) > :nth-child(1),
.page__estimate--card .bar__status > :nth-child(4) > :nth-child(1),
.page__estimate--card .bar__status > :nth-child(5) > :nth-child(1),
.page__estimate--card .bar__status > :nth-child(7) > :nth-child(1) {
	font-family: 'thin';
	font-size: 12px;
}

/*--- Стоимость ---*/

/* Компоновка. */
.page__estimate--card .bar__status > :nth-child(8) {
	display: grid;
	grid-template-columns: 68px auto;
	grid-template-rows: auto 16px;
	grid-template-areas:
		"area-1 area-1"
		"area-2 area-3";
}

/* Сумма Сметы. */
.page__estimate--card .bar__status > :nth-child(8) > :nth-child(1) {
	grid-area: area-1;
	justify-self: end;

	font-family: 'bold';
	font-size: 16px;
}

/* «Ставка». */
.page__estimate--card .bar__status > :nth-child(8) > :nth-child(2) {
	grid-area: area-2;
	justify-self: end;

	font-family: 'thin';
	font-size: 12px;
}

/* Ставка. */
.page__estimate--card .bar__status > :nth-child(8) > :nth-child(3) {
	grid-area: area-3;
	justify-self: end;

	font-size: 12px;
}

/*--- Статус ---*/

.page__estimate--card .bar__status > :nth-child(1) > span {
	display: inline-block;
	border: solid 1px var(--back);
	padding: 2px 5px 3px;
}

/*--- Кнопки ---*/

/*
	По умолчанию кнопки не выводятся.
	Алгоритм вывода прописан в контексте роли в файлах:
		contractor_role.css — «Подрядчик»
		estimator_role.css — «Сметчик»
*/

.page__estimate--card .bar__status button {
	display: none;
}

/* Оформление для статусной панели. */
.page__estimate--card .bar__status button {
	width: 136px;
	border: solid 1px var(--back);
}

/*
	Кнопки «Сохранить» и «Отмена».
	Выводятся в режиме редактирования (mode__edit).
	Роль значения не имеет.
*/
.page__estimate--card.status__estimate--new.mode__edit [name^="estimate__edit--"],
.page__estimate--card.status__estimate--query.mode__edit [name^="estimate__edit--"],
.page__estimate--card.status__estimate--calc.mode__edit [name^="estimate__edit--"],
.page__estimate--card.status__estimate--add-data.mode__edit [name^="estimate__edit--"],
.page__estimate--card.status__estimate--requery.mode__edit [name^="estimate__edit--"],
.page__estimate--card.status__estimate--add-data.mode__edit [name^="estimate__edit--"],
.page__estimate--card.status__jobplan--add-data.mode__edit [name^="estimate__edit--"] {
	display: flex;   /* Исходное значение. */
}

/*--- Текст на месте кнопок ---*/

/*
	По умолчанию текст не выводятся.
	Алгоритм вывода прописан в контексте роли в файлах:
		contractor_role.css — «Подрядчик»
		estimator_role.css — «Сметчик»
*/

.page__estimate--card .bar__status .text__estimate--no-change {
	display: none;
}

.page__estimate--card .bar__status .text__estimate--no-change {
	text-align: center;
	font-family: 'thin-italic';
	font-size: 12px;
	line-height: 14px;
	color: var(--gray-77);
}

.page__estimate--card .bar__status .text__estimate--no-change::before {
	content: "Изменение сметы недоступно";
}

/*--- Ссылка ---*/

.page__estimate--card .bar__status a {
	border-bottom: dotted 1px;
}

/*--- Вариант расчёта ---*/

/*
	Скрываем <select> — выводится прочерк.
	Скрываем прочерк — выводится <select>.
*/

/* Прочерк. */
.page__estimate--card .bar__status > :nth-child(7) > :nth-of-type(2) {
	display: none;
}
.page__estimate--card[class*="status__estimate--"] .bar__status > :nth-child(7) > :nth-of-type(2),
.page__estimate--card[class*="status__jobplan--query"] .bar__status > :nth-child(7) >:nth-of-type(2) {
	display: inline-block;
}

/* <select> */
.page__estimate--card[class*="status__estimate--"] .bar__status > :nth-child(7) > select,
.page__estimate--card[class*="status__jobplan--query"] .bar__status > :nth-child(7) > select {
	display: none;
}

.page__estimate--card .bar__status select {
	width: 116px;
	height: 18px;
	padding-left: 0;
	text-align: center;
	font-size: 12px;
	line-height: 12px;
}

.page__estimate--card .bar__status select {
	color: var(--input-text-normal);
}

.page__estimate--card .bar__status select:disabled {
	opacity: 1;
	background: var(--gray-77);
	color: var(--gray-57);
}





/*============================/
  Область раскрываемых секций
/============================*/

/*--- Позиционарование ---*/

.page__estimate--card .area__sections {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 8px;
}

/*--- Боксы секций: левый/правый ---*/

.page__estimate--card .box__sections {
	width: 612px;
}

/*-----------------/
  Заголовок Секции
/-----------------*/

/*--- Компоновка ---*/

.page__estimate--card [class^="section__"] > :nth-child(1) {
	display: grid;
	grid-template-columns: 28px auto 76px 22px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		"area-1 area-2 area-3 area-4";
	align-items: center;
}

.page__estimate--card [class^="section__"] > :nth-child(1) > :nth-child(1) { grid-area: area-1; }   /* Переключатель. */
.page__estimate--card [class^="section__"] > :nth-child(1) > :nth-child(2) { grid-area: area-2; }   /* Название Секции. */
.page__estimate--card [class^="section__"] > :nth-child(1) > :nth-child(3) { grid-area: area-3; justify-self: end; }   /* «Файлов:…» */
.page__estimate--card [class^="section__"] > :nth-child(1) > :nth-child(4) { grid-area: area-4; justify-self: end; }   /* Количество. */

/*--- Элементы ---*/

/* Заголовок Секции. */
.page__estimate--card [class^="section__"] > :nth-child(1) {
	margin-bottom: 6px;
	border: solid 1px var(--gray-67);
	background: var(--back__head-section);
	padding: 3px 6px;
	color: var(--back__tab-hover);
	font-size: 16px;
}

/* Заголовок при открытой Секции. */
.page__estimate--card [class^="section__"].open > :nth-child(1) {
	margin-bottom: 0;
	border-bottom: none;
}

/* Надпись «Файлов:» и количество. */
.page__estimate--card [class^="section__"] > :nth-child(1) > :nth-child(3),
.page__estimate--card [class^="section__"] > :nth-child(1) > :nth-child(4) {
	font-size: 12px;
}

/* Надпись «Файлов:». */
.page__estimate--card [class^="section__"] > :nth-child(1) > :nth-child(3) {
	font-family: 'thin';
}

/*---------------/
  Секции (общее)
/---------------*/

/* Секция по умолчанию свёрнута. */
.page__estimate--card [class^="section__"] > :nth-child(2) {
	display: none;
}

.page__estimate--card [class^="section__"].open > :nth-child(2) {
	display: block;
	margin-bottom: 12px;
	border: solid 1px var(--gray-67);
	background: var(--back-main);
	padding-top: 6px;
	padding-bottom: 4px;
}

/*--------------/
  Секции файлов
/--------------*/

/*--- Недоступные секции ---*/

/*
	Оформление в зависмости от статуса:
		«ТЗ на Разбивку» — все статусы «__estimate--».
		«Договор» — все статусы «__estimate--», исключая «КП принято».
	За добавление/удаление класса «disabled» отвечает фронтенд.
*/

.page__estimate--card[class*="status__estimate--"] .section__files--jobplan *,
.page__estimate--card[class*="status__estimate--"]:not(.status__estimate--accept) .section__files--contract * {
	background: var(--gray-77);
	border-color: var(--gray-67);
	color: var(--gray-57);
	cursor: not-allowed;
}

/*--- Строка файла ---*/

/* Компоновка: Общее. */
.page__estimate--card .area__sections .row__file {
	display: grid;
	grid-template-rows: 20px auto;
	align-items: center;
}

.page__estimate--card .area__sections .row__file > :nth-child(1) { grid-area: area-1; justify-self: start; }   /* Маркер. */
.page__estimate--card .area__sections .row__file > :nth-child(2) { grid-area: area-2; }   /* Ссылка. */

.page__estimate--card .area__sections .row__file {
	margin-left: 20px ;
	margin-bottom: 4px;
	line-height: 16px;
}

/* Компоновка: Просмотр. */
.page__estimate--card:not(.mode__edit) .area__sections .row__file {
	grid-template-columns: 16px auto 6px;
	grid-template-areas:
		"area-1 area-2 ."
		".       area-3 .";
}

.page__estimate--card:not(.mode__edit) .area__sections .row__file > :nth-child(3) { grid-area: area-3; }   /* Комментарий. */
.page__estimate--card:not(.mode__edit) .area__sections .row__file > :nth-child(4) { display: none; }   /* Комментарий. */
.page__estimate--card:not(.mode__edit) .area__sections .row__file > :nth-child(5) { display: none; }   /* Кнопка. */

/* Компоновка: Редактирование. */
.page__estimate--card.mode__edit .area__sections .row__file {
	grid-template-columns: 16px auto 94px 6px;
	grid-template-areas:
		"area-1 area-2 area-5 ."
		".       area-4 .       .";
}

.page__estimate--card.mode__edit .area__sections .row__file > :nth-child(3) { display: none; }   /* Комментарий. */
.page__estimate--card.mode__edit .area__sections .row__file > :nth-child(4) { grid-area: area-4; }   /* Комментарий. */
.page__estimate--card.mode__edit .area__sections .row__file > :nth-child(5) { grid-area: area-5; justify-self: end; }   /* Кнопка. */

/*--- Элементы строки файла ---*/

/* Ссылка. */
.page__estimate--card .area__sections .row__file a {
	border-bottom: dotted 1px;
}

/* Комментарий <span>. */
.page__estimate--card .area__sections .row__file > :nth-child(3) {
	font-size: 12px;
	line-height: 14px;
}

/* Комментарий <textarea> в режиме редактирования. */
.page__estimate--card .area__sections .row__file > :nth-child(4) {
	margin-top: 2px;
	resize: none;
	width: auto;
	height: 34px;
	border: solid 1px var(--gray-77);
	padding: 2px 4px;
	font-size: 12px;
	line-height: 14px;
}

/* Поле комментария в фокусе. */
.page__estimate--card .area__sections .row__file > :nth-child(4):focus {
	border: solid 1px var(--gray-57);
}

/* Поле комментария не заполнено. */
.page__estimate--card .area__sections .row__file > :nth-child(4)::placeholder {
	font-family: 'thin-italic';
	color: var(--gray-77);
}

/* Кнопка удаления файла. */
.page__estimate--card .area__sections .row__file > :nth-child(5) {
	width: 76px;
}

/*--- Форма добавления файла ---*/

/* Выводится только в режиме редактирования. */
.page__estimate--card [class^="section__files--"] form {
	display: none;
}

.page__estimate--card.mode__edit [class^="section__files--"] form {
	display: grid;
	grid-template-columns: 116px auto;
	grid-template-rows: 24px 42px 20px;
	grid-template-areas:
		"area-1 area-1"
		"area-2 area-3"
		"area-4 area-4";
}

.page__estimate--card [class^="section__files--"] form > :nth-child(1) { grid-area: area-1; }   /* Добавление файла. */
.page__estimate--card [class^="section__files--"] form > :nth-child(2) { grid-area: area-2; }   /* «Комментарий». */
.page__estimate--card [class^="section__files--"] form > :nth-child(3) { grid-area: area-3; }   /* Комментарий. */
.page__estimate--card [class^="section__files--"] form > :nth-child(4) { grid-area: area-4; }   /* Кнопка. */

/* Выводится только в режиме редактирования. */
.page__estimate--card.mode__edit [class^="section__files--"] form {
	margin: 12px 8px 4px;
	border-top: solid 1px var(--gray-77);
	padding-top: 12px;
	font-size: 12px;
}

/* Надпись «Комментарий:» */
.page__estimate--card [class^="section__files--"] form > :nth-child(2) {
	margin-top: 2px;
	margin-right: 8px;
	justify-self: end;
}

/* Поле комментария. */
.page__estimate--card [class^="section__files--"] form > textarea {
	resize: none;
	width: auto;
	height: 34px;
	border: solid 1px var(--gray-77);
	padding: 2px 4px;
	font-size: 12px;
	line-height: 14px;
}

/* Поле комментария в фокусе. */
.page__estimate--card [class^="section__files--"] form > textarea:focus {
	border: solid 1px var(--gray-57);
}

/* Поле комментария не заполнено. */
.page__estimate--card [class^="section__files--"] form > textarea::placeholder {
	font-family: 'thin-italic';
	color: var(--gray-77);
}

/* Кнопка добавления файла. */
.page__estimate--card [class^="section__files--"] form > button {
	width: 108px;
}





/*-----------------------------/
  Секция «История» (сообщения)
/-----------------------------*/

/*--- Компоновка ---*/

.page__estimate--card .message__event {
	display: grid;
	grid-template-columns: 6px 120px auto 6px;
	grid-template-rows: 18px auto;
	grid-template-areas:
		". area-1 area-2 ."
		". area-3 area-3 .";
}

.page__estimate--card .message__event > :nth-child(1) { grid-area: area-1; }   /* Дата. */
.page__estimate--card .message__event > :nth-child(2) { grid-area: area-2; }   /* Автор. */
.page__estimate--card .message__event > :nth-child(3) { grid-area: area-3; }   /* Сообщение. */

/*--- Раскрываемый бокс Сообщений ---*/

.page__estimate--card .section__history > :nth-child(2) {
	height: 200px;
	overflow: auto;
}

/*--- Блок Сообщения ---*/

.page__estimate--card .message__event {
	margin-bottom: 10px;
	border-bottom: solid 1px var(--gray-87);
	padding-bottom: 10px;
	font-size: 12px;
	line-height: 14px;
}

.page__estimate--card .section__history .message__event:first-child {
	margin-top: 6px;
}
.page__estimate--card .section__history .message__event:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}

.page__estimate--card .message__event > :nth-child(1) {
	font-family: 'thin-italic';
}

.page__estimate--card .message__event > :nth-child(2) {
	font-family: 'bold-italic';
}

/*--- Ввод текста сообщения ---*/

/* По умолчанию поле скрыто. */
.page__estimate--card .section__history .message__new {
	display: none;
}

/* Выводим в режиме редактирования. */
.page__estimate--card.mode__edit .section__history .message__new {
	display: block;
	margin: 8px 0 12px 26px;
	resize: none;
	width: 554px;
	height: 50px;
	border: solid 1px var(--gray-77);
	padding: 3px 4px;
	font-size: 12px;
	line-height: 14px;
}

/* Поле в фокусе. */
.page__estimate--card.mode__edit .section__history .message__new:focus {
	border: solid 1px var(--gray-57);
}

/* Поле не заполнено. */
.page__estimate--card.mode__edit .section__history .message__new::placeholder {
	font-family: 'thin-italic';
	color: var(--gray-77);
}




/*=====================/
  Область оборудования
/=====================*/

/*-----------/
  Компоновка
/-----------*/

.page__estimate--card .area__equipments {
	display: grid;
	grid-template-columns: 620px 620px;   /* =1240px */
	grid-template-rows: 34px max-content;
	grid-template-areas:
		"area-2  area-4 "
		"area-n5 area-n5";
}

/* :nth-child(1) — радио-кнопка для вкладки 1, скрыта. */
.page__estimate--card .area__equipments > :nth-child(2)   { grid-area: area-2;  }   /* Ярлык вкладки 1. */
/* :nth-child(3) — радио-кнопка для вкладки 2, скрыта. */
.page__estimate--card .area__equipments > :nth-child(4)   { grid-area: area-4;  }   /* Ярлык вкладки 2. */

/* :nth-child с 5 — содержание текущей вкладки. */
.page__estimate--card .area__equipments > :nth-child(n+5) { grid-area: area-n5; }

/*--------------------/
  Вкладки группировок
/--------------------*/

/*--- Радиокнопки ---*/

/* Индикаторы скрыты (переключение по надписям). */
.page__estimate--card .area__equipments > input[type="radio"] {
	display: none;
}

/*--- Ярлыки вкладок ---*/

/* Общий вид. */
.page__estimate--card .area__equipments > label {
	display: block;
	position: relative;   /* Для выравнивания вложенного <span>. */
	width: 620px;
	height: 34px;
	border: solid 2px;
	text-align: center;

	transition: all 0.3s ease-in-out;
}

/*	<span> фоном перекрывает границу текущего содержания. */
.page__estimate--card .area__equipments > label > span {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	padding-top: 6px;
}

/* Неактивный ярлык. */
.page__estimate--card .area__equipments :not(:checked) + label {
	background: var(--text-head);
	border-color: var(--back);
	font-family: 'thin';
	color: var(--back);
}

/* Неактивный ярлык при наведении. */
.page__estimate--card .area__equipments :not(:checked) + label:hover {
	background: var(--back__tab-hover);
	cursor: pointer;
}

/* Текущий ярлык. */
.page__estimate--card .area__equipments > :checked + label {
	border-color: var(--blue__48-53);
	font-family: 'bold';
	color: var(--text-head);
	cursor: default;
}

/*	<span> фоном перекрывает границу текущего содержания. */
.page__estimate--card .area__equipments > :checked + label span {
	background: var(--back-main);
	height: 34px;
}

/*-------------------/
  Содержание вкладки
/-------------------*/

/*--- Переключение вкладок ---*/

/* Блоки содержания изначально скрыты. */
.page__estimate--card .area__equipments > [id^="content__grouping--"] {
	display: none;
}

/* Привязка содержания к выбранной вкладке. */
.page__estimate--card #tab__grouping--1:checked ~ #content__grouping--1,
.page__estimate--card #tab__grouping--2:checked ~ #content__grouping--2 {
	display: block;
	border: solid 2px var(--blue__48-53);
	background: var(--back-main);
	padding-top: 12px;
}

/*--- Список оборудования в группе ---*/

/* Блок списка по умолчанию скрыт. */
.page__estimate--card [class^="group__header--"] + [class^="group__list--"] {
	display: none;
}

/* Общее оформление блока списка. */
.page__estimate--card [class^="group__header--"].open + [class^="group__list--"] {
	display: block;
	margin-bottom: 12px;
	margin-right: 6px;
	margin-left: 30px;
	border: solid 1px var(--gray-67);
	background: var(--back-main);
}

/*----------------/
  Заголовки групп
/----------------*/

/*--- Компоновка ---*/

/* Система. */
.page__estimate--card .group__header--system {
	display: grid;
	grid-template-columns: 6px 24px auto 48px 96px 48px 116px 48px 120px 6px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		". area-1 area-2 . area-3 . area-4 . area-5 .";
	align-items: center;
}

.page__estimate--card .group__header--system > :nth-child(1) { grid-area: area-1; }   /* Переключатель. */
.page__estimate--card .group__header--system > :nth-child(2) { grid-area: area-2; }   /* Система. */
.page__estimate--card .group__header--system > :nth-child(3) { grid-area: area-3; }   /* Позиции. */
.page__estimate--card .group__header--system > :nth-child(4) { grid-area: area-4; }   /* Количество. */
.page__estimate--card .group__header--system > :nth-child(5) { grid-area: area-5; }   /* Сумма. */

/* Работа. */
.page__estimate--card .group__header--jobprice {
	display: grid;
	grid-template-columns: 6px 24px auto 32px 112px 32px 92px 32px 108px 32px 120px 6px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		". area-1 area-2 . area-3 . area-4 . area-5 . area-6 .";
	align-items: center;
}

.page__estimate--card .group__header--jobprice > :nth-child(1) { grid-area: area-1; }   /* Переключатель. */
.page__estimate--card .group__header--jobprice > :nth-child(2) { grid-area: area-2; }   /* Работа. */
.page__estimate--card .group__header--jobprice > :nth-child(3) { grid-area: area-3; }   /* Единица измерения. */
.page__estimate--card .group__header--jobprice > :nth-child(4) { grid-area: area-4; }   /* Количество. */
.page__estimate--card .group__header--jobprice > :nth-child(5) { grid-area: area-5; }   /* Цена. */
.page__estimate--card .group__header--jobprice > :nth-child(6) { grid-area: area-6; }   /* Сумма. */

/*--- Элементы ---*/

/* Строка заголовка группы. */
.page__estimate--card [class^="group__header--"] {
	margin-bottom: 6px;
	color: var(--back__tab-hover);
}
.page__estimate--card [class^="group__header--"].open {
	margin-bottom: 1px;
}


/* Переключатель. */
.page__estimate--card [class^="group__header--"] > :nth-child(1) {
	margin-top: 4px;
}

/* Название Системы/Работы. */
.page__estimate--card [class^="group__header--"] > :nth-child(2) {
	border: solid 1px var(--gray-67);
	background: var(--back__head-section);
	padding: 3px 6px;
	font-size: 16px;
}

/* Выравнивание надписей и чисел. */
.page__estimate--card [class^="group__header--"] > :nth-child(3),
.page__estimate--card [class^="group__header--"] > :nth-child(4),
.page__estimate--card [class^="group__header--"] > :nth-child(5),
.page__estimate--card [class^="group__header--"] > :nth-child(6) {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

/* Шрифты надписей. */
.page__estimate--card [class^="group__header--"] > :nth-child(3) > :nth-child(1),
.page__estimate--card [class^="group__header--"] > :nth-child(4) > :nth-child(1),
.page__estimate--card [class^="group__header--"] > :nth-child(5) > :nth-child(1),
.page__estimate--card [class^="group__header--"] > :nth-child(6) > :nth-child(1) {
	font-family: 'thin';
}

/*----------------/
  Строка в списке
/----------------*/

/*--- Компоновка: Система ---*/

.page__estimate--card [class*="--system"] [class^="list__row--"] {
	display: grid;
	grid-template-columns: 50px  auto 80px 80px 260px 90px 90px;
	grid-column-gap: 12px;
	grid-template-areas:
		"area-1 area-2 area-3 area-4 area-5 area-6 area-7";
	align-items: center;
}

.page__estimate--card [class*="--system"] [class^="list__row--"] > :nth-child(1) { grid-area: area-1; }   /* Номер. */
.page__estimate--card [class*="--system"] [class^="list__row--"] > :nth-child(2) { grid-area: area-2; }   /* Оборудование. */
.page__estimate--card [class*="--system"] [class^="list__row--"] > :nth-child(3) { grid-area: area-3; justify-self: center }   /* Единица измерения. */
.page__estimate--card [class*="--system"] [class^="list__row--"] > :nth-child(4) { grid-area: area-4; justify-self: center }   /* Количество. */
.page__estimate--card [class*="--system"] [class^="list__row--"] > :nth-child(5) { grid-area: area-5; }   /* Работа. */
.page__estimate--card [class*="--system"] [class^="list__row--"] > :nth-child(6) { grid-area: area-6; justify-self: end    }   /* Цена. */
.page__estimate--card [class*="--system"] [class^="list__row--"] > :nth-child(7) { grid-area: area-7; justify-self: end    }   /* Стоимость. */

/*--- Компоновка: Работа ---*/

.page__estimate--card [class*="--jobprice"] [class^="list__row--"] {
	display: grid;
	grid-template-columns: 50px 260px auto 90px 90px;
	grid-column-gap: 12px;
	grid-template-areas:
		"area-1 area-2 area-3 area-4 area-5";
	align-items: center;
}

.page__estimate--card [class*="--jobprice"] [class^="list__row--"] > :nth-child(1) { grid-area: area-1; }   /* Номер. */
.page__estimate--card [class*="--jobprice"] [class^="list__row--"] > :nth-child(2) { grid-area: area-2; }   /* Система. */
.page__estimate--card [class*="--jobprice"] [class^="list__row--"] > :nth-child(3) { grid-area: area-3; }   /* Оборудование. */
.page__estimate--card [class*="--jobprice"] [class^="list__row--"] > :nth-child(4) { grid-area: area-4; justify-self: center }   /* Единица измерения. */
.page__estimate--card [class*="--jobprice"] [class^="list__row--"] > :nth-child(5) { grid-area: area-5; justify-self: center }   /* Количество. */

/*--- Отступы строк слева/справа ---*/

.page__estimate--card [class^="group__list--"] [class^="list__row--"] {
	padding-right:6px;
	padding-left: 6px;
}

/*--- Заголовки колонок ---*/

.page__estimate--card .list__row--headers {
	border-bottom: solid 1px var(--gray-81);
	background: var(--back__head-section);
	padding-top: 2px;
	padding-bottom: 3px;
	color: var(--back__tab-hover);
	font-family: 'thin';
}

/*--- Cтроки оборудования ---*/

.page__estimate--card [class*="list__row--equip-"] {
	padding-top: 5px;
	padding-bottom: 6px;
	line-height: 16px;
}

/*--- Зебра ---*/

/* Строки «оборудования». */
.page__estimate--card .list__row--equip-1:nth-child(even) { background: var(--back-main); }
.page__estimate--card .list__row--equip-1:nth-child(odd)  { background: var(--gray-91);   }

/* Строки «материалов». */
.page__estimate--card .list__row--equip-2:nth-child(even) { background: var(--gray-85); }
.page__estimate--card .list__row--equip-2:nth-child(odd)  { background: var(--gray-81);   }
