/*===============================================/
  Типы Смет, добавление (.adding__estimate-type)
/===============================================*/

/*--- Размер окна контейнера ---*/

/*
	Основной размер окна определяем в переменных в контексте темы.
	Ширина окна --window-width должна учитывать:
	columns {сумма всех колонок} + margin-left 20px + поле справа 20px.
	Высота окна --window-height должна учитывать:
	общая высота строк вложенной формы + заголовок + отступ снизу.
*/
#modal__box .adding__estimate-type {
  --window-width: 780px;
  --window-height: 476px;
}

/* Фон окна. */
#modal__box .adding__estimate-type {
  background: var(--back);
}

/*--- Области формы ---*/

#modal__box .adding__estimate-type form {
  display: grid;
  grid-template-columns: 360px 20px 360px;
  grid-template-rows: repeat(3, auto) 46px;
  grid-template-areas:
    "child-1 . child-2"
    "child-3 . child-4"
    "child-5 . child-6"
    "child-7 . child-8";
  margin-left: 20px;
}

#modal__box .adding__estimate-type form > :nth-child(1) {
  grid-area: child-1;
} /* Механика. */
#modal__box .adding__estimate-type form > :nth-child(2) {
  grid-area: child-2;
} /* Электрические. */

#modal__box .adding__estimate-type form > :nth-child(3) {
  grid-area: child-3;
} /* Пожарные механические. */
#modal__box .adding__estimate-type form > :nth-child(4) {
  grid-area: child-4;
} /* Саботочка. */

#modal__box .adding__estimate-type form > :nth-child(5) {
  grid-area: child-5;
} /* Пожарные системы приводные. */
#modal__box .adding__estimate-type form > :nth-child(6) {
  grid-area: child-6;
} /* Другие строительные. */

#modal__box .adding__estimate-type form > :nth-child(7) {
  grid-area: child-7;
  align-self: end;
} /* Кнопка. */
#modal__box .adding__estimate-type form > :nth-child(8) {
  grid-area: child-8;
  align-self: end;
  justify-self: end;
} /* Кнопка. */

#modal__box .adding__estimate-type fieldset {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

#modal__box .adding__estimate-type legend {
  margin-bottom: 4px;
  width: 100%;
  background: var(--back__head-section);
  padding-top: 2px;
  padding-bottom: 3px;
  padding-left: 6px;
  color: var(--text__head-section);
}

#modal__box .adding__estimate-type fieldset label {
  margin-left: 5px;
}
