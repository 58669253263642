.invoicesCaptions {
  position: sticky;
  z-index: 1;
  top: 76px;
  margin: 12px 12px 6px;
  border-top: solid 1px var(--back);
  border-bottom: solid 1px var(--back);
  background: var(--back__tab-hover);
  font-family: 'thin';
  line-height: 16px;
  color: var(--back);
  display: grid;
  grid-template-columns: 12px 320px 110px 90px 24px auto 12px;
  grid-template-rows: 42px;
  grid-template-areas:
		". area-1 area-2 area-3 . area-4 .";
  align-items: center;

  & > *:nth-child(1) { grid-area: area-1; }   /* Счёт. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Дата. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Сумма. */
  & > *:nth-child(4) { grid-area: area-4; }   /* Основание. */

  & > *:nth-child(1),
  & > *:nth-child(2),
  & > *:nth-child(4) {
    justify-self: center;
  }

  & > *:nth-child(3) {
    justify-self: end;
  }
}

.invoice {
  margin: 0 12px;
  display: grid;
  grid-template-columns: 12px 320px 110px 90px 24px auto 12px;
  grid-template-rows: 42px;
  grid-template-areas:
		". area-1 area-2 area-3 . area-4 .";
  align-items: center;

  & > *:nth-child(1) { grid-area: area-1; }   /* Счёт. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Дата. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Сумма. */
  & > *:nth-child(4) { grid-area: area-4; }   /* Основание. */

  /* Дата. */
  & > *:nth-child(2) {
    justify-self: center;
  }

  /* Сумма. */
  & > *:nth-child(3) {
    justify-self: end;
  }

  /*----------------------/
    Ссылка в строке Счёта
  /----------------------*/

  & a {
    color: var(--link-normal);
    border-bottom: dotted 1px;
  }

  /* Наведение на строку / Выбранная строка. */
  &:hover a,
  &.selected a {
    color: var(--link-normal-bright);
  }

  /* Наведение на ссылку. */
  & a:hover {
    border-bottom-style: solid;
    color: var(--link-hover-light);
    cursor: pointer;
  }

  /* Нажатие на ссылку. */
  & a:active {
    color: var(--link-active-light);
  }

  &:hover {
    background: var(--back__row-hover);
    color: var(--text__row-hover);
  }

  &.selected {
    background: var(--back__row-highlight);
    color: var(--text__row-highlight);
  }
}

.notificationContainer {
  position: relative;
}
