.estimateSection {
  margin-bottom: 6px;
}

.estimateRow {
  margin-left: 12px;
  border-style: solid;
  border-width: 1px 0 1px 1px;
  border-color: var(--gray-85);
  background: var(--blue12_89);
  display: grid;
  grid-template-columns: 8px 28px auto 160px repeat(4, 140px) 12px;
  grid-template-rows: 32px;
  grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-6 area-7 .";
  align-items: center;

  & > *:nth-child(1) { grid-area: area-1; }   /* Переключатель. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Смета. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Дата. */
  & > *:nth-child(4) { grid-area: area-4; }   /* Оплата Платформе. */
  & > *:nth-child(5) { grid-area: area-5; }   /* Оплата Бригады. */
  & > *:nth-child(6) { grid-area: area-6; }   /* Акт Платформа. */
  & > *:nth-child(7) { grid-area: area-7; }   /* Акт Бригада. */

  /* Переключатель, Смета. */
  & > *:nth-child(1),
  & > *:nth-child(2) {
    justify-self: start;
  }

  /* Переключатель: выравнивание. */
  & > *:nth-child(1) > span {
    top: 2px;
  }

  /* Смета: Название. */
  & > *:nth-child(2) > a {
    border-bottom: dotted 1px;
  }

  /* Дата. */
  & > *:nth-child(3) {
    justify-self: center;
  }

  /* Колонки с числами. */
  & > *:nth-child(n+4) {
    justify-self: end;
  }
}