/*==============================/
  Страница входа (.page__login)
/==============================*/

/*-----------------------------/
  Компоновка содержания (main)
/-----------------------------*/

.page__login > main {
  display: grid;
  grid-template-columns: auto 404px;
  grid-template-rows: 52px 78px auto auto;
  grid-template-areas:
    "child-1 child-4"
    "child-2 child-4"
    "child-3 child-4"
    "child-5 child-5";
}

.page__login > main > :nth-child(1) {
  grid-area: child-1;
} /* Заголовок. */
.page__login > main > :nth-child(2) {
  grid-area: child-2;
} /* Переключатели режимов. */
.page__login > main > :nth-child(3) {
  grid-area: child-3;
} /* Поля и кнопки. */
.page__login > main > :nth-child(4) {
  grid-area: child-4;
  align-self: center;
} /* Картинка. */
.page__login > main > :nth-child(5) {
  grid-area: child-5;
} /* Информация. */

/*----------------------/
  Переключатели режимов
/----------------------*/

.page__login [name="account__modes--switches"] {
  display: grid;
  grid-template-columns: 172px auto;
  grid-template-rows: 32px 32px;
  grid-template-areas:
    "child-1 child-2"
    "child-1 child-3";
  align-items: center;
}

.page__login [name="account__modes--switches"] > :nth-child(1) {
  grid-area: child-1;
} /* Заголовок. */
.page__login [name="account__modes--switches"] > :nth-child(2) {
  grid-area: child-2;
  align-self: end;
} /* Пароль. */
.page__login [name="account__modes--switches"] > :nth-child(3) {
  grid-area: child-3;
  align-self: start;
} /* СМС. */

/*----------------------/
  Форма входа по паролю
/----------------------*/

.page__login [name="account__mode--pass"] {
  display: grid;
  grid-template-columns: 172px auto;
  grid-template-rows: repeat(3, 38px) 52px;
  grid-template-areas:
    "child-1 child-2"
    "child-3 child-4"
    "child-5 child-6"
    ".       child-7";
  align-items: center;
}

.page__login [name="account__mode--pass"] > :nth-child(1) {
  grid-area: child-1;
} /* «Логин». */
.page__login [name="account__mode--pass"] > :nth-child(2) {
  grid-area: child-2;
} /* Логин. */
.page__login [name="account__mode--pass"] > :nth-child(3) {
  grid-area: child-3;
} /* «Пароль». */
.page__login [name="account__mode--pass"] > :nth-child(4) {
  grid-area: child-4;
} /* Пароль. */
.page__login [name="account__mode--pass"] > :nth-child(5) {
  grid-area: child-5;
} /* «Авторизация». */
.page__login [name="account__mode--pass"] > :nth-child(6) {
  grid-area: child-6;
} /* Чекбокс. */
.page__login [name="account__mode--pass"] > :nth-child(7) {
  grid-area: child-7;
  align-self: end;
} /* Кнопка. */

/*-------------------/
  Форма входа по СМС
/-------------------*/

.page__login [name="account__mode--sms"] {
  display: grid;
  grid-template-columns: 172px auto;
  grid-template-rows: repeat(4, 38px) 52px;
  grid-template-areas:
    "child-1 child-2"
    "child-3 child-4"
    "child-5 child-6"
    "child-7 child-8"
    ".       child-9";
  align-items: center;
}

.page__login [name="account__mode--sms"] > :nth-child(1) {
  grid-area: child-1;
} /* «Логин». */
.page__login [name="account__mode--sms"] > :nth-child(2) {
  grid-area: child-2;
} /* Логин. */
.page__login [name="account__mode--sms"] > :nth-child(3) {
  grid-area: child-3;
} /* «Код входа». */
.page__login [name="account__mode--sms"] > :nth-child(4) {
  grid-area: child-4;
} /* Кнопка (СМС). */
.page__login [name="account__mode--sms"] > :nth-child(5) {
  grid-area: child-5;
} /* «Код из СМС». */
.page__login [name="account__mode--sms"] > :nth-child(6) {
  grid-area: child-6;
} /* Поле кода. */
.page__login [name="account__mode--sms"] > :nth-child(7) {
  grid-area: child-7;
} /* «Авторизация». */
.page__login [name="account__mode--sms"] > :nth-child(8) {
  grid-area: child-8;
} /* Чекбокс. */
.page__login [name="account__mode--sms"] > :nth-child(9) {
  grid-area: child-9;
  align-self: end;
} /* Кнопка. */

/*-----------------------------/
  Область основного содержания
/-----------------------------*/

.page__login > main {
  margin-bottom: auto;
  background: var(--back-main);
  padding: 12px 22px 22px;
  color: var(--text);
}

/*--- Заголовок основного содержания ---*/

.page__login > main > h2 {
  font-size: 22px;
}

/*--- Заголовок в форме (подпись к полю) ---*/

.page__login form h3 {
  font-size: 15px;
  line-height: 15px;
}

/*--- Ширина кнопки ---*/

.page__login form button {
  width: 172px;
}

/*--- Картинка в правой области ---*/

.page__login .pic__back {
  mix-blend-mode: multiply;
  text-align: end;
}

.page__login .pic__back img {
  width: 88%;
}

/*--- Заголовок переключателей ---*/

.page__login [name="account__modes--switches"] > :nth-child(1) {
  width: 78px; /* Ширина для вывода в две строки. */
}

/*--- Радио-кнопка «Пароль» ---*/

.page__login [name="account__modes--switches"] > :nth-child(2) {
  margin-bottom: 2px;
}

/*--- Информационный бокс в нижней области ---*/

.page__login .info__box {
  margin-top: 26px;
  border-left: solid 4px var(--text-head);
  padding: 16px 16px 0;
  background: hsl(var(--blue), 28%, 91%);
}

/* Заголовок раздела в блоке информации. */
.page__login .info__box h3 {
  font-size: 17px;
  line-height: 17px;
}

/* Абзац в блоке информации. */
.page__login .info__box p {
  margin-top: 8px;
  margin-bottom: 18px;
}
