.header {
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 12px auto 12px;
  grid-template-rows: 42px auto;
  grid-template-areas:
		". area-1 ."
		". area-2 .";

  & > *:nth-child(1) { grid-area: area-1; }   /* Заголовки. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Суммы. */

  /* Панель заголовков. */
  & > *:nth-child(1) {
    margin-bottom: 6px;
    border-bottom: solid 1px var(--back-nav);
  }
}

.headerCaptions {
  display: grid;
  grid-template-columns: 460px auto 460px;
  /* grid-template-rows: auto; */
  grid-template-areas:
		"area-1 . area-2";
  align-items: center;
  justify-items: center;

  & > *:nth-child(1) { grid-area: area-1; }   /* Аванс. */
  & > *:nth-child(2) { grid-area: area-2; }   /* План. */

  /* Заголовки. */
  & > div {
    font-family: 'thin';
    font-size: 16px;
  }
}

.headerTotal {
  display: grid;
  grid-template-columns: 460px auto 460px;
  /* grid-template-rows: auto; */
  grid-template-areas:
		"area-1 . area-2";
  align-items: center;
  justify-items: center;

  & > *:nth-child(1) { grid-area: area-1; }   /* Аванс. */
  & > *:nth-child(2) { grid-area: area-2; }   /* План. */

  /* Числа. */
  & > div {
    font-size: 16px;
  }
}