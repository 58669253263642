/*==============================================/
  Страница Карточки наряда (.page__order--card)
/==============================================*/

/*----------------------/
  Область данных Наряда
/----------------------*/

.page__order--card .order__parent-data {
  display: grid;
  grid-template-columns: repeat(3, 348px) auto;
  grid-column-gap: 8px;
  grid-template-rows: 24px 22px;
  grid-template-areas:
    "child-1 child-3 child-5 child-7"
    "child-2 child-4 child-6 child-7";
  /* align-items: center; */
}

.page__order--card .order__parent-data > :nth-child(1) {
  grid-area: child-1;
  align-self: end;
} /* Объект. */
.page__order--card .order__parent-data > :nth-child(2) {
  grid-area: child-2;
  align-self: start;
} /* Адрес. */
.page__order--card .order__parent-data > :nth-child(3) {
  grid-area: child-3;
  align-self: end;
} /* Смета. */
.page__order--card .order__parent-data > :nth-child(4) {
  grid-area: child-4;
  align-self: start;
} /* Статус. */
.page__order--card .order__parent-data > :nth-child(5) {
  grid-area: child-5;
  align-self: end;
} /* Подрядчик: Организация. */
.page__order--card .order__parent-data > :nth-child(6) {
  grid-area: child-6;
  align-self: start;
} /* Подрядчик: Данные. */
.page__order--card .order__parent-data > :nth-child(7) {
  grid-area: child-7;
} /* Даты. */

/*--- Статус ---*/

.page__order--card .order__parent-data > :nth-child(4) {
  display: grid;
  grid-template-columns: 52px auto;
  grid-template-areas: "child-1 child-2";
  align-items: start;
}

.page__order--card .order__parent-data > :nth-child(4) > :nth-child(1) {
  grid-area: child-1;
} /* «Статус». */
.page__order--card .order__parent-data > :nth-child(4) > :nth-child(2) {
  grid-area: child-2;
} /* Статус. */

/*--- Подрядчик: Данные ---*/

.page__order--card .order__parent-data > :nth-child(6) {
  display: grid;
  grid-template-columns: 120px auto;
  grid-template-areas: "child-1 child-2";
  align-items: start;
}

.page__order--card .order__parent-data > :nth-child(6) > :nth-child(1) {
  grid-area: child-1;
} /* Телефон. */
.page__order--card .order__parent-data > :nth-child(6) > :nth-child(2) {
  grid-area: child-2;
} /* Имя Подрядчика. */

/*--- Даты. ---*/

.page__order--card .order__parent-data > :nth-child(7) {
  display: grid;
  grid-template-columns: 70px auto 70px;
  grid-template-areas:
    "child-1 child-1 child-1"
    "child-2 child-3 child-4";
}

.page__order--card .order__parent-data > :nth-child(7) > :nth-child(1) {
  grid-area: child-1;
  align-self: end;
  justify-self: center;
} /* Надпись. */
.page__order--card .order__parent-data > :nth-child(7) > :nth-child(2) {
  grid-area: child-2;
  align-self: start;
  justify-self: end;
} /* Дата. */
.page__order--card .order__parent-data > :nth-child(7) > :nth-child(3) {
  grid-area: child-3;
  align-self: start;
  justify-self: center;
} /* Тире. */
.page__order--card .order__parent-data > :nth-child(7) > :nth-child(4) {
  grid-area: child-4;
  align-self: start;
  justify-self: start;
} /* Дата. */

/*-----------------/
  Заголовок Наряда
/-----------------*/

.page__order--card .order__heading {
  display: grid;
  grid-template-columns: 330px 150px 140px 110px 150px auto 60px 62px;
  column-gap: 8px;
  /* grid-template-rows: auto; */
  grid-template-areas: "child-1 child-2 child-3 child-4 child-5 child-6 child-7 child-8";
  align-items: center;
  justify-items: center;
}

.page__order--card .order__heading > :nth-child(1) {
  grid-area: child-1;
  justify-self: start;
} /* Название Наряда. */
.page__order--card .order__heading > :nth-child(2) {
  grid-area: child-2;
} /* Даты. */
.page__order--card .order__heading > :nth-child(3) {
  grid-area: child-3;
} /* Трудоёмкость. */
.page__order--card .order__heading > :nth-child(4) {
  grid-area: child-4;
} /* Стоимость. */
.page__order--card .order__heading > :nth-child(5) {
  grid-area: child-5;
} /* Статус. */
.page__order--card .order__heading > :nth-child(6) {
  grid-area: child-6;
} /* Бригада. */
.page__order--card .order__heading > :nth-child(7) {
  grid-area: child-7;
} /* Споры. */
.page__order--card .order__heading > :nth-child(8) {
  grid-area: child-8;
  justify-self: start;
} /* Кнопка начала спора. */

/*--- Вложенный grid: Трудоёмкость ---*/

.page__order--card .order__heading > :nth-child(3) {
  display: grid;
  grid-template-columns: 42px 8px 46px; /* <=96px */
  /* grid-template-rows: auto; */
  grid-template-areas:
    "child-1 child-1 child-1"
    "child-2 .       child-3";
}

.page__order--card .order__heading > :nth-child(3) > :nth-child(1) {
  grid-area: child-1;
  align-self: end;
  justify-self: center;
} /* «Трудоёмкость». */
.page__order--card .order__heading > :nth-child(3) > :nth-child(2) {
  grid-area: child-2;
  align-self: start;
  justify-self: end;
} /* Число. */
.page__order--card .order__heading > :nth-child(3) > :nth-child(3) {
  grid-area: child-3;
  align-self: start;
  justify-self: start;
} /* «дн.» */

/*--- Вложенный grid: Споры ---*/

.page__order--card .order__heading > :nth-child(7) {
  display: grid;
  grid-template-columns: 28px 12px 28px; /* <=68px */
  /* grid-template-rows: auto; */
  grid-template-areas:
    "child-1 child-1 child-1"
    "child-2 child-3 child-4";
}

.page__order--card .order__heading > :nth-child(7) > :nth-child(1) {
  grid-area: child-1;
  align-self: end;
  justify-self: center;
} /* «Споры». */
.page__order--card .order__heading > :nth-child(7) > :nth-child(2) {
  grid-area: child-2;
  align-self: start;
  justify-self: end;
} /* Число. */
.page__order--card .order__heading > :nth-child(7) > :nth-child(3) {
  grid-area: child-3;
  align-self: start;
  justify-self: center;
} /* «/». */
.page__order--card .order__heading > :nth-child(7) > :nth-child(4) {
  grid-area: child-4;
  align-self: start;
  justify-self: start;
} /* Число. */

/*--------------------------------/
  Бокс областей содержания Наряда
/--------------------------------*/

.page__order--card .order__box--content {
  display: grid;
  grid-template-columns: 616px 16px 608px; /* =1240px */
  /* grid-template-rows: auto; */
  grid-template-areas:
    "child-1 . child-2"
    "child-1 . child-3"
    "child-1 . child-4"
    "child-1 . child-5"
    "child-1 . child-6"
    "child-1 . child-7"
    "child-1 . child-8"
    "child-1 . child-9"
    "child-1 . .      ";
}

.page__order--card .order__box--content > :nth-child(1) {
  grid-area: child-1;
} /* Бокс Превью. */
.page__order--card .order__box--content > :nth-child(2) {
  grid-area: child-2;
} /* Секция «Наряд». */
.page__order--card .order__box--content > :nth-child(3) {
  grid-area: child-3;
} /* Секция «Чертежи…» */
.page__order--card .order__box--content > :nth-child(4) {
  grid-area: child-4;
} /* Секция «Спецификация». */
.page__order--card .order__box--content > :nth-child(5) {
  grid-area: child-5;
} /* Секция «График…» */
.page__order--card .order__box--content > :nth-child(6) {
  grid-area: child-6;
} /* Секция «ТЗ». */
.page__order--card .order__box--content > :nth-child(7) {
  grid-area: child-7;
} /* Секция «Договор» */
.page__order--card .order__box--content > :nth-child(8) {
  grid-area: child-8;
} /* Секция «Акт…» */
.page__order--card .order__box--content > :nth-child(9) {
  grid-area: child-9;
} /* Раздел «Работы…» */

/*--- Секция: Заголовок ---*/

.page__order--card [class*="files__section--"] > .section__header {
  display: grid;
  grid-template-columns: 28px auto 56px 20px;
  /* grid-template-rows: auto; */
  grid-template-areas: "child-1 child-2 child-3 child-4";
  align-items: center;
}

.page__order--card
  [class*="files__section--"]
  > .section__header
  > :nth-child(1) {
  grid-area: child-1;
} /* Переключатель. */
.page__order--card
  [class*="files__section--"]
  > .section__header
  > :nth-child(2) {
  grid-area: child-2;
} /* Название Секции. */
.page__order--card
  [class*="files__section--"]
  > .section__header
  > :nth-child(3) {
  grid-area: child-3;
} /* «Файлов:…» */
.page__order--card
  [class*="files__section--"]
  > .section__header
  > :nth-child(4) {
  grid-area: child-4;
  justify-self: end;
} /* Количество. */

/*--- Секция: строка Файла ---*/

.page__order--card [class*="section__files--"] > div {
  display: grid;
  grid-template-columns: 16px auto 320px;
  /* grid-template-rows: auto; */
  grid-template-areas: "child-1 child-2 child-3";
  align-items: center;
}

.page__order--card [class*="section__files--"] > div > :nth-child(1) {
  grid-area: child-1;
  justify-self: start;
} /* Маркер. */
.page__order--card [class*="section__files--"] > div > :nth-child(2) {
  grid-area: child-2;
} /* Ссылка. */
.page__order--card [class*="section__files--"] > div > :nth-child(3) {
  grid-area: child-3;
} /* Комментарий. */

/*--- Секция «Наряд»: Загрузка файла ---*/

.page__order--card .section__files--link form {
  display: grid;
  grid-template-columns: 270px auto 314px;
  grid-template-rows: 32px 22px;
  grid-template-areas:
    "child-1 . child-3"
    "child-2 . child-4";
}

.page__order--card .section__files--link form > :nth-child(1) {
  grid-area: child-1;
  align-self: end;
} /* Выбор файла. */
.page__order--card .section__files--link form > :nth-child(2) {
  grid-area: child-2;
  align-self: center;
} /* Кнопка «Загрузить». */
.page__order--card .section__files--link form > :nth-child(3) {
  grid-area: child-3;
  align-self: end;
} /* Комментарий. */
.page__order--card .section__files--link form > :nth-child(4) {
  grid-area: child-4;
  align-self: center;
} /* «Комментарий». */

/*--- Раздел «Работы…»: Строка ---*/

.page__order--card .jobprices__partition .jobprice__line {
  display: grid;
  grid-template-columns: 28px auto 60px 60px 60px 68px 46px;
  /* grid-template-rows: auto; */
  grid-template-areas: "child-1 child-2 child-3 child-4 child-5 child-6 child-7";
  align-items: center;
}

.page__order--card .jobprice__line > :nth-child(1) {
  grid-area: child-1;
} /* Переключатель. */
.page__order--card .jobprice__line > :nth-child(2) {
  grid-area: child-2;
} /* Название. */
.page__order--card .jobprice__line > :nth-child(3) {
  grid-area: child-3;
  justify-self: end;
} /* Количество. */
.page__order--card .jobprice__line > :nth-child(4) {
  grid-area: child-4;
} /* Единица измерения. */
.page__order--card .jobprice__line > :nth-child(5) {
  grid-area: child-5;
  justify-self: end;
} /* Цена. */
.page__order--card .jobprice__line > :nth-child(6) {
  grid-area: child-6;
  justify-self: end;
} /* Стоимость. */
.page__order--card .jobprice__line > :nth-child(7) {
  grid-area: child-7;
  justify-self: end;
} /* Трудоёмкость. */

/*--- Раздел «Работы…»: Строка позиции (состав Работы) ---*/

.page__order--card .jobprice__structure .jobprice__position {
  display: grid;
  grid-template-columns: 16px auto 60px 60px;
  /* grid-template-rows: auto; */
  grid-template-areas: "child-1 child-2 child-3 child-4";
  align-items: center;
}

.page__order--card .jobprice__position > :nth-child(1) {
  grid-area: child-1;
  justify-self: start;
} /* Маркер. */
.page__order--card .jobprice__position > :nth-child(2) {
  grid-area: child-2;
} /* Название. */
.page__order--card .jobprice__position > :nth-child(3) {
  grid-area: child-3;
  justify-self: end;
} /* Количество. */
.page__order--card .jobprice__position > :nth-child(4) {
  grid-area: child-4;
} /* Единица измерения. */

/*------------------------------------/
  Области основного содержания (main)
/------------------------------------*/

/*--- Непрокручиваемые области ---*/

/* Родительские данные Наряда и Заголовок Наряда. */
.page__order--card .order__parent-data,
.page__order--card .order__heading {
  position: sticky;
  z-index: 1; /* Перекрываем прокручиваемое содержание. */
  width: 1240px; /* Ширина контента. */
  border-top: solid 1px var(--back);
  border-bottom: solid 1px var(--back);
  color: var(--back);
}

/*---------------------------/
  Родительские данные Наряда
/---------------------------*/

/* Бокс. */
.page__order--card .order__parent-data {
  top: 76px;
  margin-top: -2px;
  margin-bottom: 16px;
  background: var(--back__tab-hover);
  padding-left: 6px;
}

/*--- Черта под ссылкой ---*/

.page__order--card .order__parent-data .link-light {
  border-bottom: dotted 1px;
}

/* Иначе черта не видна (overflow: hidden). */
.page__order--card .order__parent-data > :nth-child(1),
.page__order--card .order__parent-data > :nth-child(3),
.page__order--card .order__parent-data > :nth-child(5) {
  padding-bottom: 1px;
}

/*--- Шрифты ---*/

.page__order--card .order__parent-data > :nth-child(2),
.page__order--card .order__parent-data > :nth-child(4) > :nth-child(1),
.page__order--card .order__parent-data > :nth-child(6),
.page__order--card .order__parent-data > :nth-child(7) > :nth-child(1) {
  font-family: "thin";
}

/* Имя статуса. */
.page__order--card .order__parent-data > :nth-child(4) > :nth-child(2) {
  font-family: "bold";
}

.page__order--card .order__parent-data > :nth-child(2),
.page__order--card .order__parent-data > :nth-child(4),
.page__order--card .order__parent-data > :nth-child(6) {
  font-size: 12px;
}

/*-----------------/
  Заголовок Наряда
/-----------------*/

/*--- Панель заголовка ---*/

.page__order--card .order__heading {
  top: 125px; /* = «Данные Наряда»: 76 (top) + высота строк grid. */
  min-height: 44px;
  border-bottom: solid 2px var(--back);
  background: var(--text-head);
}

/*--- Название Наряда ---*/

.page__order--card .order__heading > :nth-child(1) {
  padding: 2px 6px 3px;
  line-height: 16px;
}

/*--- Даты ---*/

.page__order--card .order__heading > :nth-child(2) > :nth-child(2) {
  padding-right: 4px;
  padding-left: 4px;
}

/*--- Трудоёмкость ---*/

/* «Трудоёмкость». */
.page__order--card .order__heading > :nth-child(3) > :nth-child(1) {
  margin-bottom: -2px;
  font-family: "thin";
  font-size: 12px;
}

/*--- Стоимость ---*/

/* Блок. */
.page__order--card .order__heading > :nth-child(4) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Оплата. */
.page__order--card .order__heading > :nth-child(4) > :nth-child(2) {
  font-family: "thin";
  font-size: 12px;
}

/*--- Статус ---*/

.page__order--card .order__heading > :nth-child(5) span {
  display: inline-block; /* Для выравнивания. */
  border: solid 1px var(--back);
  padding: 2px 5px 3px;
}

/*--- Бригада ---*/

/* Ссылка. */
.page__order--card .order__heading > :nth-child(6) a {
  display: inline-block; /* Для выравнивания. */
  margin-bottom: 4px;
  border-bottom: dotted 1px;
}

/*--- Споры ---*/

/* Ссылка. */
.page__order--card .order__heading > :nth-child(7) a {
  display: inline-block; /* Для выравнивания. */
  /* margin-bottom: -2px; */
  border-bottom: dotted 1px;
  font-family: "thin";
  font-size: 12px;
}

/*--- Кнопка начала спора ---*/

.page__order--card .order__heading > :nth-child(8) {
  width: 56px;
  height: 32px;
  border: solid 1px var(--gray-77);
  border-radius: 8px;
  font-size: 12px;
  line-height: 12px;
}

/*--------------------------------/
  Бокс областей содержания Наряда
/--------------------------------*/

/* Бокс всего содержания Наряда. */
.page__order--card .order__box--content {
  margin-top: 8px; /* Расстояние после заголовка. */
  margin-bottom: 8px; /* Расстояние над footer. */
}

/*--- Превью ---*/

/* Бокс. */
.page__order--card .order__preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Наряд: Строка выбора миниатюры. */
.page__order--card .preview__select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  width: 616px; /* = ширине области grid. */
  height: 46px;
  margin-bottom: 8px; /* Расстояние до превью. */
}

/* Стрелки выбора миниатюры. */
.page__order--card .preview__select span {
  display: flex; /* Для выравнивания. */
  align-items: center;
  height: 100%;
  color: var(--button-back-normal);
  font-family: "bold";
  font-size: 24px;
  cursor: pointer;
}

/* Границы стрелок. */
.page__order--card .preview__select span:first-of-type {
  border-right: solid 1px;
}
.page__order--card .preview__select span:last-of-type {
  border-left: solid 1px;
}

.page__order--card .preview__select span:hover {
  color: var(--button-back-hover);
}
.page__order--card .preview__select span:active {
  color: var(--button-back-active);
}

/* Стрелка слева/справа. */
.page__order--card .preview__select span:first-of-type::before {
  content: "\25c4";
}
.page__order--card .preview__select span:last-of-type::before {
  content: "\25ba";
}

/* Картинка миниатюры (выбор). */
.page__order--card .preview__select img {
  width: auto;
  max-height: 46px; /* = высоте «preview__select». */
  border: solid 2px var(--gray-67);
  cursor: pointer;
}

/* Картинка миниатюры: выбранная. */
.page__order--card .preview__select img.selected {
  border-color: var(--button-back-normal);
}

/* Картинка миниатюры: наведение. */
.page__order--card .preview__select img:hover {
  border-color: var(--button-back-hover);
}

/* Область под Превью. */
.page__order--card .order__preview .preview__full {
  max-width: 616px; /* Не более ширины области grid. */
  cursor: zoom-in;
}

/* Картинка в Превью. */
.page__order--card .order__preview .preview__full img {
  width: 100%; /* Область заполняется на ширину картинки. */
}

/*--- Секции с файлами ---*/

/* Заголовок Секции. */
.page__order--card [class*="files__section--"] > .section__header {
  margin-bottom: 6px;
  border: solid 1px var(--gray-67);
  background: var(--back__head-section);
  padding: 3px 6px;
  color: var(--back__tab-hover);
}

.page__order--card [class*="files__section--"].open > .section__header {
  margin-bottom: 0;
  border-bottom: none;
}

/* Название Секции. */
.page__order--card
  [class*="files__section--"]
  > .section__header
  > :nth-child(2) {
  font-size: 16px;
}

/* Надпись «Файлов:» и количество. */
.page__order--card
  [class*="files__section--"]
  > .section__header
  > :nth-child(3),
.page__order--card
  [class*="files__section--"]
  > .section__header
  > :nth-child(4) {
  font-size: 14px;
}

/* Надпись «Файлов:» */
.page__order--card
  [class*="files__section--"]
  > .section__header
  > :nth-child(3) {
  font-family: "thin";
}

/* Блок файлов: Скрыт по умолчанию. */
.page__order--card .section__files--link {
  display: none;
}

/* Блок файлов: Выводится, если Секция раскрыта. */
.page__order--card .open .section__files--link {
  display: block;
  margin-bottom: 12px;
  border: solid 1px var(--gray-67);
  background: var(--back-main);
  padding-top: 6px;
  padding-bottom: 4px;
}

/* Строка файла. */
.page__order--card .section__files--link > div {
  margin-left: 24px;
  margin-bottom: 4px;
}

/* Ссылка на файл. */
.page__order--card .section__files--link > div > :nth-child(2) {
  line-height: 16px;
}
.page__order--card .section__files--link > div a {
  border-bottom: dotted 1px;
}

/* Комментарий. */
.page__order--card .section__files--link > div > :nth-child(3) {
  font-size: 12px;
  line-height: 14px;
}

/*--- Загрузка файла. ---*/

.page__order--card .section__files--link form {
  margin: 6px;
  border-top: solid 1px var(--gray-67);
  font-size: 12px;
}

/* Поле комментария. */
.page__order--card form [type="text"] {
  width: auto;
  height: 18px;
}

/* Кнопка «Загрузить». */
.page__order--card .section__files--link button.bttn__small {
  width: 110px;
}

/*--- Раздел «Работы…» ---*/

/* Весь раздел. */
.page__order--card .jobprices__partition {
  margin-top: 16px;
  background: var(--back-main);
  padding-bottom: 2px;
  border: solid 1px var(--gray-67);
  border-top: none;
}

/* Заголовок раздела. */
.page__order--card .partition__header {
  margin-right: -1px;
  margin-left: -1px;
  background: var(--back__tab-hover);
  padding-top: 3px;
  padding-bottom: 4px;
  text-align: center;
  color: var(--back);
  font-size: 16px;
}

/* Строка Работы. */
.page__order--card .jobprice__line {
  margin: 0 6px;
  border-top: solid 1px var(--gray-67);
  padding-top: 2px;
  padding-bottom: 2px;
  font-family: "thin";
  font-size: 13px;
}

/* Строка первой Работы: нет границы. */
.page__order--card .jobprices__partition > :nth-child(2) {
  border-top: none;
}

/* Переключатель (выравниваем). */
.page__order--card .jobprice__line > :nth-child(1) {
  height: 32px;
}
.page__order--card .jobprice__line > :nth-child(1) span {
  margin-top: 6px;
}

/* Название. */
.page__order--card .jobprice__line > :nth-child(2) {
  font-family: "regular";
  line-height: 16px;
}

/* Количество. */
.page__order--card .jobprice__line > :nth-child(3) {
  padding-right: 6px;
}

/* Блок состава (позиции): Скрыт по умолчанию. */
.page__order--card .jobprice__structure {
  display: none;
}

/* Блок состава (позиции): Выводится, если Работа раскрыта. */
.page__order--card .jobprice__line.open + .jobprice__structure {
  display: block;
  margin-bottom: 12px;
  font-size: 12px;
}

/*--- Строка позиции (состав Работы) ---*/

/* Сдвигаем строку. */
.page__order--card .jobprice__position {
  margin-left: 24px;
}

/* Название. */
.page__order--card .jobprice__position > :nth-child(2) {
  line-height: 14px;
}

/* Количество. */
.page__order--card .jobprice__position > :nth-child(3) {
  padding-right: 6px;
}

/* Трудоёмкость. */
.page__order--card .jobprice__line > :nth-child(7) {
  display: flex;
  flex-direction: row;
}

/* Буквы «ч/д» и «дн.» */
.page__order--card .jobprice__line > :nth-child(7) > :nth-child(2) {
  padding-left: 4px;
}
