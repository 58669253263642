.chainBox {
  margin-bottom: 8px;
  background: var(--back-main);
  padding: 3px 1px;
  border: solid 1px var(--gray-67);

  button {
    width: 86px;
  }

  &Orders {
    margin-top: 4px;
  }
}

.chainRow {
  display: grid;
  grid-template-columns: 24px auto 90px 90px 612px;
  column-gap: 8px;
  grid-template-rows: auto;
  grid-template-areas:
		"child-1 child-2 child-3 child-4 child-5";
  align-items: center;
  padding-top: 1px;     /* Влияет на выравнивание Переключателя. */
  padding-bottom: 1px;  /* Влияет на расстояние до строки Наряда. */

  &.error {
    background: var(--back-order-overdue);
  }

  &.error &Status {
    color: var(--error-dark);
  }

  &.error:hover &Status, &.error:global(.highlighted) &Status {
    color: var(--error-light);
  }

  &.warning {
    background: var(--back-order-launch);
  }

  &.warning &Status {
    color: var(--orange-site);
  }

  &.warning:hover &Status, &.warning:global(.highlighted) &Status {
    color: var(--error-light);
  }

  & > *:nth-child(1) { grid-area: child-1; justify-self: end;    }   /* Переключатель. */
  & > *:nth-child(2) { grid-area: child-2; }                         /* Название. */
  & > *:nth-child(3) { grid-area: child-3; justify-self: center; }   /* Статус. */
  & > *:nth-child(4) { grid-area: child-4; justify-self: end;    }   /* Сумма. */
  & > *:nth-child(5) { grid-area: child-5; }

  & > *:nth-child(1) {
    padding-top: 4px;   /* Влияет на высоту строки и выравнивание Переключателя. */
  }

  :global(.text__status--chain)::before {
    display: inline-block;
    font-family: 'thin';
    text-align: center;   /* Для текста в две строки. */
    line-height: 14px;    /* Для текста в две строки. */
  }

  & > *:nth-child(5) {
    width: 612px;
  }

  &Status {

  }

  &Btn {
    margin: 1px 0;
  }
}

.dateSelect {
  width: 92px;
  height: 18px;
  margin-bottom: 2px;
}

.graphBoxWrap {
  position: relative;
  height: 36px;
}

.graphBox {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  //width: max-content;
  height: 36px;
  padding-bottom: 14px;

  &Dates {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 0;
    font-family: 'thin';
    font-size: 12px;
    transform: translateX(-50%);
    white-space: nowrap;

    &.alignLeft {
      left: 0;
      transform: none;
    }

    &.alignRight {
      left: auto;
      right: 0;
      transform: none;
    }
  }

  &Line {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2px;
    height: 14px;
    background: var(--back-chain-graph);

    &.light {
      background: var(--back-order-graph);
    }
  }
}

.orderRow {
  display: grid;
  grid-template-columns: 24px auto 140px 76px 612px;
  column-gap: 8px;
  grid-template-rows: max-content;
  grid-template-areas:
		"child-1 child-2 child-3 child-4 child-5";
  align-items: center;
  margin-left: 14px;    /* Отодвигаем область под название Цепочки. */
  margin-bottom: 4px;   /* Расстояние между строками Нарядов. */
  border: solid 1px var(--back-order-graph);
  background: var(--back-order-line);

  &Status {
    text-align: center;
    line-height: 14px;
  }

  &.error {
    background: var(--back-order-overdue);
  }

  &.error &Status {
    color: var(--error-dark);
    font-family: 'regular';
    font-weight: 500;
  }

  &.error:hover &Status, &.error:global(.highlighted) &Status {
    color: var(--error-light);
    font-family: 'regular';
    font-weight: 500;
  }

  &.warning {
    background: var(--back-order-launch);
  }

  &.warning &Status {
    color: var(--orange-site);
    font-family: 'regular';
    font-weight: 500;
  }

  &.warning:hover &Status, &.warning:global(.highlighted) &Status {
    color: var(--error-light);
    font-family: 'regular';
    font-weight: 500;
  }

  & > *:nth-child(1) { grid-area: child-1; justify-self: end; }   /* Переключатель. */
  & > *:nth-child(2) { grid-area: child-2; }                      /* Название. */
  & > *:nth-child(3) { grid-area: child-3; }                      /* Дни/Бригада/Статус. */
  & > *:nth-child(4) { grid-area: child-4; justify-self: end; }   /* Сумма. */
  & > *:nth-child(5) { grid-area: child-5; }

  & > *:nth-child(1) > span {
    margin-top: 2px;
    margin-left: 6px;
  }

  & > *:nth-child(2) > a {
    border-bottom: dotted 1px;
  }

  &:hover > *:nth-child(2) > a,
  &:global(.highlighted) > *:nth-child(2) > a {
    color: var(--link-normal-light);
  }

  &:hover > *:nth-child(2) > a:hover,
  &:global(.highlighted) > *:nth-child(2) > a:hover {
    color: var(--link-hover-light);
  }

  & > *:nth-child(2),
  & > *:nth-child(4) {
    font-size: 13px;
  }

  & > *:nth-child(5) {
    width: 612px;
  }
}

.areaDayBrigade {
  display: grid;
  grid-template-columns: 50px 90px;   /* =140px */
  /* grid-template-rows: 0px 0px; */
  grid-template-areas:
		"child-1 child-2"
		"child-3 child-3";
  margin-top: 2px;
  margin-bottom: 2px;
  font-family: 'thin';
  font-size: 12px;

  & > *:nth-child(1) { grid-area: child-1; align-self: end;   justify-self: end;    }   /* Дни. */
  & > *:nth-child(2) { grid-area: child-2; align-self: end;   justify-self: center; }   /* Статус/Дни. */
  & > *:nth-child(3) { grid-area: child-3; align-self: start; justify-self: center; }   /* Бригада. */

  & > *:nth-child(3) {
    margin-top: -2px;
  }
}

.jobPrices {
  display: grid;
  margin-top: -2px;     /* Поднимаем оюласть Работ к строке Наряда-родителя. */
  margin-bottom: 8px;   /* Расстояние между областью Работ и следующим Нарядом. */
  margin-left: 34px;
  grid-template-columns: 580px auto 614px;
  grid-template-rows: max-content;
  grid-template-areas:
		"child-2 . child-1";
  align-items: center;
  margin-top: -2px;     /* Поднимаем оюласть Работ к строке Наряда-родителя. */
  margin-bottom: 8px;   /* Расстояние между областью Работ и следующим Нарядом. */
  margin-left: 34px;    /* Отодвигаем фон области Работ под название Наряда. */

  & > *:nth-child(1) { grid-area: child-1; }   /* Область поля замечаний. */
  & > *:nth-child(2) { grid-area: child-2; }   /* Область списка Работ. */
}

.jobPricesRow {
  display: grid;
  grid-template-columns: 8px auto 68px 50px 60px 70px;
  column-gap: 8px;
  grid-template-rows: max-content;
  grid-template-areas:
		"child-1 child-2 child-3 child-4 child-5 child-6";
  align-items: center;
  padding: 6px 0;
  font-size: 12px;

  & > *:nth-child(1) { grid-area: child-1; align-self: start; }   /* Маркер. */
  & > *:nth-child(2) { grid-area: child-2; }                      /* Работа. */
  & > *:nth-child(3) { grid-area: child-3; justify-self: end; }   /* Количество. */
  & > *:nth-child(4) { grid-area: child-4; }                      /* Единица. */
  & > *:nth-child(5) { grid-area: child-5; justify-self: end; }   /* Цена. */
  & > *:nth-child(6) { grid-area: child-6; justify-self: end; }   /* Стоимость. */

  & div {
    /* margin-bottom: 8px; */
    line-height: 14px;
  }
}

.ajustArea {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-right: 1px;
  padding-left: 8px;

  &Head {
    width: 100%;
    padding: 2px 0 2px;
    text-align: center;
    background: var(--text-head);
    color: var(--back);
    font-family: "thin";
    font-size: 12px;
  }

  &Field {
    width: 100%;
    height: 100%;
    resize: vertical;
    padding: 2px;
    background: var(--back);
    font-family: "thin";
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;

    &:focus {
      background: var(--gray-light);
      cursor: text;
    }

    &:read-only {
      cursor: not-allowed;

      &:focus {
        background: var(--gray-93);
        cursor: default;
      }
    }
  }
}