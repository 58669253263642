
.contractSection {
  margin: 4px 0;
}

.contractRow {
  margin-left: 28px;
  border-style: solid;
  border-width: 1px 0 1px 1px;
  border-color: var(--gray-85);
  background: var(--orange12_89);
  display: grid;
  grid-template-columns: 8px 28px auto 160px repeat(4, 140px) 12px;
  grid-template-rows: 38px;
  grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-6 area-7 .";
  align-items: center;

  & > *:nth-child(1) { grid-area: area-1; }   /* Переключатель. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Договор. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Дата. */
  & > *:nth-child(4) { grid-area: area-4; }   /* Оплата Платформе. */
  & > *:nth-child(5) { grid-area: area-5; }   /* Оплата Бригады. */
  & > *:nth-child(6) { grid-area: area-6; }   /* Акт Платформа. */
  & > *:nth-child(7) { grid-area: area-7; }   /* Акт Бригада. */

  /* Переключатель, Договор. */
  & > *:nth-child(1),
  & > *:nth-child(2) {
    justify-self: start;
  }

  /* Переключатель: выравнивание. */
  & > *:nth-child(1) > span {
    top: 2px;
  }

  /* Договор. */
  & > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  /* Договор: Название договора, ссылка. */
  & > *:nth-child(2) > a {
    border-bottom: dotted 1px;
  }

  /* Договор СМР: Наряд. */
  & > *:nth-child(2) > span {
    font-family: 'thin-italic';
    font-size: 12px;
  }

  /* Дата. */
  & > *:nth-child(3) {
    justify-self: center;
  }

  /* Колонки с числами. */
  & > *:nth-child(n+4) {
    justify-self: end;
  }
}

.billsList {
  margin-left: 44px;
  border-bottom: solid 1px var(--gray-85);
  border-left: solid 4px var(--gray-81);
  background: var(--green12_89);
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
}

.billsRow {
  display: grid;
  grid-template-columns: 12px 16px auto 160px repeat(4, 140px) 12px;
  grid-template-rows: 20px;
  grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-6 area-7 .";
  align-items: center;

  & > *:nth-child(1) { grid-area: area-1; }   /* Макер. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Счёт. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Дата. */
  & > *:nth-child(4) { grid-area: area-4; }   /* Оплата Платформе. */
  & > *:nth-child(5) { grid-area: area-5; }   /* Оплата Бригады. */
  & > *:nth-child(6) { grid-area: area-6; }   /* Акт Платформа. */
  & > *:nth-child(7) { grid-area: area-7; }   /* Акт Бригада. */

  /* Макер, Документ. */
  & > *:nth-child(1),
  & > *:nth-child(2) {
    justify-self: start;
  }

  /* Дата. */
  & > *:nth-child(3) {
    justify-self: center;
  }

  /* Колонки с числами. */
  & > *:nth-child(n+4) {
    justify-self: end;
  }
}