.statistics {
  display: grid;
  grid-template-columns: 12px 606px auto 596px 12px;
  grid-template-areas:
		". area-1 . area-2 .";

  & > *:nth-child(1) { grid-area: area-1; }
  & > *:nth-child(2) { grid-area: area-2; }

  &Left {
    margin-bottom: 12px;
    border-right: solid 1px var(--back-nav);
    padding-right: 12px;
  }

  &Right {
    display: grid;
    grid-template-rows: 40px 14px 56px;
    grid-template-areas:
        "area-1"
        "area-2"
        "area-3";
    justify-items: center;
    margin-bottom: 12px;
    border: solid 1px var(--back-nav);
    background: var(--gray-93);

    & > *:nth-child(1) { grid-area: area-1; }   /* «Рейтинг». */
    & > *:nth-child(2) { grid-area: area-2; }   /* Подзаголовок. */
    & > *:nth-child(3) { grid-area: area-3; }   /* Рейтинг. */

    & > *:nth-child(1) {
      align-self: end;
      font-family: 'bold';
      color: var(--text-head);
    }

    & > *:nth-child(2) {
      align-self: start;
      font-family: 'thin-italic';
      font-size: 12px;
    }

    & > *:nth-child(3) {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      border-style: solid;
      border-width: 1px;
      border-radius: 50%;
      padding: 7px 6px;
      font-size: 16px;
      min-width: 34px;

      /* Число: Рейтинг «высокий». */
      &.high {
        border-color: var(--green24_51);
        background: var(--green24_89);
        color: var(--green24_51);
      }

      /* Число: Рейтинг «средний». */
      &.medium {
        border-color: var(--blue24_51);
        background: var(--blue24_89);
        color: var(--blue24_51);
      }

      /* Число: Рейтинг «низкий». */
      &.low {
        border-color: var(--red24_51);
        background: var(--red24_89);
        color: var(--red24_51);
      }
    }
  }

  &Captions {
    display: grid;
    grid-template-columns: auto repeat(3, 120px);
    grid-template-rows: 32px;
    grid-template-areas:
		"area-1 area-2 area-3 area-4";
    align-items: center;
    border-bottom: solid 1px var(--back-nav);
    font-family: 'thin';

    & > *:nth-child(1) { grid-area: area-1; }   /* Объект БП. */
    & > *:nth-child(2) { grid-area: area-2; }   /* Всего. */
    & > *:nth-child(3) { grid-area: area-3; }   /* В работе. */
    & > *:nth-child(4) { grid-area: area-4; }   /* Завершено. */

    & > *:nth-child(1) {
      justify-self: start;
    }

    & > *:nth-child(n+2) {
      justify-self: center;
    }
  }
  
  &Row {
    display: grid;
    grid-template-columns: auto repeat(3, 120px);
    grid-template-rows: 32px;
    grid-template-areas:
		"area-1 area-2 area-3 area-4";
    align-items: center;

    & > *:nth-child(1) { grid-area: area-1; }   /* Объект БП. */
    & > *:nth-child(2) { grid-area: area-2; }   /* Всего. */
    & > *:nth-child(3) { grid-area: area-3; }   /* В работе. */
    & > *:nth-child(4) { grid-area: area-4; }   /* Завершено. */

    & > *:nth-child(1) {
      justify-self: start;
    }

    & > *:nth-child(n+2) {
      justify-self: center;
    }
  }
}