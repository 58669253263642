.container {
  display: grid;
  grid-template-columns: 240px 210px 240px;
  grid-template-areas: "area-1 area-2 area-3";
  width: 690px;
  height: 47px;
  margin-bottom: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  &__offer {
    background: var(--back-estimate-offer);
  }
  &__jobplan {
    background: var(--back-estimate-jobplan);
  }
  &__url {
    border-bottom: dotted 1px;
    padding-right: 0;
  }
}

.name {
  grid-area: "area-1";
  padding-left: 6px;
}

.sumContainer {
  grid-area: "area-3";
  display: flex;
  justify-content: space-around;
  padding-left: 6px;
}

.sum {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.estMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    text-align: center;
    font-style: italic;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.types {
  grid-area: "area-2";
  padding-left: 6px;
}

.borderLine:nth-child(n):not(:last-child) {
  border-right: solid 1px var(--gray-87);
}

.container:hover {
  background: var(--button-back-normal);
  color: var(--button-text-normal);
  text-shadow: var(--button-text-normal-shade);
  cursor: pointer;
  transition: .5s;
}

.estimateInfoRow {
  width: 120px; 
  display: flex;
  justify-content: center;
}

.estimateRow {
  margin-bottom: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  position: relative;

  &.alarm::before {
    top: 0;
    left: -12px;
    /*position: absolute;
    width: 12px;
    height: 18px;
    background: var(--red-site);
    text-align: center;
    font-family: "bold";
    font-size: 12px;
    color: var(--back);
    content: "!";*/
  }

  & > *:nth-child(n) {
    padding-left: 6px;
    padding-right: 6px;
  }

  & {
    display: grid;
    grid-template-columns: 240px 210px repeat(4, 80px) 208px;
    /* grid-template-rows: *px; */
    grid-template-areas:
		"area-1 area-4 area-7 area-8 area-9 area-10 area-11"
		"area-2 area-5 area-7 area-8 area-9 area-10 area-11"
		"area-3 area-6 area-7 area-8 area-9 area-10 area-11";
    align-items: center;
  }

  & > *:nth-child(1)  { grid-area: area-1;  }   /* Название. */
  & > *:nth-child(2)  { grid-area: area-2;  }   /* Примечание. */
  & > *:nth-child(3)  { grid-area: area-3;  }   /* Прораб. */

  & > *:nth-child(4)  { grid-area: area-4;  }   /* Тип. */
  & > *:nth-child(5)  { grid-area: area-5;  }   /* Статус. */
  & > *:nth-child(6)  { grid-area: area-6;  }   /* Даты. */

  & > *:nth-child(7)  { grid-area: area-7;  }   /* Сумма: Всего. */
  & > *:nth-child(8)  { grid-area: area-8;  }   /* Сумма: Остаток. */
  & > *:nth-child(9)  { grid-area: area-9;  }   /* Сумма: В работе. */
  & > *:nth-child(10) { grid-area: area-10; }   /* Сумма: Выполнено. */

  & > *:nth-child(11) { grid-area: area-11; }   /* Информация. */

  /*--- Суммы ---*/

  & > *:nth-child(n+7):not(:nth-child(11)) {
    display: grid;
    /* grid-template-columns: max-content min-content auto; */
    grid-template-areas:
		"area-1"
		"area-2";
    justify-items: center;
  }

  & > *:nth-child(n+7):not(:nth-child(11)) > *:nth-child(1) { grid-area: area-1; align-self: end; }   /* Надпись. */
  & > *:nth-child(n+7):not(:nth-child(11)) > *:nth-child(2) { grid-area: area-2; align-self: start; }   /* Сумма. */


  & > *:nth-child(n):not(:nth-child(11)) {
    border-right: solid 1px var(--gray-91);
  }

  /* Граница во всю высоту строки Сметы. */
  & > *:nth-child(n+7):not(:nth-child(11)) {
    height: 100%;
  }

  &Status {
    font-family: 'bold';
  }

  & > *:nth-child(1) a {
    border-bottom: dotted 1px;
    color: var(--link-hover);
  }

  &:hover > *:nth-child(1) a {
    color: var(--link-normal-bright);
    text-shadow: none;
  }

  & > *:nth-child(1) a:hover {
    border-bottom-style: solid;
    color: var(--link-hover-light);
  }

  & > *:nth-child(2) {
    font-family: 'thin';
  }
}

.shortEstimateRow {
  position: relative;
  &.alarm::before {
    top: 0;
    left: -12px;
  }
}

.alarm::before {
  top: 18px;
  left: -10px;
  position: absolute;
  width: 12px;
  height: 18px;
  background: var(--red-site);
  text-align: center;
  font-family: "bold";
  font-size: 12px;
  color: var(--back);
  content: "!";
}