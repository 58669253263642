.passEyeContainer {
    display: flex;
}
.passEyeImg {
    margin-left: 4px;
    cursor: pointer;
}
.passEyeDisableImg {
    margin-left: 4px;
    cursor: not-allowed;
}