.captionsBar {
  display: grid;
  grid-template-columns: 0 200px 200px auto 120px 150px 130px 70px 90px 0;
  grid-column-gap: 6px;
  grid-template-rows: 42px;
  grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-6 area-7 area-8 .";
  align-items: center;
  justify-items: center;

  position: sticky;
  z-index: 1;      /* Перекрываем прокручиваемое содержание. */
  top: 76px;

  width: 1240px;   /* Ширина контента. */
  margin-bottom: 6px;

  border-top: solid 1px var(--back);
  border-bottom: solid 1px var(--back);
  background: var(--back__tab-hover);
  text-align: center;
  font-family: 'thin';
  line-height: 16px;
  color: var(--back);

  & > *:nth-child(1) { grid-area: area-1; }   /* Объект/Адрес. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Смета. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Наряд. */
  & > *:nth-child(4) { grid-area: area-4; }   /* Сроки/Стоимость. */
  & > *:nth-child(5) { grid-area: area-5; }   /* Бригадир/Продолжительность. */
  & > *:nth-child(6) { grid-area: area-6; }   /* Статус/(управление). */
  & > *:nth-child(7) { grid-area: area-7; }   /* Споры/…. */
  & > *:nth-child(8) { grid-area: area-8; }   /* Ответчики/Время. */
}