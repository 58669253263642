.bar {
  display: grid;
  grid-template-columns: repeat(3, 341px) auto;
  grid-column-gap: 8px;
  grid-template-rows: 24px 22px;
  grid-template-areas:
		"child-1 child-3 child-5 child-7"
		"child-2 child-4 child-6 child-7";
  position: sticky;
  z-index: 1;
  width: 1240px;
  top: 76px;
  margin-bottom: 8px;
  padding-left: 6px;
  border-top: solid 1px var(--back);
  border-bottom: solid 1px var(--back);
  color: var(--back);
  background: var(--back__tab-hover);

  & > *:nth-child(1) { grid-area: child-1; align-self: end;   }
  & > *:nth-child(2) { grid-area: child-2; align-self: start; }
  & > *:nth-child(3) { grid-area: child-3; align-self: end;   }
  & > *:nth-child(4) { grid-area: child-4; align-self: start; }
  & > *:nth-child(5) { grid-area: child-5; align-self: end;   }
  & > *:nth-child(6) { grid-area: child-6; align-self: start; }
  & > *:nth-child(7) { grid-area: child-7; }

  & > *:nth-child(6) {
    display: grid;
    grid-template-columns: 120px auto;
    grid-template-areas:
		"child-1 child-2";
    align-items: start;
  }

  & > *:nth-child(6) > *:nth-child(1) { grid-area: child-1; }
  & > *:nth-child(6) > *:nth-child(2) { grid-area: child-2; }

  & > *:nth-child(7) {
    display: grid;
    grid-template-columns: 78px auto 78px;
    grid-template-areas:
		"child-1 child-1 child-1"
		"child-2 child-3 child-4";
  }

  & > *:nth-child(7) > *:nth-child(1) { grid-area: child-1; align-self: end;   justify-self: center; }
  & > *:nth-child(7) > *:nth-child(2) { grid-area: child-2; align-self: start; justify-self: end;    }
  & > *:nth-child(7) > *:nth-child(3) { grid-area: child-3; align-self: start; justify-self: center; }
  & > *:nth-child(7) > *:nth-child(4) { grid-area: child-4; align-self: start; justify-self: start;  }

  & :global(.link-light) {
    border-bottom: dotted 1px;
  }

  & > *:nth-child(1),
  & > *:nth-child(3),
  & > *:nth-child(5) {
    padding-bottom: 1px;
  }

  & > *:nth-child(2),
  & > *:nth-child(4),
  & > *:nth-child(6),
  & > *:nth-child(7) > *:nth-child(1) {
    font-family: 'thin';
  }

  & > *:nth-child(2),
  & > *:nth-child(4),
  & > *:nth-child(6) {
    font-size: 12px;
  }
}