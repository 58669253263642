.container {
  display: flex;
  flex-direction: column;
}

.footerStatic {
  display: flex;
  justify-content: space-between;
  font-family: 'thin';
}

.openFooter {
  display: flex;
  justify-content: space-between;
}

.openFooter {
  opacity: 0;
  height: 0;

  transition:
      opacity 0.3s 0.1s ease,
      height 0.4s 0.2s ease;

}

footer:hover .openFooter {
  opacity: 1;
  height: 48px;
  border-bottom: solid 1px var(--back);

  transition:
      opacity 0.4s 0.2s ease,
      height 0.5s 0.1s ease;
}