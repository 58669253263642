.objectPageHead {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 30px;
  grid-template-areas:
		"area-1 area-2";
  line-height: 18px;
  margin-top: 10px;
}

.objectPageHeadTitle {
  grid-area: area-1;
  font-size: 24px;
}

.objectPageHeadInfoText {
  grid-area: area-2;
  justify-self: end;
  font-family: 'thin-italic';
  font-size: 12px;
}