.bar {
  display: grid;
  grid-template-columns: repeat(3, 341px) auto;
  grid-column-gap: 8px;
  grid-template-rows: 24px 22px;
  grid-template-areas:
		"area-1 area-3 area-5 area-7"
		"area-2 area-4 area-6 area-7";
  /* align-items: center; */
  top: 76px;   /* = высота панели вкладок. */
  margin-bottom: 8px;
  background: var(--back__tab-hover);
  padding-left: 6px;

  position: sticky;
  z-index: 1;      /* Перекрываем прокручиваемое содержание. */
  width: 1240px;   /* Ширина контента. */
  border-top: solid 1px var(--back);
  border-bottom: solid 1px var(--back);
  color: var(--back);

  & > *:nth-child(1) { grid-area: area-1; align-self: end;   }   /* Объект. */
  & > *:nth-child(2) { grid-area: area-2; align-self: start; }   /* Адрес. */
  & > *:nth-child(3) { grid-area: area-3; align-self: end;   }   /* Смета. */
  & > *:nth-child(4) { grid-area: area-4; align-self: start; }   /* Статус. */
  & > *:nth-child(5) { grid-area: area-5; align-self: end;   }   /* Подрядчик: Организация. */
  & > *:nth-child(6) { grid-area: area-6; align-self: start; }   /* Подрядчик: Данные. */
  & > *:nth-child(7) { grid-area: area-7; }   /* Даты. */

  /*--- Статус ---*/

  & > *:nth-child(4) {
    display: grid;
    grid-template-columns: 52px auto;
    grid-template-areas:
		"area-1 area-2";
    align-items: start;
  }

  & > *:nth-child(4) > :nth-child(1) { grid-area: area-1; }   /* «Статус». */
  & > *:nth-child(4) > :nth-child(2) { grid-area: area-2; }   /* Статус. */

  /*--- Подрядчик: Данные ---*/

  & > *:nth-child(6) {
    display: grid;
    grid-template-columns: 120px auto;
    grid-template-areas:
		"area-1 area-2";
    align-items: start;
  }

  & > *:nth-child(6) > :nth-child(1) { grid-area: area-1; }   /* Телефон. */
  & > *:nth-child(6) > :nth-child(2) { grid-area: area-2; }   /* Имя Подрядчика. */

  /*--- Даты. ---*/

  & > *:nth-child(7) {
    display: grid;
    grid-template-columns: 78px auto 78px;
    grid-template-areas:
		"area-1 area-1 area-1"
		"area-2 area-3 area-4";
  }

  & > *:nth-child(7) > :nth-child(1) { grid-area: area-1; align-self: end;   justify-self: center; }   /* Надпись. */
  & > *:nth-child(7) > :nth-child(2) { grid-area: area-2; align-self: start; justify-self: end;    }   /* Дата. */
  & > *:nth-child(7) > :nth-child(3) { grid-area: area-3; align-self: start; justify-self: center; }   /* Тире. */
  & > *:nth-child(7) > :nth-child(4) { grid-area: area-4; align-self: start; justify-self: start;  }   /* Дата. */

  /*--- Черта под ссылкой ---*/

  & :global(.link-light) {
    border-bottom: dotted 1px;
  }

  /* Иначе черта не видна (overflow: hidden). */
  & > *:nth-child(1),
  & > *:nth-child(3),
  & > *:nth-child(5) {
    padding-bottom: 1px;
  }

  /*--- Шрифты ---*/

  & > *:nth-child(2),
  & > *:nth-child(4) > :nth-child(1),
  & > *:nth-child(6),
  & > *:nth-child(7) > :nth-child(1) {
    font-family: 'thin';
  }

  /* Имя статуса. */
  & > *:nth-child(4) > :nth-child(2) {
    font-family: 'bold';
  }

  & > *:nth-child(2),
  & > *:nth-child(4),
  & > *:nth-child(6) {
    font-size: 12px;
  }
}