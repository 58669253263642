.billing {
  transition: all 0.2s;
}

.billingLoading {
  opacity: 0.5;
  pointer-events: none;
}

.tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 34px;
  width: 100%;
  border: solid 2px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background: var(--text-head);
  border-color: var(--back);
  font-family: 'thin';
  color: var(--back);
  border-radius: 0;
  text-shadow: none;

  &:hover {
    background: var(--back__tab-hover);
    cursor: pointer;
  }

  &.isActive {
    background: var(--back-main);
    border-color: var(--blue__48-53);
    font-family: 'bold';
    color: var(--text-head);
    cursor: default;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -4px;
      height: 4px;
      background: var(--back-main);
    }
  }
}

.content {
  border: solid 2px var(--blue__48-53);
  background: var(--back-main);
}
