
.estimateRow {
  margin-left: 12px;
  border-style: solid;
  border-width: 1px 0 1px 1px;
  border-color: var(--gray-85);
  background: var(--blue12_89);
  display: grid;
  grid-template-columns: 8px 28px auto repeat(4, 160px) 12px;
  grid-template-rows: 32px;
  grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-6 .";
  align-items: center;

  & > *:nth-child(1) { grid-area: area-1; }   /* Переключатель. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Смета. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Дата. */
  & > *:nth-child(4) { grid-area: area-4; }   /* Статус Наряда. */
  & > *:nth-child(5) { grid-area: area-5; }   /* Аванс. */
  & > *:nth-child(6) { grid-area: area-6; }   /* План. */

  /* Переключатель, Смета. */
  & > *:nth-child(1),
  & > *:nth-child(2) {
    justify-self: start;
  }

  /* Переключатель: выравнивание. */
  & > *:nth-child(1) > span {
    top: 2px;
  }

  /* Смета: Название. */
  & > *:nth-child(2) > a {
    border-bottom: dotted 1px;
  }

  /* Дата, Статус. */
  & > *:nth-child(3),
  & > *:nth-child(4) {
    justify-self: center;
  }

  /* Колонки с числами. */
  & > *:nth-child(5),
  & > *:nth-child(6) {
    justify-self: end;
  }
}

.contractList {
  margin-left: 28px;
  border-bottom: solid 1px var(--gray-85);
  border-left: solid 4px var(--gray-81);
  background: var(--green12_89);
  padding-top: 4px;
  padding-bottom: 4px;
}

.contractRow {
  display: grid;
  grid-template-columns: 12px 28px auto repeat(4, 160px) 12px;
  grid-template-rows: 38px;
  grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-6 .";
  align-items: center;

  & > *:nth-child(1) { grid-area: area-1; }   /* Макер. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Договор. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Дата. */
  & > *:nth-child(4) { grid-area: area-4; }   /* Статус. */
  & > *:nth-child(5) { grid-area: area-5; }   /* Аванс. */
  & > *:nth-child(6) { grid-area: area-6; }   /* План. */

  /* Макер, Договор. */
  & > *:nth-child(1),
  & > *:nth-child(2) {
    justify-self: start;
  }

  /* Договор. */
  & > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  /* Договор: Название договора, ссылка. */
  & > *:nth-child(2) > a {
    border-bottom: dotted 1px;
  }

  /* Договор СМР: Наряд. */
  & > *:nth-child(2) > span {
    font-family: 'thin-italic';
    font-size: 12px;
  }

  /* Дата, Статус. */
  & > *:nth-child(3),
  & > *:nth-child(4) {
    justify-self: center;
  }

  /* Колонки с числами. */
  & > *:nth-child(5),
  & > *:nth-child(6) {
    justify-self: end;
  }
}