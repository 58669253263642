.createEmployeeModal {
  width: 480px;

  &Notification {
    width: 100%;
    border-bottom: solid 1px var(--gray-83);
    background: var(--blue48_89);
    padding-top: 12px;
    padding-bottom: 6px;
    text-align: center;
  }

  &Content {
    padding: 16px 12px;
    display: grid;
    grid-template-columns: 152px auto;
    grid-template-rows: repeat(6, 32px) repeat(2, auto);
    grid-template-areas:
		"area-1  area-2 "
		"area-3  area-4 "
		"area-5  area-6 "
		"area-7  area-8 "
		"area-9  area-10"
		"area-11 area-12"
		"area-13 area-13"
		"area-14 area-15";
    align-items: center;

    & > *:nth-child(1)  { grid-area: area-1;  }   /* «Фамилия». */
    & > *:nth-child(2)  { grid-area: area-2;  }   /* Фамилия. */
    & > *:nth-child(3)  { grid-area: area-3;  }   /* «Имя». */
    & > *:nth-child(4)  { grid-area: area-4;  }   /* Имя. */
    & > *:nth-child(5)  { grid-area: area-5;  }   /* «Отчество». */
    & > *:nth-child(6)  { grid-area: area-6;  }   /* Отчество. */
    & > *:nth-child(7)  { grid-area: area-7;  }   /* «Логин». */
    & > *:nth-child(8)  { grid-area: area-8;  }   /* Логин. */
    & > *:nth-child(9)  { grid-area: area-9;  }   /* «E-mail». */
    & > *:nth-child(10) { grid-area: area-10; }   /* E-mail. */
    & > *:nth-child(11) { grid-area: area-11; }   /* «Телефон». */
    & > *:nth-child(12) { grid-area: area-12; }   /* Телефон. */

    & > *:nth-child(13) { grid-area: area-13; }   /* Линия. */

    & > *:nth-child(14) { grid-area: area-14; }   /* Кнопка. */
    & > *:nth-child(15) { grid-area: area-15; }   /* Кнопка. */

    /* Поля по ширине окна. */
    & input {
      width: auto;

      &.error {
        border: 1px solid red;
      }
    }

    /* Кнопка «Отмена». */
    & > *:nth-child(15) {
      justify-self: end;
    }

    /* Ширина кнопок. */
    & button {
      width: 136px;
    }
  }
}