/* Инициируем шрифты сайта (только свои). */

/*===============/
  Шрифт логотипа
/===============*/

/*
	Семейство «20 db»: Normal (400).
	Subset (unicode-range): 157 chars:
	Буквы латиницы и кириллицы, базовая пунктуация, несколько символов.
*/
@font-face {
  font-family: "logo-text";
  src: url("../../fonts/20db.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0020-0024, U+0026-0029, U+002B-007B, U+007D, U+0401,
    U+0410-044F, U+0451;
}

/*===============/
  Основной текст
/===============*/

/*
	Семейство «IBM Plex Sans»: Text; Normal (500).
	Subset (unicode-range): полный.
*/
@font-face {
  font-family: "regular";
  src: url("../../fonts/ibm_plex_sans_5.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

/*
	Семейство «IBM Plex Sans»: Text; Italic (500).
	Subset (unicode-range): полный.
*/
@font-face {
  font-family: "regular-italic";
  src: url("../../fonts/ibm_plex_sans_5i.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

/*=============/
  Жирный текст
/=============*/

/*
	Семейство «IBM Plex Sans»: Semi Bold; Normal (700).
	Subset (unicode-range): полный.
*/
@font-face {
  font-family: "bold";
  src: url("../../fonts/ibm_plex_sans_7.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

/*
	Семейство «IBM Plex Sans»: Semi Bold; Italic (700).
	Subset (unicode-range): полный.
*/
@font-face {
  font-family: "bold-italic";
  src: url("../../fonts/ibm_plex_sans_7i.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

/*=============/
  Тонкий текст
/=============*/

/*
	Семейство «IBM Plex Sans»: Light; Normal (300).
	Subset (unicode-range): полный.
*/
@font-face {
  font-family: "thin";
  src: url("../../fonts/ibm_plex_sans_3.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

/*
	Семейство «IBM Plex Sans»: Light; Italic (300).
	Subset (unicode-range): полный.
*/
@font-face {
  font-family: "thin-italic";
  src: url("../../fonts/ibm_plex_sans_3i.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}
