.employees {
  display: grid;
  grid-template-columns: 12px auto 12px;
  grid-template-areas:
		". area-1 ."
		". area-2 .";

  &Captions {
    grid-area: area-1;
    display: grid;
    grid-template-columns: auto 180px 220px 146px 120px;
    grid-template-areas:
		"area-1 area-2 area-3 area-4 area-5";

    margin-bottom: 6px;
    border-bottom: solid 1px var(--back-nav);
    padding-bottom: 4px;

    & > *:nth-child(1) {
      grid-area: area-1;
    }

    /* ФИО, Кнопки. */
    & > *:nth-child(2) {
      grid-area: area-2;
    }

    /* Логин. */
    & > *:nth-child(3) {
      grid-area: area-3;
    }

    /* E-mail. */
    & > *:nth-child(4) {
      grid-area: area-4;
    }

    /* Телефон. */
    & > *:nth-child(5) {
      grid-area: area-5;
    }

    /* Аккаунт. */

    /* ФИО, Кнопки. */
    & > *:nth-child(1) {
      justify-self: start;
    }

    & > *:nth-child(2),
    & > *:nth-child(3),
    & > *:nth-child(4) {
      justify-self: center;
    }

    /* Аккаунт. */
    & > *:nth-child(5) {
      justify-self: end;
    }

    /* Текст в заголовке. */
    & span {
      font-family: 'thin';
      font-size: 16px;
    }

    /*--- ФИО, Кнопки ---*/
    & > *:nth-child(1) {
      display: grid;
      grid-template-columns: auto 98px 126px 126px;
      grid-template-areas:
		"area-1 . area-2 area-3";
    }

    & > *:nth-child(1) > :nth-child(1) {
      grid-area: area-1;
    }

    /* ФИО. */
    & > *:nth-child(1) > :nth-child(2) {
      grid-area: area-2;
    }

    /* Кнопка (1). */
    & > *:nth-child(1) > :nth-child(3) {
      grid-area: area-3;
    }

    /* Кнопка (2). */
  }

  &Data {
    grid-area: area-2;
  }
}

.employeeItem {
  display: grid;
  grid-template-columns: auto 180px 220px 146px 120px;
  /* grid-template-rows: auto; */
  grid-template-areas:
		"area-1 area-2 area-3 area-4 area-5";
  align-items: center;
  padding: 2px 4px 3px;
  cursor: pointer;

  transition: all 0.3s ease-in-out;

  &:last-child {
    margin-bottom: 18px;
  }

  &:nth-child(odd) {
    background: var(--back);
  }


  & > *:nth-child(1) {
    grid-area: area-1;
  }

  /* ФИО. */
  & > *:nth-child(2) {
    grid-area: area-2;
  }

  /* Логин. */
  & > *:nth-child(3) {
    grid-area: area-3;
  }

  /* E-mail. */
  & > *:nth-child(4) {
    grid-area: area-4;
  }

  /* Телефон. */
  & > *:nth-child(5) {
    grid-area: area-5;
  }

  /* Аккаунт. */

  /* ФИО. */
  & > *:nth-child(1) {
    justify-self: start;
  }

  & > *:nth-child(2),
  & > *:nth-child(3),
  & > *:nth-child(4) {
    justify-self: center;
  }

  /* Аккаунт. */
  & > *:nth-child(5) {
    justify-self: end;
  }

  /* Строка блокированного Прораба. */
  &.locked {
    color: var(--gray-77);
  }

  /* Наведение на строку. */
  &:hover {
    background: var(--back__row-hover);
    color: var(--text__row-hover);
  }

  &.selected {
    background: var(--back__row-highlight);
    color: var(--text__row-highlight);
  }

  &Status {
    button {
      width: 96px;
    }
  }

  .notVerified {
    color: var(--error-dark);
  }

  &:hover .notVerified,
  &.selected .notVerified {
    color: var(--error-light-2);
  }

  .verified {
    color: var(--accept-dark);
  }

  &:hover .verified,
  &.selected .verified {
    color: var(--accept-light);
  }
}