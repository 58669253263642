/*
	Определение базового дизайна элементов (для этого сайта).

	Для совместимости с браузерами и их версиями User Agent Stylesheet
	переопределяем «дефолтные» значения свойств на единые.

	Корректировки по ситуациям определяем в соответствующих стилевых файлах,
	приоритет которых в «allstyle.css» ниже текущего.
*/

/*===============/
  Общие свойства
/===============*/

/*
	Основные свойства всех элементов приводим к единому стилю.

	box-sizing
		Соответствие указываемому размеру по границам элемента:
		border-box — ширина границ и поля являются частью размера (уменьшают содержимое).
	font
		Объединяет наследование (inherit) свойств шрифта:
			font-…:
				-family; -style; -weight; -size; -stretch;
			font-variant-…:
				-ligatures; -caps; -numeric; -east-asian; -alternates
			line-height
	…-spacing / text-…
		Наследование (inherit) свойств текста.
*/
*,
::before,
::after {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: baseline;
  font: inherit;
  font-kerning: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
  text-indent: inherit;
  text-transform: inherit;
}

/* Обрамление элементов в фокусе будем определять отдельно. */
:focus,
:focus-within,
:focus-visible {
  outline: none;
}

/*==================/
  Основные элементы
/==================*/

/*----------------/
  Каркас страницы
/----------------*/

/*
	min-height
		Элемент <body> несёт весь «Grid» и должен быть на всю высоту окна браузера,
		чтобы «footer» всегда был прижат к нижней границе окна.
	font-…
		Шрифт; селектор «*» (см. выше) наследует эти свойства для всех элементов.
	…-spacing / text-…
		Текст; селектор «*» (см. выше) наследует эти свойства для всех элементов.
	overflow-y
		Прокрутка справа выводится всегда, чтобы содержание не прыгало.
*/

body {
  background: var(--back);
  overflow-y: scroll;
}

#root {
  min-height: 100vh;
  font-family: "regular";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  font-stretch: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: slashed-zero; /* Цифра «0» со слешем внутри. */
  font-variant-east-asian: normal;
  font-kerning: normal;

  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0;
  text-transform: none;

  color: var(--text);
  /*overflow-y: scroll;*/
  cursor: default;
}

/*----------/
  Заголовки
/----------*/

h1 {
  font-size: 36px;
  line-height: 36px;
}
h2 {
  font-size: 30px;
  line-height: 32px;
}
h3 {
  font-size: 25px;
  line-height: 28px;
}
h4 {
  font-size: 22px;
  line-height: 25px;
}
h5 {
  font-size: 19px;
  line-height: 23px;
}
h6 {
  font-size: 17px;
  line-height: 21px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-head);
}

/*------/
  Абзац
/------*/

p {
  margin-bottom: 10px;
}

/*=======/
  Шрифты
/=======*/

/*---------------/
  Основной текст
/---------------*/

/* Обычный (regular) определён в селекторе «body» (см. выше). */

/* Обычный курсив. */
i,
em {
  font-family: "regular-italic";
  font-weight: 500;
  font-style: italic;
}

/*-----------------/
  Выделение жирным
/-----------------*/

/* Жирный normal. */
h2,
h4,
h6,
b,
strong,
.text__bold {
  font-family: "bold";
  font-weight: 700;
  font-style: normal;
}

/* Жирный курсив. */
h1,
h3,
h5,
b i,
strong i,
i b,
i strong,
b em,
strong em,
em b,
em strong,
.text__bold--i {
  font-family: "bold-italic";
  font-weight: 700;
  font-style: italic;
}

/*-------------/
  Тонкий текст
/-------------*/

legend,
.text__thin {
  font-family: "thin";
  font-weight: 300;
  font-style: normal;
}

.text__thin--i {
  font-family: "thin-italic";
  font-weight: 300;
  font-style: italic;
}

/* Адрес «автора». */
address {
  font-style: normal;
}

/*=================================/
  Вид курсора над типами элементов
/=================================*/

/* По умолчанию для всего «body» (см. выше) определён вид «default». */

/*  Нажимаемые (кнопки, ссылки). */
a,
button,
select:not([multiple]),
option,
[type="button"],
[type="checkbox"],
[type="color"],
[type="file"],
[type="image"],
[type="radio"],
[type="reset"],
[type="submit"],
.clickable {
  cursor: pointer;
}

/* Вставка курсора (ввод текста). */
textarea,
[type="email"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="url"] {
  cursor: text;
}

/* Совмещённые: вставка курсора / кнопки управления / кнопка виджета. */
[type="date"],
[type="datetime-local"],
[type="month"],
[type="number"],
[type="time"],
[type="week"] {
  cursor: alias;
}

/* Перемещаемый индикатор (ползунок). */
[type="range"] {
  cursor: col-resize;
}

/* Вывод всплывающей подсказки. */
abbr[title] {
  cursor: help;
}

/* Недоступные элементы. */
:disabled,
[disabled],
select[disabled] {
  cursor: not-allowed;
}

/*===========================/
  Ввод данных, поля и кнопки
/===========================*/

/* Отключаем браузерное оформление, будем задавать сами. */
input,
button,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input,
select {
  border-radius: 1px;
}

/*------------------/
  Поля ввода текста
/------------------*/

/*
	У простых текстовых полей значения «padding» соответствуют задаваемым.
	У текстовых полей с виджетами добавляются расстояния внутренней структуры UAS.
	При этом общая ширина у полей с виджетами зависит от типа.
	Выравниваем:
		по высоте    — задаём всем общий «height»;
		по ширине    — задаём всем общий «width»;
		отступ слева — прописываем отдельные «padding-left» по типам.
*/

/* Все текстовые поля: Общее свойства. */
select,
[type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"] {
  width: 172px;
  height: 28px; /* Равно высоте кнопки (button). */
  border-style: solid;
  border-width: 1px;
  text-align: left;

  /* border-color: var(--input-bord-normal);
	background: var(--input-back-normal);
	color: var(--input-text-normal); */
  border-color: var(--input-bord-normal);
  background: var(--input-back-normal);
  color: var(--input-text-normal);

  transition: all 0.2s ease-in-out;
}

/*
	Все текстовые поля: вид текста в «placeholder».
	В UAS определяется псевдоэлементом ::-webkit-input-placeholder
*/
[type="date"]::placeholder,
[type="datetime-local"]::placeholder,
[type="email"]::placeholder,
[type="month"]::placeholder,
[type="number"]::placeholder,
[type="password"]::placeholder,
[type="search"]::placeholder,
[type="tel"]::placeholder,
[type="text"]::placeholder,
[type="time"]::placeholder,
[type="url"]::placeholder,
[type="week"]::placeholder {
  color: var(--input-bord-normal);
}

/* Все текстовые поля: Наведение. */
select:hover,
[type="date"]:hover,
[type="datetime-local"]:hover,
[type="email"]:hover,
[type="month"]:hover,
[type="number"]:hover,
[type="password"]:hover,
[type="search"]:hover,
[type="tel"]:hover,
[type="text"]:hover,
[type="time"]:hover,
[type="url"]:hover,
[type="week"]:hover {
  border-color: var(--input-bord-hover);
  background: var(--input-back-hover);
}

/* Все текстовые поля: Фокус. */
select:focus-within,
[type="date"]:focus-within,
[type="datetime-local"]:focus-within,
[type="email"]:focus-within,
[type="month"]:focus-within,
[type="number"]:focus-within,
[type="password"]:focus-within,
[type="search"]:focus-within,
[type="tel"]:focus-within,
[type="text"]:focus-within,
[type="time"]:focus-within,
[type="url"]:focus-within,
[type="week"]:focus-within {
  border-color: var(--input-text-normal);
  background: var(--input-back-normal);
}

/* Все текстовые поля: Недоступные. */
[type="date"]:disabled,
[type="datetime-local"]:disabled,
[type="email"]:disabled,
[type="month"]:disabled,
[type="number"]:disabled,
[type="password"]:disabled,
[type="search"]:disabled,
[type="tel"]:disabled,
[type="text"]:disabled,
[type="time"]:disabled,
[type="url"]:disabled,
[type="week"]:disabled {
  border-color: var(--input-bord-disable);
  background: var(--input-back-disable);
}

/* Простые текстовые поля: отступ слева. */
select,
[type="email"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="url"],
[type="week"] {
  padding-left: 4px;
}

/* Текстовые поля с виджетами: отступ слева. */
[type="date"],
[type="datetime-local"],
[type="month"],
[type="time"] {
  padding-left: 3px;
}

/* Значок в поле даты. */
input[type="date" i]::-webkit-calendar-picker-indicator {
  opacity: 0.5;
  margin: 0;
  width: 16px;
  padding: 0;
}

/* Подпись к недоступному полю. */

label[disabled] {
  color: var(--input-bord-hover);
}

/*-------------------------------/
  Переключатели (radio/checkbox)
/-------------------------------*/

/*
	Браузерное оформление (appearance) отключено выше.
	Вид радикнопок/чекбоксов и состояния «рисуем» во псевдоэлементе input`а.
	Особенности варианта:
		<input> вложен в <label> и находится перед текстом надписи;
		размер (width/height) ориентирован на размер шрифта 14px (см. body);
	При других вариантах могут потребоваться корректировки отступа и размера.
*/

[type="radio"],
[type="checkbox"] {
  position: relative; /* Позволяет позиционировать псевдоэлемент. */
  padding-right: 20px; /* Место под индикатор, расстояние до надписи. */
}

[type="radio"]::before,
[type="checkbox"]::before {
  display: inline-block;
  position: absolute;
  bottom: -1px;
  width: 12px;
  height: 12px;
  border-style: solid;
  border-width: 1px;
  content: "";

  border-color: var(--input-bord-hover);
  background: var(--input-back-normal);

  transition: all 0.3s linear;
}
/* Радиокнопка — круг. */
[type="radio"]::before {
  border-radius: 50%;
}
/* Чекбокс — квадрат. */
[type="checkbox"]::before {
  border-radius: 1px;
}

[type="radio"]:hover::before,
[type="checkbox"]:hover::before {
  box-shadow: var(--switch-shade-normal);
}

[type="radio"]:checked::before,
[type="checkbox"]:checked::before {
  box-shadow: var(--switch-shade-check);
  background: var(--green-site);
}

[type="checkbox"]:checked:hover::before {
  box-shadow: var(--switch-shade-normal);
}

[type="radio"]:disabled::before,
[type="checkbox"]:disabled::before,
[type="radio"]:disabled:hover::before,
[type="checkbox"]:disabled:hover::before {
  box-shadow: none;
  border-color: var(--input-bord-normal);
  background: var(--input-back-disable);
}

[type="radio"]:disabled:checked::before,
[type="checkbox"]:disabled:checked::before,
[type="radio"]:disabled:checked:hover::before,
[type="checkbox"]:disabled:checked:hover::before {
  box-shadow: var(--switch-shade-check-disable);
  background: var(--input-bord-normal);
}

/*-------/
  Кнопки
/-------*/

button,
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 28px; /* Равно высоте текстового поля (input). */
  border-radius: 12px;
  /* box-shadow: var(--shadow-logo), -1px -1px 0 var(--gray-57); */
  background: var(--button-back-normal);
  color: var(--button-text-normal);
  text-shadow: var(--button-text-normal-shade);
  transition: all 0.2s ease-in-out;
}

button:hover,
.button:hover {
  background: var(--button-back-hover);
}

button:active,
.button:active {
  background: var(--button-back-active);
}

button:disabled,
.button:disabled {
  background: var(--input-bord-disable);
  color: var(--button-text-disable);
  text-shadow: var(--button-text-disable-shade);
}

/* Кнопка уменьшенного размера. */
button.bttn__small, a.bttn__small {
  height: 24px;
  font-size: 12px;
  line-height: 12px;
  padding: 0 10px;
}

/* Кнопка в шапке */
button.bttn__menu {
  font-size: 12px;
  line-height: 12px;
  padding: 0 10px;
}

/*----------------------/
  Виджет загрузки файла
/----------------------*/

/*--- !!! «Chrome»: Стилизуем input «Выберите файл» ---*/

input[type="file" i]::-webkit-file-upload-button {
  width: 108px;
  margin-right: 8px;
  border: solid 1px var(--button-back-active);
  border-radius: 12px;
  font-family: "thin";
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

input[type="file" i]::-webkit-file-upload-button:hover {
  border-color: var(--button-back-active);
  background: var(--button-back-normal);
  color: var(--button-text-normal);
  text-shadow: var(--button-text-normal-shade);
}

input[type="file" i]::-webkit-file-upload-button:active {
  background: var(--button-back-active);
}

/*---------------------/
  Кликабельный элемент
/---------------------*/

/*
	Применение: Декоративный элемент-индикатор.
	Структура:
		несущий элемент — span — фон, окружность;
		форма значка — after/before — вид по ситуации.
	Вид «кнопки»:
		ring     — граница по окружности (кольцо), светлый фон, внутри тёмный треугольник.
		circle   — закрашенный тёмный круг, внутри светлый треугольник.
	Виды треугольника переключаются в файлах «…-context.css» по ситуации:
	включается свойство «content» соответствующего псевдоэлемента.
*/

/* Кольцо/Круг: Общие свойства. */
span.ring-triangle,
span.circle-triangle {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--back-nav);
  cursor: pointer;
}

/* Треугольник: Общее. */
span.ring-triangle::before,
span.ring-triangle::after,
span.circle-triangle::before,
span.circle-triangle::after {
  position: absolute;
  left: 4px;
  border-style: solid;
  border-width: 8px 4px;
  border-color: transparent;
  /* content: "";   !!! Включается по ситуации. */

  transition: all 0.2s ease-in-out;
}

/* Треугольник: угол вниз. */
span.ring-triangle::before,
span.circle-triangle::before {
  top: 5px;
  border-bottom-width: 0;
}

/* Треугольник: угол вверх. */
span.ring-triangle::after,
span.circle-triangle::after {
  top: 3px;
  border-top-width: 0;
}

/* Кольцо (граница по окружности). */
span.ring-triangle {
  border: solid 1px var(--back-nav);
  background: var(--back);
}
/* Треугольник: угол вниз. */
span.ring-triangle::before {
  border-top-color: var(--back-nav);
}
span.ring-triangle:hover::before {
  border-top-color: hsl(var(--blue-basic), 53%);
}
span.ring-triangle:active::before {
  border-top-color: var(--back);
}
/* Треугольник: угол вверх. */
span.ring-triangle::after {
  border-bottom-color: var(--button-back-active);
}
span.ring-triangle:hover::after {
  border-bottom-color: var(--button-back-normal);
}
span.ring-triangle:active::after {
  border-bottom-color: var(--back);
}

/* Круг (закрашенный круг). */
span.circle-triangle {
  border: solid 1px var(--back-nav);
  background: var(--back-nav);
  box-shadow: 0 0 0 2px var(--back) inset;
}

span.circle-triangle:hover {
  box-shadow: 0 0 0 1px var(--back) inset;
}

/* Треугольник: угол вниз. */
span.circle-triangle::before {
  border-top-color: var(--back);
}
span.circle-triangle:active::before {
  border-top-color: var(--back-nav);
}
/* Треугольник: угол вверх. */
span.circle-triangle::after {
  border-bottom-color: hsl(var(--orange), 96%, 71%);
}
span.circle-triangle:active::after {
  border-bottom-color: var(--back-nav);
}

/*----------------/
  Списки значений
/----------------*/

/*
	Основные стили определены выше (текстовые поля).
	Скрываем в списке пункт, который нельзя выбрать.
*/
select option:disabled {
  display: none;
}

/* Если пункт, который нельзя выбрать, выводится. */
select option:disabled {
  background: var(--gray-77);
  color: var(--gray-57);
}

/*
	Цвет текста до первого выбора.
	При выборе значения всему <select> добавляем класс «selected».
*/
select:not(.selected) {
  color: var(--input-bord-normal);
}

/* Цвет текста внутри списка. */
select:not(.selected) option:not(:disabled) {
  color: var(--input-text-normal);
}

/*============================/
  Ссылки (гиперссылки, якоря)
/============================*/

/* Тёмный текст на светлом фоне. */

a,
a:link,
a:visited {
  color: var(--link-normal);
  text-decoration: none;
  cursor: pointer;
}

span.link-normal {
  color: var(--link-normal);
  cursor: pointer;
}

span.link-normal:hover {
  color: var(--link-hover);
}

a:hover,
a:link:hover,
a:visited:hover {
  border-bottom: solid 1px;
  color: var(--link-hover);
}

a:active,
a:link:active,
a:visited:active {
  color: var(--link-active);
}

/* Светлый текст на тёмном фоне. */

a.link-light,
span.link-light,
a.link-light:link,
a.link-light:visited {
  color: var(--link-normal-light);
}

span.link-light {
  cursor: pointer;
}

a.link-light:hover,
a.link-light:link:hover,
a.link-light:visited:hover {
  color: var(--link-hover-light);
}

a.link-light:active,
a.link-light:link:active,
a.link-light:visited:active {
  color: var(--link-active);
}

/*===================/
  Отдельные элементы
/===================*/

/*--------/
  Символы
/--------*/

/*--- Общее ---*/

[class*="symbol__"]::before,
[class*="symbol__"]::after {
  display: inline-block;
  padding-right: 1px;
  padding-left: 1px;
}

/*--- Кавычки (русские) ---*/

[class*="symbol__quotes"]::before,
[class*="symbol__quotes"]::after {
  padding-right: 0;
  padding-left: 0;
}

/* Первый уровень. */
.symbol__quotes::before,
.symbol__quotes--1::before {
  content: "\00ab";
}
.symbol__quotes::after,
.symbol__quotes--1::after {
  content: "\00bb";
}

/* Второй уровень. */
.symbol__quotes .symbol__quotes::before,
.symbol__quotes--2::before {
  content: "\201e";
}
.symbol__quotes .symbol__quotes::after,
.symbol__quotes--2::after {
  content: "\201c";
}

/* Третий уровень. */
.symbol__quotes .symbol__quotes .symbol__quotes::before,
.symbol__quotes--3::before {
  content: "\2018";
}
.symbol__quotes .symbol__quotes .symbol__quotes::after,
.symbol__quotes--3::after {
  content: "\2019";
}

/*--- Одиночные символы ---*/

/* Символ «Solidus», косая черта (slash): / */
.symbol__slash--before::before,
.symbol__slash--after::after {
  content: "\002f";
}

/* Символ «Colon», двоеточие: : */
.symbol__colon--before::before,
.symbol__colon--after::after {
  content: "\003a";
}

/* Символ «Vertical Line», вертикальная черта: | */
.symbol__vertical--before::before,
.symbol__vertical--after::after {
  content: "\007c";
}

/* Символ «Em Dash», длинное тире: — */
.symbol__mdash--before::before,
.symbol__mdash--after::after {
  content: "\2014";
}

/* Символ «Bullet», маркер: • */
.symbol__bull--before::before,
.symbol__bull--after::after {
  content: "\2022";
}

/* Символ «Horizontal Ellipsis», троеточие: … */
.symbol__hellip--before::before,
.symbol__hellip--after::after {
  content: "\2026";
}

/*--- Пробелы ---*/

[class*="symbol__space-thin--before"]::before,
[class*="symbol__space-thin--after"]::after {
  padding-right: 0;
  padding-left: 0;
}

/* Тонкий пробел «Thin Space». */
.symbol__space-thin--before::before,
.symbol__space-thin--after::after {
  content: "\2009";
}

/*------------/
  Ограничения
/------------*/

/* Длинный текст скрывается. */
.limit__text--length {
  /* max-width: {по ситуации};   / * Ширина видимости, указывается при необходимости. */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 8px;
}
