/*=======================================/
    Блокировка Счёта (.locking__bank)
  Разблокировка Счёта (.unlocking__bank)
/=======================================*/

/*--- Размер окна контейнера ---*/

/*
	Основной размер окна определяем в переменных в контексте темы.
	Ширина окна --window-width должна учитывать:
	columns {сумма всех колонок} + margin-left 20px + поле справа 20px.
	Высота окна --window-height должна учитывать:
	общая высота строк вложенной формы + заголовок + отступ снизу.
*/
#modal__box .locking__bank,
#modal__box .unlocking__bank {
  --window-width: 440px;
  --window-height: 232px;
}

/* Фон окна. */
#modal__box .locking__bank,
#modal__box .unlocking__bank {
  background: var(--error-light);
}

#modal__box .locking__bank h3,
#modal__box .unlocking__bank h3 {
  background: var(--error-dark);
  color: var(--error-light);
}

#modal__box .locking__bank div,
#modal__box .unlocking__bank div {
  margin-bottom: 4px;
  border-bottom: none;
  background: var(--error-light);
  color: var(--error-dark);
}

#modal__box .locking__bank p,
#modal__box .unlocking__bank p {
  margin: 0;
  text-align: center;
}

#modal__box .locking__bank p:nth-of-type(2),
#modal__box .unlocking__bank p:nth-of-type(2) {
  font-family: "bold";
  font-size: 16px;
}
#modal__box .locking__bank p:last-of-type,
#modal__box .unlocking__bank p:last-of-type {
  margin-bottom: 20px;
  font-family: "regular-italic";
}

#modal__box .locking__bank p + button,
#modal__box .unlocking__bank p + button {
  float: left;
  margin-left: 20px;
}

#modal__box .locking__bank button + button,
#modal__box .unlocking__bank button + button {
  float: right;
  margin-right: 20px;
}
