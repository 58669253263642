/*
	Общие вторичные классы.
	Используются в качестве добавочных при изменении ситуации на элементе.
	Настоящий файл должен всегда подключаться последним,
	чтобы перебивать дефолтный вид элементов.
*/

/*==========================/
  Выделение строки по клику
/==========================*/

/*
	item__to-highlight       — Выделяемый элемент, расположение значения не имеет.
	child__to-highlight      — Выделяемый элемент, любой потомок в контейнере «box__diff--highlight».
	list__childs-highlight   — Контейнер, выделяется прямой потомок в контейнере.
	Секция [class] позволяет «перебить» дополнительную раскраску элемента,
	которая может быть добавлена для другого класса этого элемента.
*/

[class].item__to-highlight:hover,
[class].child__to-highlight:hover,
[class].list__childs--highlight > *:hover {
  background: var(--back__row-hover);
  color: var(--text__row-hover);
}

[class].item__to-highlight.highlighted,
[class].child__to-highlight.highlighted,
[class].list__childs--highlight > *.highlighted {
  background: var(--back__row-highlight);
  color: var(--text__row-highlight);
}

/*=====================/
  Кликабельный элемент
/=====================*/

/*
	Элемент не является кнопкой,
	но на него повешен «контрол клика».
	Оформление соответствует кнопке.
*/

/* Анимация: параметры эффекта. */
.animate__clickable {
  transition: all 0.3s ease-in-out;
}

/* Наведение. */
.animate__clickable:hover {
  background-color:var(--orange-site) !important;
  color: var(--button-text-normal);
  text-shadow: var(--button-text-normal-shade);
  cursor: pointer;
}

/* Нажатие. */
.animate__clickable:active {
  background: var(--button-back-active);
}

/*=========================/
  Error — Ошибка — красный
/=========================*/

.error__box,
.error__text,
.error__text h3 {
  color: var(--error-dark);
  border-color: var(--error-dark);
}

.error__box {
  background: var(--error-light);
}

/* .error__box--light {
	background: var(--error-light);
} */

/*===========================/
  Accept — Принято — зелёный
/===========================*/

/*
.accept__text {
}

.accept__box {
	background: var(--accept-***);
}
*/

/*===============/
  Open — Открыто
/===============*/

/*
Не несёт стилей, но является «ситуационным катализатором»:
позволяет менять стиль элемента при изменении ситуации.
.open {}
*/
