.window {
  width: 580px;
}

.loading {
  padding: 15px;
}

.orders {
  width: 100%;
  margin-bottom: 16px;
  border-bottom: solid 1px var(--gray-83);
  padding-top: 12px;
  padding-bottom: 6px;
  text-align: center;
  background: var(--blue48_89);
}

.orderRow {
  display: grid;
  grid-template-columns: 12px 16px auto 36px 26px 12px;
  grid-template-rows: auto;
  grid-template-areas:
		". area-1 area-2 area-3 area-4 .";
  align-items: center;
  margin-bottom: 6px;
  text-align: start;

  & > *:nth-child(1) { grid-area: area-1; }   /* Маркер. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Наряд. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Дни. */
  & > *:nth-child(4) { grid-area: area-4; }   /* «дн.». */

  /* Наряд. */
  & > *:nth-child(2) {
    line-height: 15px;
  }

  /* Дни. */
  & > *:nth-child(3) {
    justify-self: end;
  }

  /* Надпись «дн.» */
  & > *:nth-child(4) {
    justify-self: end;
    font-family: 'thin';
  }
}

.totalDays {
  display: grid;
  grid-template-columns: 12px auto 42px 26px 12px;
  /* grid-template-rows: auto; */
  grid-template-areas:
		". area-1 area-2 area-3 .";
  align-items: center;
  margin-top: 12px;
  border-top: solid 1px var(--gray-83);
  padding-top: 6px;
  text-align: start;

  & > *:nth-child(1) { grid-area: area-1; }   /* «Общая…» */
  & > *:nth-child(2) { grid-area: area-2; }   /* Дни. */
  & > *:nth-child(3) { grid-area: area-3; }   /* «дн.». */

  /* «Общая…» */
  & > *:nth-child(1) {
    font-family: 'thin';
    font-size: 15px;
  }

  /* Дни. */
  & > *:nth-child(2) {
    justify-self: end;
  }

  /* Надпись «дн.» */
  & > *:nth-child(3) {
    justify-self: end;
    font-family: 'thin';
  }
}

.form {
  display: grid;
  grid-template-columns: auto 60px 140px 86px 120px;
  grid-template-rows: repeat(2, 32px) auto auto;
  grid-template-areas:
		"area-1 area-2 area-2 area-2  area-2 "
		"area-3 area-4 area-5 area-6  area-7 "
		"area-8 area-8 area-8 area-8  area-8 "
		"area-9 .      .      area-10 area-10";
  align-items: center;
  padding: 0 12px 16px;

  hr {
    margin-top: 16px;
    margin-bottom: 16px;
    border-bottom: solid 1px var(--gray-83);
  }

  & > *:nth-child(1)  { grid-area: area-1;  }   /* «Цепочка». */
  & > *:nth-child(2)  { grid-area: area-2;  }   /* Цепочка. */

  & > *:nth-child(3)  { grid-area: area-3;  }   /* «Плановые сроки». */
  & > *:nth-child(4)  { grid-area: area-4;  }   /* «Начало». */
  & > *:nth-child(5)  { grid-area: area-5;  }   /* Начало. */
  & > *:nth-child(6)  { grid-area: area-6;  }   /* «Окончание». */
  & > *:nth-child(7)  { grid-area: area-7;  }   /* Окончание. */

  & > *:nth-child(8)  { grid-area: area-8;  }   /* Линия. */

  & > *:nth-child(9)  { grid-area: area-9;  }   /* Кнопка. */
  & > *:nth-child(10) { grid-area: area-10; }   /* Кнопка. */

  /* Кнопка «Отмена». */
  & > *:nth-child(10) {
    justify-self: end;
  }

  /* Поле названия по ширине окна. */
  & [type="text"] {
    width: auto;
  }

  /* Подписи к полям дат. */
  & > *:nth-child(4),
  & > *:nth-child(6) {
    font-family: 'thin';
  }

  /* Поля дат. */
  & [type="date"] {
    width: 120px;
  }
}