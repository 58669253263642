.accounts {
  display: grid;
  grid-template-columns: 12px auto 12px;
  grid-template-areas:
        ". area-1 ."
        ". area-2 .";

  &Captions {
    grid-area: area-1;
    display: grid;
    grid-template-columns: auto 234px 242px 160px 116px;
    grid-template-areas:
		"area-1 area-2 area-3 area-4 area-5";
    margin-bottom: 6px;
    border-bottom: solid 1px var(--back-nav);
    padding-bottom: 4px;

    & > *:nth-child(1) { grid-area: area-1; }   /* Банк, Кнопки. */
    & > *:nth-child(2) { grid-area: area-2; }   /* Р/Счёт. */
    & > *:nth-child(3) { grid-area: area-3; }   /* К/Счёт. */
    & > *:nth-child(4) { grid-area: area-4; }   /* БИК. */
    & > *:nth-child(5) { grid-area: area-5; }   /* Эскроу. */

    /* Банк, Кнопки. */
    & > *:nth-child(1) {
      justify-self: start;
    }

    & > *:nth-child(2),
    & > *:nth-child(3),
    & > *:nth-child(4) {
      justify-self: center;
    }

    /* Эскроу. */
    & > *:nth-child(5) {
      justify-self: end;
    }

    /*--- Оформление панели заголовков ---*/

    & span {
      font-family: 'thin';
      font-size: 16px;
    }

    /*--- Банк, Кнопки ---*/

    & > *:nth-child(1) {
      display: grid;
      grid-template-columns: auto 186px 220px;
      /* grid-template-rows: auto; */
      grid-template-areas:
		"area-1 . area-2";
    }

    & > *:nth-child(1) > *:nth-child(1) { grid-area: area-1; }   /* «Банк». */
    & > *:nth-child(1) > *:nth-child(2) { grid-area: area-2; }   /* Кнопка. */

  }

  &Data {
    grid-area: area-2;
  }
}

.accountRow {
  display: grid;
  grid-template-columns: auto 240px 240px 160px 112px;
  /* grid-template-rows: auto; */
  grid-template-areas:
		"area-1 area-2 area-3 area-4 area-5";
  align-items: center;
  padding: 2px 4px 3px;
  cursor: pointer;

  transition:  all 0.3s ease-in-out;

  & > *:nth-child(1) { grid-area: area-1; }   /* Банк. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Р/Счёт. */
  & > *:nth-child(3) { grid-area: area-3; }   /* К/Счёт. */
  & > *:nth-child(4) { grid-area: area-4; }   /* БИК. */

  &Type,
  & select        { grid-area: area-5; justify-self: end; }   /* Тип. */

  &TypeText {
    display: inline-block;
    width: 102px;
    border: solid 1px transparent;
    padding: 1px 3px;
    font-size: 12px;
    text-align: end;
    transition: border-color 0.1s ease-in-out;
  }

  &:hover &TypeText {
    border-color: var(--gray-77);
  }

  /* Банк. */
  & > *:nth-child(1) {
    justify-self: start;
  }

  /* Р/Счёт / К/Счёт / БИК */
  & > *:nth-child(2),
  & > *:nth-child(3),
  & > *:nth-child(4) {
    justify-self: center;
  }

  /* Тип. */
  & select {
    width: 102px;
    height: 22px;
    padding-right: 3px;
    text-align: center;
    font-size: 12px;
  }
  
  &:last-child {
    margin-bottom: 18px;
  }

  &:nth-child(odd) {
    background: var(--back);
  }

  &:hover {
    background: var(--back__row-hover);
    color: var(--text__row-hover);
  }

  &.selected {
    background: var(--back__row-highlight);
    color: var(--text__row-highlight);
  }

  &.locked {
    color: var(--gray-77);
  }
}