/*
	Страница «Профиль пользователя»
	.page__profile
	Роль «Прораб»
	.role__foreman
*/



/*=============================/
  Области основного содержания
/=============================*/

/*-----------/
  Компоновка
/-----------*/

/* Ширина содержания =1240px. */
.page__profile.role__foreman main {
	display: grid;
	grid-template-columns: 620px 620px;
	grid-template-rows: 34px 34px max-content;
	grid-template-areas:
		"area-1 area-1"
		"area-2 area-3"
		"area-4 area-4";
}

/* :nth-child(1) — Заголовок страницы. */
.page__profile.role__foreman main > h2 {
	grid-area: area-1;
}

/* :nth-child(2) — Радио-кнопка для вкладки 1, скрыта. */
.page__profile.role__foreman main > #tab__account {
	display: none;
}

/* :nth-child(3) — Ярлык вкладки 1. */
.page__profile.role__foreman main > [for="tab__account"] {
	grid-area: area-2;
}

/* :nth-child(4) — Радио-кнопка для вкладки 2, скрыта. */
.page__profile.role__foreman main > #tab__statistics {
	display: none;
}

/* :nth-child(5) — Ярлык вкладки 1. */
.page__profile.role__foreman main > [for="tab__statistics"] {
	grid-area: area-3;
}

/* :nth-child 6 и 7 — содержание текущей вкладки, изначально скрыто. */
.page__profile.role__foreman main > #content__account,
.page__profile.role__foreman main > #content__statistics {
	grid-area: area-4;
	display: none;
}

/*---------------/
  Ярлыки вкладок
/---------------*/

/*--- Общий вид ---*/

/* Позиционаруем (relative) для выравнивания вложенного <span>. */
.page__profile.role__foreman main > [for^="tab__"] {
	display: block;
	position: relative;
	width: 620px;   /* = ширине колонки «grid-template-columns». */
	height: 34px;
	border: solid 2px;
	text-align: center;

	transition: all 0.3s ease-in-out;
}

/*	<span> фоном перекрывает границу текущего содержания. */
.page__profile.role__foreman main > [for^="tab__"] > span {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	padding-top: 6px;
}

/*--- Неактивный ярлык ---*/

/* Общее. */
.page__profile.role__foreman main > :not(:checked) + [for^="tab__"] {
	background: var(--text-head);
	border-color: var(--back);
	font-family: 'thin';
	color: var(--back);
}

/* Наведение. */
.page__profile.role__foreman main > :not(:checked) + [for^="tab__"]:hover {
	background: var(--back__tab-hover);
	cursor: pointer;
}

/*--- Текущий ярлык ---*/

/* Общее. */
.page__profile.role__foreman main > :checked + [for^="tab__"] {
	border-color: var(--blue__48-53);
	font-family: 'bold';
	color: var(--text-head);
	cursor: default;
}

/*	<span> фоном перекрывает границу текущего содержания. */
.page__profile.role__foreman main > :checked + [for^="tab__"] span {
	background: var(--back-main);
	height: 34px;
}

/*---------------------/
  Переключение вкладок
/---------------------*/

/*--- Привязка содержания к ярлыку ---*/

/* При выборе ярлыка (input checked) выводим блок содержания. */
.page__profile.role__foreman #tab__account:checked ~ #content__account,
.page__profile.role__foreman #tab__statistics:checked ~ #content__statistics {
	display: grid;
	border: solid 2px var(--blue__48-53);
	background: var(--back-main);
	padding-top: 12px;
}





/*=========================/
  Вкладка «Учётные данные»
/=========================*/

/*----------------/
  Стороны вкладки
/----------------*/

/*--- Компоновка ---*/

/* Ширина вкладки =1240px. */
.page__profile #content__account {
	/* display: grid;   Определено в «Переключение вкладок» (выше). */
	grid-template-columns: 12px 350px auto 390px auto 390px 12px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		". area-1 .      area-2 .      area-3 ."
		". area-4 area-4 area-4 area-4 area-4 .";
}

.page__profile #content__account > :nth-child(1) { grid-area: area-1; }   /* Левая сторона. */
.page__profile #content__account > :nth-child(2) { grid-area: area-2; }   /* Середина. */
.page__profile #content__account > :nth-child(3) { grid-area: area-3; }   /* Правая сторона. */
.page__profile #content__account > :nth-child(4) { grid-area: area-4; }   /* Низ. */

.page__profile #content__account > div {
	margin-bottom: 12px;
}

/*--- Разделение сторон ---*/

/* Вертикальные границы. */
.page__profile .content__account--middle,
.page__profile .content__account--right {
	border-left: solid 1px var(--back-nav);
	padding-left: 12px;
}

/* Горизонтальная граница. */
.page__profile .content__account--bottom {
	border-top: solid 1px var(--back-nav);
	padding-top: 12px;
}

/*--- Общее оформление ---*/

/* Заголовки секторов. */
.page__profile #content__account > div > h6 {
	margin-bottom: 6px;
	font-size: 15px;
}

/* Компоновка надписей и полей. ---*/
.page__profile #content__account > :nth-child(1),
.page__profile #content__account > :nth-child(2),
.page__profile #content__account > :nth-child(3) {
	display: grid;
	grid-template-columns: 120px 12px auto;
	/* grid-template-rows: auto; */
}

/* Надписи к полям. */
.page__profile #content__account > :nth-child(1) > :nth-child(2n+2),
.page__profile #content__account > :nth-child(2) > :nth-child(2n+2),
.page__profile #content__account > :nth-child(3) > :nth-child(2n+2) {
	font-family: 'thin';
}

/* Ссылка. */
.page__profile #content__account a {
	border-bottom: dotted 1px;
}

/* Кнопка. */
.page__profile #content__account button {
	width: 172px;
}

/*--------------/
  Левая сторона
/--------------*/

/*--- Компоновка ---*/

.page__profile .content__account--left {
	grid-template-areas:
		"area-1 area-1 area-1"
		"area-2 .      area-3"
		"area-4 .      area-5"
		"area-6 .      area-7";
}

.page__profile .content__account--left > :nth-child(1) { grid-area: area-1; }   /* Заголовок. */
.page__profile .content__account--left > :nth-child(2) { grid-area: area-2; }   /* «Логин». */
.page__profile .content__account--left > :nth-child(3) { grid-area: area-3; }   /* Логин. */
.page__profile .content__account--left > :nth-child(4) { grid-area: area-4; }   /* «Телефон». */
.page__profile .content__account--left > :nth-child(5) { grid-area: area-5; }   /* Телефон. */
.page__profile .content__account--left > :nth-child(6) { grid-area: area-6; }   /* «E-mail». */
.page__profile .content__account--left > :nth-child(7) { grid-area: area-7; }   /* E-mail. */

/*--- Статус аккаунта ---*/

/*
	Статус «Не подтверждено»:
		Подрядчик отправил письмо-запрос Прорабу,
		но Прораб ещё не вошёл на сайт по данным из письма.
	Выделяем заголовок, телефон и e-mail.
*/

.page__profile.status__profile--not-confirmed .content__account--left > :nth-child(1),
.page__profile.status__profile--not-confirmed .content__account--left > :nth-child(5),
.page__profile.status__profile--not-confirmed .content__account--left > :nth-child(7) {
	color: var(--error-dark);
}

/*---------/
  Середина
/---------*/

/*--- Компоновка ---*/

.page__profile .content__account--middle {
	grid-template-areas:
		"area-1 area-1 area-1"
		"area-2 .      area-3"
		"area-4 .      area-5"
		"area-6 .      area-7";
}

.page__profile .content__account--middle > :nth-child(1) { grid-area: area-1; }   /* Заголовок. */
.page__profile .content__account--middle > :nth-child(2) { grid-area: area-2; }   /* «Фамилия». */
.page__profile .content__account--middle > :nth-child(3) { grid-area: area-3; }   /* Фамилия. */
.page__profile .content__account--middle > :nth-child(4) { grid-area: area-4; }   /* «Имя». */
.page__profile .content__account--middle > :nth-child(5) { grid-area: area-5; }   /* Имя. */
.page__profile .content__account--middle > :nth-child(6) { grid-area: area-6; }   /* «Отчество». */
.page__profile .content__account--middle > :nth-child(7) { grid-area: area-7; }   /* Отчество. */

/*---------------/
  Правая сторона
/---------------*/

/*--- Компоновка ---*/

.page__profile .content__account--right {
	grid-template-areas:
		"area-1 area-1 area-1"
		"area-2 .      area-3"
		"area-4 .      area-5"
		"area-6 .      area-7";
}

.page__profile .content__account--right > :nth-child(1) { grid-area: area-1; }   /* Заголовок. */
.page__profile .content__account--right > :nth-child(2) { grid-area: area-2; }   /* «Ответственный». */
.page__profile .content__account--right > :nth-child(3) { grid-area: area-3; }   /* Ответственный. */
.page__profile .content__account--right > :nth-child(4) { grid-area: area-4; }   /* «Телефон». */
.page__profile .content__account--right > :nth-child(5) { grid-area: area-5; }   /* Телефон. */
.page__profile .content__account--right > :nth-child(6) { grid-area: area-6; }   /* «Организация». */
.page__profile .content__account--right > :nth-child(7) { grid-area: area-7; }   /* Организация. */

/*-------------/
  Низ (кнопка)
/-------------*/

/*--- Компоновка ---*/





/*=====================/
  Вкладка «Статистика»
/=====================*/

/*-----------/
  Компоновка
/-----------*/

/* Ширина вкладки =1240px. */
.page__profile #content__statistics {
	/* display: grid;   Определено в «Переключение вкладок» (выше). */
	grid-template-columns: 12px 606px auto 596px 12px;
	/* grid-template-rows: auto; */
	grid-template-areas:
		". area-1 . area-2 ."
		". area-1 . area-3 .";
}

.page__profile #content__statistics > :nth-child(1) { grid-area: area-1; }   /* Левая сторона. */
.page__profile #content__statistics > :nth-child(2) { grid-area: area-2; }   /* Правая сторона (верх). */
.page__profile #content__statistics > :nth-child(3) { grid-area: area-3; }   /* Правая сторона (низ). */

/*-----------------/
  Общее оформление
/-----------------*/

/* Заголовки. */
.page__profile .statistic__row--captions {
	border-bottom: solid 1px var(--back-nav);
	font-family: 'thin';
}

/*--------------/
  Левая сторона
/--------------*/

/* Разделитель сторон. */
.page__profile .statistics__left {
	margin-bottom: 12px;
	border-right: solid 1px var(--back-nav);
	padding-right: 12px;
}

/*--- Строки ---*/

/* Компоновка. */
.page__profile .statistics__left [class^="statistic__row--"] {
	display: grid;
	grid-template-columns: auto repeat(3, 120px);
	grid-template-rows: 32px;
	grid-template-areas:
		"area-1 area-2 area-3 area-4";
	align-items: center;
}

.page__profile .statistics__left [class^="statistic__row--"] > :nth-child(1) { grid-area: area-1; }   /* Объект БП. */
.page__profile .statistics__left [class^="statistic__row--"] > :nth-child(2) { grid-area: area-2; }   /* Всего. */
.page__profile .statistics__left [class^="statistic__row--"] > :nth-child(3) { grid-area: area-3; }   /* В работе. */
.page__profile .statistics__left [class^="statistic__row--"] > :nth-child(4) { grid-area: area-4; }   /* Завершено. */

.page__profile .statistics__left [class^="statistic__row--"] > :nth-child(1) {
	justify-self: start;
}

.page__profile .statistics__left [class^="statistic__row--"] > :nth-child(n+2) {
	justify-self: center;
}

/*----------------------/
  Правая сторона (верх)
/----------------------*/

/*--- Строки ---*/

/* Компоновка. */
.page__profile .statistics__right-top [class^="statistic__row--"] {
	display: grid;
	grid-template-columns: auto repeat(5, 90px);
	grid-template-rows: 32px;
	grid-template-areas:
		"area-1 area-2 area-3 area-4 area-5 area-6";
	align-items: center;
}

.page__profile .statistics__right-top [class^="statistic__row--"] > :nth-child(1) { grid-area: area-1; }   /* Объект БП. */
.page__profile .statistics__right-top [class^="statistic__row--"] > :nth-child(2) { grid-area: area-2; }   /* Всего. */
.page__profile .statistics__right-top [class^="statistic__row--"] > :nth-child(3) { grid-area: area-3; }   /* Инициатор. */
.page__profile .statistics__right-top [class^="statistic__row--"] > :nth-child(4) { grid-area: area-4; }   /* Ответчик. */
.page__profile .statistics__right-top [class^="statistic__row--"] > :nth-child(5) { grid-area: area-5; }   /* Выиграно. */
.page__profile .statistics__right-top [class^="statistic__row--"] > :nth-child(6) { grid-area: area-6; }   /* Проиграно. */

.page__profile .statistics__right-top [class^="statistic__row--"] > :nth-child(1) {
	justify-self: start;
}

.page__profile .statistics__right-top [class^="statistic__row--"] > :nth-child(n+2) {
	justify-self: center;
}

/*---------------------/
  Правая сторона (низ)
/---------------------*/

.page__profile .statistics__right-bottom {
	margin-top: 24px;
	margin-bottom: 12px;
	border: solid 1px var(--back-nav);
	background: var(--gray-93);
}

.page__profile .statistics__right-bottom {
	display: grid;
	/* grid-template-columns: auto; */
	grid-template-rows: 26px 14px 56px;
	grid-template-areas:
		"area-1"
		"area-2"
		"area-3";
	justify-items: center;
}

.page__profile .statistics__right-bottom > :nth-child(1) { grid-area: area-1; }   /* «Рейтинг». */
.page__profile .statistics__right-bottom > :nth-child(2) { grid-area: area-2; }   /* Подзаголовок. */
.page__profile .statistics__right-bottom > :nth-child(3) { grid-area: area-3; }   /* Рейтинг. */

.page__profile .statistics__right-bottom > :nth-child(1) {
	align-self: end;
	font-family: 'bold';
	color: var(--text-head);
}

.page__profile .statistics__right-bottom > :nth-child(2) {
	align-self: start;
	font-family: 'thin-italic';
	font-size: 12px;
}

.page__profile .statistics__right-bottom > :nth-child(3) {
	align-self: center;
	border-style: solid;
	border-width: 1px;
	border-radius: 50%;
	padding: 7px 6px;
	font-size: 16px;
}

/*--- Рейтинг ---*/

/* Число: Рейтинг «высокий». */
.page__profile .statistic__rating--high .statistics__right-bottom > :nth-child(3) {
	border-color: var(--green24_51);
	background: var(--green24_89);
	color: var(--green24_51);
}

/* Число: Рейтинг «средний». */
.page__profile .statistic__rating--average .statistics__right-bottom > :nth-child(3) {
	border-color: var(--blue24_51);
	background: var(--blue24_89);
	color: var(--blue24_51);
}

/* Число: Рейтинг «низкий». */
.page__profile .statistic__rating--low .statistics__right-bottom > :nth-child(3) {
	border-color: var(--red24_51);
	background: var(--red24_89);
	color: var(--red24_51);
}

.page__error-message{
	color: red;
	grid-area: main;
}

.page__loading-message{
	color: gray;
	grid-area: main;
}