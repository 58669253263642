/*
	Общие стили персонализированных страниц вне зависимости от роли.

	Ширина контента 1240px (без прокрутки для монитора с шириной разрешения 1280px).
	Общая ширина колонок рабочих областей =1240px (рабочие области — фиксированные).
	Боковые нерабочие области (неопределённые «.») =auto.
	Это выравнивает «main» по центру и компенсируют изменение ширины окна браузера.
*/

/*=======================/
  Каркас страницы (#root)
/=======================*/

/*
	Компоновка основных областей страницы.
	Экранное представление страницы разбито на три ключевые «строки»:
		header   — верхняя панель кнопок (вкладок), «липкая»;
		main     — основное содержание, изменяемая высота =auto;
		footer   — нижняя панель ссылок и информации, «липкая».
*/

#root[class*="role__"] {
  display: grid;
  grid-template-columns: auto 1240px auto;
  grid-template-rows: 94px auto 42px;
  grid-template-areas:
    "header header header"
    ".      main   .     "
    "footer footer footer";
}

#root[class*="role__"] > header {
  grid-area: header;
}
#root[class*="role__"] > main {
  grid-area: main;
}
#root[class*="role__"] > footer {
  grid-area: footer;
}

/*=======================================/
  Верхняя панель кнопок-вкладок (header)
/=======================================*/

/* Непрокручиваемая область, прилипание к верху окна браузера. */

/*--------------------------/
  Контейнер областей панели
/--------------------------*/

/*--- Компоновка ---*/

#root[class*="role__"] > header {
  display: grid;
  grid-template-columns: auto 970px 10px 260px auto; /* =1240px */
  grid-template-rows: 76px;
  grid-template-areas: ". child-1 child-2 child-3 .";
  align-items: center;
}

#root[class*="role__"] > header > :nth-child(1) {
  grid-area: child-1;
} /* Кнопки-вкладки. */
#root[class*="role__"] > header > :nth-child(2) {
  grid-area: child-2;
} /* Разделитель. */
#root[class*="role__"] > header > :nth-child(3) {
  grid-area: child-3;
} /* Пользователь. */

/*--- Позиционарование ---*/

#root[class*="role__"] > header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100; /* Перекрываем элементы «въезжающие» под панель. */
  height: 76px; /* Высота панели; высота области grid`а в layout. */
  background: var(--back-nav);
}

/*-----------------------/
  Область кнопок-вкладок
/-----------------------*/

/*--- Места под вкладки-кнопки ---*/

/* Зарезервировано 6 мест. */
#root[class*="role__"] > header > nav {
  display: grid;
  grid-template-columns: repeat(6, 150px) 70px; /* <=970px (ширина области). */
  grid-template-areas: "child-1 child-2 child-3 child-4 child-5 child-6 .";
  align-items: center;
}

#root[class*="role__"] > header > nav > :nth-child(1) {
  grid-area: child-1;
} /* Кнопка-вкладка 1. */
#root[class*="role__"] > header > nav > :nth-child(2) {
  grid-area: child-2;
} /* Кнопка-вкладка 2. */
#root[class*="role__"] > header > nav > :nth-child(3) {
  grid-area: child-3;
} /* Кнопка-вкладка 3. */
#root[class*="role__"] > header > nav > :nth-child(4) {
  grid-area: child-4;
} /* Кнопка-вкладка 4. */
#root[class*="role__"] > header > nav > :nth-child(5) {
  grid-area: child-5;
} /* Кнопка-вкладка 5. */
#root[class*="role__"] > header > nav > :nth-child(6) {
  grid-area: child-6;
} /* Кнопка-вкладка 6. */

/*--- Каркас вкладки — ссылка ---*/

a.nav__tab-box {
  position: relative; /* Для позиционирования индикатора. */
  width: 108px;
  height: 36px;
  background: var(--text-head);
  padding-top: 8px;
  padding-left: 8px;

  transition: all 0.3s linear;
}

a.nav__tab-box:hover {
  border: none;
}

a.nav__tab-box:not(.current):hover {
  background: var(--back__tab-hover);
}

/*--- Текущая вкладка ---*/

.nav__tab-box.current {
  background: var(--back);
}

/*--- Имя вкладки ---*/

.nav__tab-box > :nth-child(1) {
  color: var(--back);
  font-size: 16px;
}

/* Имя текущей вкладки. */
.nav__tab-box.current > :nth-child(1) {
  color: var(--text-head);
}

/*--- Индикатор вкладки ---*/

.nav__tab-box > :nth-child(2) {
  display: block;
  position: absolute;
  top: -10px;
  right: -10px;
  min-width: 20px;
  height: 20px;
  border-bottom: solid 1px;
  border-top: solid 1px;
  border-radius: 6px;
  background: var(--back-nav);
  text-align: center;
  padding: 0 1px;
  color: var(--back);
  font-family: "thin";
  font-size: 12px;
}

/*------------/
  Разделитель
/------------*/

#root[class*="role__"] > header > :nth-child(2) {
  width: 4px;
  height: 56px;
  background: var(--back);
}

/*----------------------------/
  Область данных Пользователя
/----------------------------*/

#root[class*="role__"] .top-bar__user--box {
  display: grid;
  grid-template-columns: 260px;
  grid-template-rows: 19px 27px 18px;
  grid-template-areas:
    "child-1"
    "child-2"
    "child-3";
  align-items: center;
  justify-self: end; /* Вся область вправо. */
  justify-items: end; /* Все вложенные области вправо. */
}

#root[class*="role__"] .top-bar__user--box > :nth-child(1) {
  grid-area: child-1;
  align-self: start;
}
#root[class*="role__"] .top-bar__user--box > :nth-child(2) {
  grid-area: child-2;
  align-self: start;
} /* ФИО. */
#root[class*="role__"] .top-bar__user--box > :nth-child(3) {
  grid-area: child-3;
}

/*--- Позиционирование и анимация ---*/

.top-bar__user--box {
  position: relative;
  color: var(--back);

  transition: all 0.3s linear;
}

/*--- Стрелка (у Разделителя) ---*/

.top-bar__user--box::before {
  content: "";
  position: absolute;
  top: 6px;
  left: -4px;
  border-style: solid;
  border-width: 26px 0;
  border-color: transparent transparent transparent var(--back);

  transition: all 0.3s linear;
}

#root[class*="page__profile"] .top-bar__user--box::before,
.top-bar__user--box:hover::before {
  border-width: 26px 0 26px 16px;
  border-left-color: var(--user-box);
}

/*--- Верхняя/Нижняя строка ---*/

.top-bar__user--box > :nth-child(1),
.top-bar__user--box > :nth-child(3) {
  font-family: "thin";
  font-size: 12px;
}

/*--- Средняя строка: Имя пользователя (ссылка) ---*/

.top-bar__user--box > :nth-child(2) {
  border-bottom: dotted 1px;
  padding-bottom: 4px;
  font-size: 19px; /* Равно размеру текста вкладок. */
  color: var(--link-normal-light);

  transition: all 0.3s linear;
}

/*--- Вид: Обычный/Наведение/Нажатие ---*/

/* Стили страницы Профиля статичны. */

#root[class*="page__profile"] .top-bar__user--box > :nth-child(2),
#root[class*="role__"] .top-bar__user--box:hover > :nth-child(2):hover,
.top-bar__user--box:hover > :nth-child(2) {
  color: var(--user-box);
  border-bottom-style: solid;
}

#root[class*="page__profile"] .top-bar__user--box:hover > :nth-child(2):hover {
  /*cursor: not-allowed;*/
}

#root[class*="role__"]:not(#root[class*="page__profile"])
  .top-bar__user--box:hover
  > :nth-child(2):hover {
  color: var(--link-hover);
}
#root[class*="role__"]:not(#root[class*="page__profile"])
  .top-bar__user--box:hover
  > :nth-child(2):active {
  color: var(--link-active);
}

/*--- Выравнивание в нижней строке ---*/

.top-bar__user--box > :nth-child(3) {
  display: flex;
  flex-direction: row;
}

.top-bar__user--box > :nth-child(3) .symbol__colon--after {
  margin-right: 4px;
}
.top-bar__user--box > :nth-child(3) .symbol__vertical--before {
  margin-right: 10px;
  margin-left: 10px;
}

/*=========================/
  Подвал страницы (footer)
/=========================*/

/* Непрокручиваемая область, прилипание к низу окна браузера. */

/*--- Компоновка ---*/

#root[class*="role__"] > footer {
  display: grid;
  grid-template-columns: auto 412px 416px 412px auto; /* =1240px */
  grid-template-areas: ". child-1 child-2 child-3 .";
  align-items: center;
}

#root[class*="role__"] > footer > :nth-child(1) {
  grid-area: child-1;
  justify-self: start;
} /* Помощь. */
#root[class*="role__"] > footer > :nth-child(2) {
  grid-area: child-2;
  justify-self: center;
} /* Информация. */
/*#root > footer > :nth-child(3){
  display: none;
}*/
#root[class*="role__"] > footer > :nth-child(3) {
  grid-area: child-3;
  justify-self: end;
} /* Выход. */

/*--- Позиционарование ---*/
/*[class*="role__"]*/
#root > footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100; /* Перекрываем элементы «въезжающие» под панель. */
  height: 43px; /* Высота панели; высота области grid`а определена в layout. */
  background: var(--back-nav);
  color: var(--back);
}

/*--- Ссылки в футере ---*/
/*[class*="role__"] */
#root > footer a {
  border-bottom: dotted 1px;
}

#root[class*="role__"] > footer a:hover {
  border-bottom-style: solid;
}

/*--- Выравнивание в области информации ---*/

#root[class*="role__"] > footer > :nth-child(2) {
  display: flex;
  flex-direction: row;
}

#root[class*="role__"] > footer .symbol__colon--after {
  margin-right: 6px;
}
#root[class*="role__"] > footer .symbol__vertical--before {
  margin-right: 12px;
  margin-left: 12px;
}

/*===================/
  Отдельные элементы
/===================*/

/*--- Кнопка-переключатель (треугольник в круге) ---*/

/* Вывод треугольника: угол вниз/вверх. */
span.circle-triangle::before,
.open span.circle-triangle::after {
  content: "";
}
.open span.circle-triangle::before {
  content: none;
}

/*=================================/
  Статусы объектов бизнес-процесса
/=================================*/

/* Для переноса слов во псевдоэлементе. */
[class*="status__"] [class*="text__status"]::before {
  white-space: pre;
}

/*------/
  Наряд
/------*/

.status__order--formed .text__status--order::before {
  content: "Сформирован";
}
.status__order--adjust .text__status--order::before {
  content: "Замечания" "\000A""по наряду";
}
.status__order--accept .text__status--order::before {
  content: "Утверждён";
}
.status__order--overdue-launch .text__status--order::before {
  content: "Просрочен" "\000A""запуск";
}
.status__order--launch .text__status--order::before {
  content: "К запуску";
}
.status__order--overdue-brigade .text__status--order::before {
  content: "Бригада" "\000A""не подобрана";
}
.status__order--offer .text__status--order::before {
  content: "Оферта";
}
.status__order--prepaid .text__status--order::before {
  content: "Предоплачен";
}
.status__order--work .text__status--order::before {
  content: "В работе";
}
.status__order--complaint .text__status--order::before {
  content: "Спор";
}
.status__order--break .text__status--order::before {
  content: "Расторгнут";
}
.status__order--overdue-done .text__status--order::before {
  content: "Просрочена" "\000A""сдача";
}
.status__order--work-done .text__status--order::before {
  content: "Сдача работы";
}
.status__order--work-repair .text__status--order::before {
  content: "Исправление" "\000A""замечаний";
}
.status__order--work-accept .text__status--order::before {
  content: "Работы" "\000A""приняты";
}
.status__order--close .text__status--order::before {
  content: "Закрыт";
}

/*-----/
  Спор
/-----*/

.status__complaint--initiate .text__status--complaint::before {
  content: "Начало спора";
}
.status__complaint--start .text__status--complaint::before {
  content: "Открыт";
}
.status__complaint--respond .text__status--complaint::before {
  content: "Дан ответ";
}
.status__complaint--end .text__status--complaint::before {
  content: "Закрыт";
}
.status__complaint--restart .text__status--complaint::before {
  content: "Возобновлён";
}
.status__complaint--arbitrate .text__status--complaint::before {
  content: "Арбитраж";
}
.status__complaint--query .text__status--complaint::before {
  content: "Запрос от арбитра";
}
.status__complaint--party-respond .text__status--complaint::before {
  content: "Дан ответ арбитру";
}
.status__complaint--stop .text__status--complaint::before {
  content: "Приостановка работ";
}
.status__complaint--overrule .text__status--complaint::before {
  content: "Отклонён";
}
.status__complaint--satisfied .text__status--complaint::before {
  content: "Удовлетворён";
}

/*-------------------/
  Заголовок страницы
/-------------------*/

main > h2 {
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: 30px;
	grid-template-areas:
		"area-1 area-2";
	line-height: 18px;   /* Влияет на отступ сверху. */
}

main > h2 > :nth-child(1) { grid-area: area-1; }   /* Название. */
main > h2 > :nth-child(2) { grid-area: area-2; justify-self: end; }   /* Роль. */

/* Карточки объектов. */
.page__complaint--card .text__page--name::before { content: "Карточка спора"; }
/* Карточки объектов при создании. */
.page__complaint--card.status__complaint--initiate .text__page--name::after { content: "\003a\2002создание"; }

/* Списки объектов. */
.page__complaint--list .text__page--name::before  { content: "Список споров"; }
.page__brigade--list .text__page--name::before  { content: "Список бригад"; }

/*--- Роли ---*/

main > h2 .text__role--name {
	font-family: 'thin-italic';
	font-size: 12px;
}
.role__brigadier .text__role--name::before  { content: "Бригадир"; }
.role__contractor .text__role--name::before { content: "Подрядчик"; }
.role__arbitrate .text__role--name::before  { content: "Арбитр"; }
.role__foreman .text__role--name::before    { content: "Прораб"; }
.role__operator .text__role--name::before   { content: "Администратор"; }