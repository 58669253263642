.captions {
  position: sticky;
  z-index: 1;      /* Перекрываем прокручиваемое содержание. */
  top: 76px;
  margin: 0 12px 6px;
  border-top: solid 1px var(--back);
  border-bottom: solid 1px var(--back);
  background: var(--back__tab-hover);
  font-family: 'thin';
  line-height: 16px;
  color: var(--back);
  display: grid;
  grid-template-columns: 12px auto 160px repeat(4, 140px) 12px;
  grid-template-rows: 42px;
  grid-template-areas:
		". area-1 area-2 area-3 area-4 area-5 area-6 .";
  align-items: center;

  & > div {
    text-align: center;
  }

  & > *:nth-child(1) { grid-area: area-1; }   /* Объект. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Дата. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Оплата Платформе. */
  & > *:nth-child(4) { grid-area: area-4; }   /* Оплата Бригады. */
  & > *:nth-child(5) { grid-area: area-5; }   /* Акт Платформа. */
  & > *:nth-child(6) { grid-area: area-6; }   /* Акт Бригада. */

  /* Объект, Дата. */
  & > *:nth-child(1),
  & > *:nth-child(2) {
    justify-self: center;
  }

  /* Колонки с числами. */
  & > *:nth-child(n+3) {
    justify-self: end;
  }
}