.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: hsla(0, 0%, 17%, 0.6);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.4s, opacity 0.4s;

  &::-webkit-scrollbar {
    display: none;
  }

  &.isOpen {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s 0s, opacity 0.4s;
  }

  &Wrapper {
    width: auto;
    max-height: 100%;

    &::before,
    &::after {
      display: block;
      height: 24px;
      content: "";
    }
  }

  &Window {
    margin-bottom: 126px;
    box-shadow: var(--shadow-modal);
    background: var(--back);
  }

  &Title {
    width: 100%;
    background: var(--back-nav);
    padding-top: 6px;
    padding-bottom: 7px;
    text-align: center;
    color: var(--back);
    text-shadow: var(--shadow-contour);
  }
}