/*==================================================/
  Страница Карточки разбивки (.page__jobplan--card)
/==================================================*/

/*------------------------/
  Панели данных Разбивки
/------------------------*/

/*
	Родительские данные Разбивки и Заголовок Разбивки.
	При прокрутке прижимаются к верхней панели, но не прокручиваются.
*/

/*--- Позиционирование ---*/

.page__jobplan--card .jobplan__parent-data,
.page__jobplan--card .jobplan__heading {
  position: sticky;
  z-index: 1; /* Перекрываем прокручиваемое содержание. */
  width: 1240px; /* Ширина контента. */
  border-top: solid 1px var(--back);
  border-bottom: solid 1px var(--back);
  color: var(--back);
}

/*-----------------------------/
  Родительские данные Разбивки
/-----------------------------*/

/*--- Компоновка ---*/

.page__jobplan--card .jobplan__parent-data {
  display: grid;
  grid-template-columns: repeat(3, 348px) auto;
  grid-column-gap: 8px;
  grid-template-rows: 24px 22px;
  grid-template-areas:
    "child-1 child-3 child-5 child-7"
    "child-2 child-4 child-6 child-7";
  /* align-items: center; */
}

.page__jobplan--card .jobplan__parent-data > :nth-child(1) {
  grid-area: child-1;
  align-self: end;
} /* Объект. */
.page__jobplan--card .jobplan__parent-data > :nth-child(2) {
  grid-area: child-2;
  align-self: start;
} /* Адрес. */
.page__jobplan--card .jobplan__parent-data > :nth-child(3) {
  grid-area: child-3;
  align-self: end;
} /* Смета. */
.page__jobplan--card .jobplan__parent-data > :nth-child(4) {
  grid-area: child-4;
  align-self: start;
} /* Примечание Сметы. */
.page__jobplan--card .jobplan__parent-data > :nth-child(5) {
  grid-area: child-5;
  align-self: end;
} /* Подрядчик: Организация. */
.page__jobplan--card .jobplan__parent-data > :nth-child(6) {
  grid-area: child-6;
  align-self: start;
} /* Подрядчик: Данные. */
.page__jobplan--card .jobplan__parent-data > :nth-child(7) {
  grid-area: child-7;
} /* Даты. */

/*--- Подрядчик: Данные ---*/

.page__jobplan--card .jobplan__parent-data > :nth-child(6) {
  display: grid;
  grid-template-columns: 120px auto;
  grid-template-areas: "child-1 child-2";
  align-items: start;
}

.page__jobplan--card .jobplan__parent-data > :nth-child(6) > :nth-child(1) {
  grid-area: child-1;
} /* Телефон. */
.page__jobplan--card .jobplan__parent-data > :nth-child(6) > :nth-child(2) {
  grid-area: child-2;
} /* Имя Подрядчика. */

/*--- Даты. ---*/

.jobplan__parent-data > :nth-child(7) {
  display: grid;
  grid-template-columns: 70px auto 70px;
  grid-template-areas:
    "child-1 child-1 child-1"
    "child-2 child-3 child-4";
}

.page__jobplan--card .jobplan__parent-data > :nth-child(7) > :nth-child(1) {
  grid-area: child-1;
  align-self: end;
  justify-self: center;
} /* Надпись. */
.page__jobplan--card .jobplan__parent-data > :nth-child(7) > :nth-child(2) {
  grid-area: child-2;
  align-self: start;
  justify-self: end;
} /* Дата. */
.page__jobplan--card .jobplan__parent-data > :nth-child(7) > :nth-child(3) {
  grid-area: child-3;
  align-self: start;
  justify-self: center;
} /* Тире. */
.page__jobplan--card .jobplan__parent-data > :nth-child(7) > :nth-child(4) {
  grid-area: child-4;
  align-self: start;
  justify-self: start;
} /* Дата. */

/*--- Панель ---*/

.page__jobplan--card .jobplan__parent-data {
  top: 76px;
  margin-top: -2px;
  margin-bottom: 16px;
  background: var(--back__tab-hover);
  padding-left: 6px;
}

/*--- Черта под ссылкой ---*/

.page__jobplan--card .jobplan__parent-data .link-light {
  border-bottom: dotted 1px;
}

/* Иначе черта не видна (overflow: hidden). */
.page__jobplan--card .jobplan__parent-data > :nth-child(1),
.page__jobplan--card .jobplan__parent-data > :nth-child(3),
.page__jobplan--card .jobplan__parent-data > :nth-child(5) {
  padding-bottom: 1px;
}

/*--- Шрифты ---*/

.page__jobplan--card .jobplan__parent-data > :nth-child(2),
.page__jobplan--card .jobplan__parent-data > :nth-child(4),
.page__jobplan--card .jobplan__parent-data > :nth-child(6),
.page__jobplan--card .jobplan__parent-data > :nth-child(7) > :nth-child(1) {
  font-family: "thin";
}

.page__jobplan--card .jobplan__parent-data > :nth-child(2),
.page__jobplan--card .jobplan__parent-data > :nth-child(4),
.page__jobplan--card .jobplan__parent-data > :nth-child(6) {
  font-size: 12px;
}

/*-------------------/
  Заголовок Разбивки
/-------------------*/

/*--- Компоновка ---*/

.page__jobplan--card .jobplan__heading {
  display: grid;
  grid-template-columns: 70px 276px 153px 95px 70px auto 70px;
  column-gap: 8px;
  grid-template-rows: 36px;
  grid-template-areas: "child-1 child-2 child-3 child-4 child-5 child-6 child-7";
  align-items: center;
}

.page__jobplan--card .jobplan__heading > :nth-child(1) {
  grid-area: child-1;
  justify-self: end;
} /* «Разбивка». */
.page__jobplan--card .jobplan__heading > :nth-child(2) {
  grid-area: child-2;
  justify-self: center;
} /* Статус. */
.page__jobplan--card .jobplan__heading > :nth-child(3) {
  grid-area: child-3;
} /* Кнопка «Новая Цепочка». */
.page__jobplan--card .jobplan__heading > :nth-child(4) {
  grid-area: child-4;
  justify-self: end;
} /* Сумма. */
.page__jobplan--card .jobplan__heading > :nth-child(5) {
  grid-area: child-5;
  justify-self: center;
} /* Дата (Старт). */
.page__jobplan--card .jobplan__heading > :nth-child(6) {
  grid-area: child-6;
  justify-self: center;
} /* Область под кнопки. */
.page__jobplan--card .jobplan__heading > :nth-child(7) {
  grid-area: child-7;
  justify-self: center;
} /* Дата (Финиш). */

/*--- Панель заголовка ---*/

.page__jobplan--card .jobplan__heading {
  top: 121px; /* = сумма данных Разбивки: 76 (top) + height */
  border-bottom: solid 2px var(--back);
  background: var(--text-head);
}

/*--- Статус (child-2) ---*/

.page__jobplan--card .jobplan__heading > select {
  width: 160px;
  height: 20px;
  padding-left: 0;
  text-align: center;
  font-size: 12px;
}

/* Статус «Замечания по разбивке». */
.page__jobplan--card.status__jobplan--adjust .jobplan__heading > select {
  border-color: var(--error-dark);
  background: var(--error-light);
  color: var(--error-dark);
}

/*--- Ссылка и Кнопки (child-3) ---*/

/*
	Ссылка «История…» выводится только в режиме просмотра.
	В режиме редактирования ссылка скрывается и выводится кнопка:
		«Отправить»     — в статусе «Разбивка выполнена» (выбран статус «Замечания по разбивке»);
		«Новая цепочка» — в статусе «Разбивка утверждена».
*/

/* Ссылка. */
.page__jobplan--card .jobplan__heading > :nth-child(3) a {
  display: inline-block; /* Для выравнивания. */
  margin-bottom: 4px;
  border-bottom: dotted 1px;
  font-family: "thin";
  font-size: 12px;
}
/* Ссылка скрывается при редактировании вне зависимости от статуса. */
.page__jobplan--card.mode__edit .jobplan__heading > :nth-child(3) a {
  display: none;
}

/* Кнопки, в обычном режиме не выводятся. */
.page__jobplan--card .jobplan__heading > :nth-child(3) button {
  display: none;
}

/*
	Кнопка «Отправить», выводится в режиме редактирования
	при статусе «Разбивка выполнена».
*/
.page__jobplan--card.status__jobplan--done.mode__edit
  [name="control__submit--adjust"] {
  display: flex; /* flex — исходное значение. */
}

/*
	Кнопка «Новая Цепочка», выводится в режиме редактирования
	при статусе «Разбивка утверждена».
*/
.page__jobplan--card.status__jobplan--accept.mode__edit
  [name="control__chain--creating"] {
  display: flex; /* flex — исходное значение. */
}

/*--- Сумма (child-4) ---*/

.page__jobplan--card .jobplan__heading > :nth-child(4) {
  font-family: "bold";
}

/*--- Даты (child-5 / child-7) ---*/

.page__jobplan--card .jobplan__heading > :nth-child(5),
.page__jobplan--card .jobplan__heading > :nth-child(7) {
  font-family: "thin";
}

/*--- Кнопки (child-6) ---*/

/* Контейнер кнопок. */
.page__jobplan--card .jobplan__heading > :nth-child(6) {
  display: flex;
  gap: 20px;
}

/* Кнопки не выводятся. */
.page__jobplan--card [name="control__changes--save"],
.page__jobplan--card [name="control__mode--read"],
.page__jobplan--card [name="control__dates--change"] {
  display: none;
}

/*
	Кнопки выводятся только при статусе «Разбивка утверждена»:
		«Сохранить» (changes--save) и «Выйти» (mode--read) в режиме редактирования;
		«Изменение дат…» (dates--change) в обычном режиме.
*/
.page__jobplan--card.status__jobplan--accept.mode__edit
  [name="control__changes--save"],
.page__jobplan--card.status__jobplan--accept.mode__edit
  [name="control__mode--read"],
.page__jobplan--card.status__jobplan--accept:not(.mode__edit)
  [name="control__dates--change"] {
  display: flex; /* flex — исходное значение. */
}

/*--------/
  Цепочка
/--------*/

/*--- Компоновка ---*/

.page__jobplan--card .chain__line {
  display: grid;
  grid-template-columns: 24px auto 90px 90px 612px;
  column-gap: 8px;
  grid-template-rows: auto;
  grid-template-areas: "child-1 child-2 child-3 child-4 child-5";
  align-items: center;
}

.page__jobplan--card .chain__line > :nth-child(1) {
  grid-area: child-1;
  justify-self: end;
} /* Переключатель. */
.page__jobplan--card .chain__line > :nth-child(2) {
  grid-area: child-2;
} /* Название. */
.page__jobplan--card .chain__line > :nth-child(3) {
  grid-area: child-3;
  justify-self: center;
} /* Статус. */
.page__jobplan--card .chain__line > :nth-child(4) {
  grid-area: child-4;
  justify-self: end;
} /* Сумма. */
.page__jobplan--card .chain__line > :nth-child(5) {
  grid-area: child-5;
} /* Диаграмма. */

/*--- Цепочка: Контейнер ---*/

.page__jobplan--card .jobplan__heading + .chain__box {
  margin-top: 8px; /* Расстояние между Заголовком и первой Цепочкой. */
}

.page__jobplan--card .chain__box {
  margin-bottom: 8px; /* Расстояние между Цепочками. */
  background: var(--back-main);
  padding: 3px 1px;
  border: solid 1px var(--gray-67);
}

/* Ширина всех кнопок в боксе Цепочки. */
.page__jobplan--card .chain__box button {
  width: 86px;
}

/*--- Цепочка: Строка ---*/

/* Высота строки. */
.page__jobplan--card .chain__line {
  padding-top: 1px; /* Влияет на выравнивание Переключателя. */
  padding-bottom: 1px; /* Влияет на расстояние до строки Наряда. */
}

/* Есть Наряд(ы) в статусе «К запуску». */
.page__jobplan--card .chain__order--launch .chain__line {
  background: var(--back-order-launch);
}

/* Есть Наряд(ы) в статусе «Замечания» / «Просрочен». */
.page__jobplan--card .chain__adjust .chain__line,
.page__jobplan--card .chain__overdue .chain__line {
  background: var(--back-order-overdue);
}

/*--- Переключатель ---*/

/* Родитель, несущий элемент Переключателя. */
.page__jobplan--card .chain__line > :nth-child(1) {
  padding-top: 4px; /* Влияет на высоту строки и выравнивание Переключателя. */
}

/*--- Состояние Цепочки (состояние Нарядов) ---*/

.page__jobplan--card .chain__line .chain__line--status::before {
  display: inline-block;
  font-family: "thin";
}

/* !!! Когда и что выводить не определено. */

/*
	Состояние «Замечания».
	Только когда в Цепочке есть Наряд(ы) с замечаниями.
	Только если Разбивка в статусах:
		«Разбивка выполнена» в режиме редактирования
		(в поле статуса выбран пункт «Замечания по разбивке»);
		«Замечания по разбивке» (доступен только просмотр).
*/
.page__jobplan--card .chain__adjust .chain__line--status::before {
  content: "Замечания";
  color: var(--error-dark);
}

/* Меняем цвет при выделении строки. */
.page__jobplan--card
  .chain__adjust
  .chain__line.highlighted
  .chain__line--status::before {
  color: var(--error-light-2);
}

/*
	Состояние «Просрочено».
	Только когда в Цепочке есть просроченный(е) Наряд(ы).
*/
.page__jobplan--card .chain__overdue .chain__line--status::before {
  content: "Просрочено";
  color: var(--error-dark);
}

/* Меняем цвет при выделении строки. */
.page__jobplan--card
  .chain__overdue
  .chain__line.highlighted
  .chain__line--status::before {
  color: var(--error-light-2);
}

/*
	Состояние «К запуску».
	Только когда в Цепочке есть Наряд(ы) со статусом «К запуску».
*/
.page__jobplan--card .chain__order--launch .chain__line--status::before {
  content: "К запуску";
  color: var(--error-dark);
}

/* Меняем цвет при выделении строки. */
.page__jobplan--card
  .chain__order--launch
  .chain__line.highlighted
  .chain__line--status::before {
  color: var(--error-light-2);
}

/*--- Кнопка «Запустить» ---*/

/*
	Доступна при статусе «Разбивка утвержедена»,
	только в режиме просмотра,
	только если в Цепочке нет запущенных Нарядов.
*/

/* Не выводим (исключения). */
.page__jobplan--card [name="control__chain--run"],
.page__jobplan--card.mode__edit .chain__box [name="control__chain--run"],
.page__jobplan--card
  .chain__box[class*="chain__run--"]
  [name="control__chain--run"] {
  display: none;
}

/* Выводим, если не попадает под исключения. */
.page__jobplan--card.status__jobplan--accept [name="control__chain--run"] {
  display: flex; /* flex — исходное значение. */
  margin-top: 1px;
  margin-bottom: 2px;
}

/*--- Диаграмма Цепочки ---*/

/* Вся область диаграммы в строке Цепочки. */
.page__jobplan--card .graph__box--chain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: 36px;
}

/* Блок дат. */
.page__jobplan--card .graph__box--chain > :nth-child(1) {
  display: flex;
  justify-content: center;
  font-family: "thin";
  font-size: 12px;
}

.page__jobplan--card .graph__box--chain .symbol__mdash--after::after {
  padding-right: 4px;
  padding-left: 4px;
}

/*
	Дата начала в <span>, выводится:
	(1)	в режиме просмотра;
	(2)	в режиме редактирования при статусе «Разбивка утверждена»,
		если в Цепочке запущены Наряды:
			хотя бы один: chain__run--order;
			все: chain__run--all.
*/
.page__jobplan--card .graph__box--chain > :nth-child(1) > :nth-child(1),
.page__jobplan--card.status__jobplan--accept.mode__edit
  .chain__run--order
  .graph__box--chain
  > :nth-child(1)
  > :nth-child(1),
.page__jobplan--card.status__jobplan--accept.mode__edit
  .chain__run--all
  .graph__box--chain
  > :nth-child(1)
  > :nth-child(1) {
  display: block;
}

/*
	Дата начала; не выводится:
		<span>
			в режиме редактирования при статусе «Разбивка утверждена»,
			только если в Цепочке есть незапущенные Наряды;
		<input>
			в режиме просмотра;
			в режиме редактирования при статусе «Разбивка утверждена»,
			если в Цепочке запущены Наряды:
				хотя бы один: chain__run--order;
				все: chain__run--all.
*/
.page__jobplan--card.status__jobplan--accept.mode__edit
  .graph__box--chain
  > :nth-child(1)
  > :nth-child(1),
.page__jobplan--card [name="input__chain--date-start"],
.page__jobplan--card.status__jobplan--accept.mode__edit
  .chain__run--order
  [name="input__chain--date-start"],
.page__jobplan--card.status__jobplan--accept.mode__edit
  .chain__run--all
  [name="input__chain--date-start"] {
  display: none;
}

/*
	Поле даты <input> выводится только в статусе «Разбивка утверждена»
	в режиме редактирования при условии, что не запущена
	ни сама Цепочка ни Наряд(ы) в ней.
*/
.page__jobplan--card.status__jobplan--accept.mode__edit
  [name="input__chain--date-start"] {
  display: flex; /* flex — исходное значение. */
  width: 92px;
  height: 18px;
  margin-bottom: 2px;
}

/* Полоса диаграммы. */
.page__jobplan--card .graph__box--chain > :nth-child(2) {
  height: 14px;
  background: var(--back-chain-graph);
}

/*------/
  Наряд
/------*/

/*--- Компоновка ---*/

.page__jobplan--card .order__line {
  display: grid;
  grid-template-columns: 24px auto 140px 76px 612px;
  column-gap: 8px;
  grid-template-rows: max-content;
  grid-template-areas: "child-1 child-2 child-3 child-4 child-5";
  align-items: center;
}

.page__jobplan--card .order__line > :nth-child(1) {
  grid-area: child-1;
  justify-self: end;
} /* Переключатель. */
.page__jobplan--card .order__line > :nth-child(2) {
  grid-area: child-2;
} /* Название. */
.page__jobplan--card .order__line > :nth-child(3) {
  grid-area: child-3;
} /* Дни/Бригада/Статус. */
.page__jobplan--card .order__line > :nth-child(4) {
  grid-area: child-4;
  justify-self: end;
} /* Сумма. */
.page__jobplan--card .order__line > :nth-child(5) {
  grid-area: child-5;
} /* Диаграмма. */

/* Вложенный grid (дни/бригада). */

.page__jobplan--card .order__line--day_brigade {
  display: grid;
  grid-template-columns: 50px 90px; /* =140px */
  /* grid-template-rows: 0px 0px; */
  grid-template-areas:
    "child-1 child-2"
    "child-3 child-3";
}

.page__jobplan--card .order__line--day_brigade > :nth-child(1) {
  grid-area: child-1;
  align-self: end;
  justify-self: end;
} /* Дни. */
.page__jobplan--card .order__line--day_brigade > :nth-child(2) {
  grid-area: child-2;
  align-self: end;
  justify-self: center;
} /* Статус/Дни. */
.page__jobplan--card .order__line--day_brigade > :nth-child(3) {
  grid-area: child-3;
  align-self: start;
  justify-self: center;
} /* Бригада. */

/*--- Область Работ. ---*/

.page__jobplan--card .order__box--jobprices {
  /* display: grid; добавляется при раскрытии Наряда. */
  grid-template-columns: 580px auto 614px;
  grid-template-rows: max-content;
  grid-template-areas: "child-2 . child-1";
  align-items: center;
}

.page__jobplan--card .order__box--jobprices > :nth-child(1) {
  grid-area: child-1;
} /* Область поля замечаний. */
.page__jobplan--card .order__box--jobprices > :nth-child(2) {
  grid-area: child-2;
} /* Область списка Работ. */
/* Неименованная колонка — расстояние между областями. */

/*--- Наряд: Контейнер ---*/

/* Скрыт по умолчанию. */
.page__jobplan--card .chain__box--orders {
  display: none;
}

/* Выводится, если строка Цепочки раскрыта. */
.page__jobplan--card .chain__line.open + .chain__box--orders {
  display: block;
  margin-top: 4px; /* Расстояние от строки Цепочки; = расстоянию между строками Нарядов. */
}

/*--- Наряд: Строка ---*/

.page__jobplan--card .order__line {
  margin-left: 14px; /* Отодвигаем область под название Цепочки. */
  margin-bottom: 4px; /* Расстояние между строками Нарядов. */
  border: solid 1px var(--back-order-graph);
  background: var(--back-order-line);
}

/* Статус «К запуску». */
.page__jobplan--card .order__line.status__order--launch {
  background: var(--back-order-launch);
}

/* Статус «Замечания» / «Просрочен». */
.page__jobplan--card .order__line.status__order--adjust,
.page__jobplan--card .order__line.status__order--overdue {
  background: var(--back-order-overdue);
}

/*--- Наряд: Переключатель ---*/

.page__jobplan--card .order__line > :nth-child(1) > span {
  margin-top: 2px;
  margin-left: 6px;
}

/*--- Наряд: Название и Сумма ---*/

.page__jobplan--card .order__line > :nth-child(2),
.page__jobplan--card .order__line > :nth-child(4) {
  font-size: 13px;
}

/*--- Наряд: Название, кнопка «Запустить» ---*/

/*
	Доступна при статусе «Разбивка утвержедена»,
	только в режиме просмотра,
	только если Наряд не запущен.
*/

/* Не выводим (исключения). */
.page__jobplan--card [name="control__order--run"],
.page__jobplan--card .chain__box.chain__run--all [name="control__order--run"],
.page__jobplan--card .order__line.order__run [name="control__order--run"],
.page__jobplan--card.mode__edit
  .chain__box--orders
  [name="control__order--run"] {
  display: none;
}

/* Выводим, если не попадает под исключения. */
.page__jobplan--card.status__jobplan--accept [name="control__order--run"] {
  display: flex; /* flex — исходное значение. */
  margin-top: 1px;
  margin-bottom: 4px;
}

/*--- Наряд: Дни/Статус/Бригада (Вложенный grid) ---*/

.page__jobplan--card .order__line--day_brigade {
  margin-top: 2px;
  margin-bottom: 2px;
  font-family: "thin";
  font-size: 12px;
}

.page__jobplan--card .order__line--day_brigade > :nth-child(3) {
  margin-top: -2px;
}

/* Наряд в статусе «К запуску». */
.page__jobplan--card
  .status__order--launch
  .order__line--day_brigade
  > :nth-child(2)::before {
  content: "К запуску";
  color: var(--orange-site);
  font-family: "bold";
}

/* Наряд в статусе «Замечания» / «Просрочен». */
.page__jobplan--card
  .status__order--adjust
  .order__line--day_brigade
  > :nth-child(2)::before,
.page__jobplan--card
  .status__order--overdue
  .order__line--day_brigade
  > :nth-child(2)::before {
  color: var(--error-dark);
  font-family: "bold";
}

.page__jobplan--card
  .status__order--adjust.highlighted
  .order__line--day_brigade
  > :nth-child(2)::before,
.page__jobplan--card
  .status__order--overdue.highlighted
  .order__line--day_brigade
  > :nth-child(2)::before {
  color: var(--error-light-2);
}

/* Наряд в статусе «Замечания». */
.page__jobplan--card
  .status__order--adjust
  .order__line--day_brigade
  > :nth-child(2)::before {
  content: "Замечания";
}

/* Наряд в статусе «Просрочен». */
.page__jobplan--card
  .status__order--overdue
  .order__line--day_brigade
  > :nth-child(2)::before {
  content: "Просрочен";
}

/*--- Диаграмма Наряда ---*/

/* Вся область диаграммы в строке Наряда. */
.page__jobplan--card .order__line > :nth-child(5) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: 36px;
}

/* Блок дат. */
.page__jobplan--card .graph__box--order > :nth-child(1) {
  display: flex;
  justify-content: center;

  font-family: "thin";
  font-size: 12px;
}
.page__jobplan--card .graph__box--order .symbol__mdash--after::after {
  padding-right: 3px;
  padding-left: 3px;
}

/* Полоса Наряда. */
.page__jobplan--card .graph__box--order > :nth-child(2) {
  background: var(--back-order-graph);
  height: 14px;
}

/*--------------/
  Область Работ
/--------------*/

/*--- Общий контейнер ---*/

/* Скрыт по умолчанию. */
.page__jobplan--card .order__box--jobprices {
  display: none;
  /* position: relative; */
  margin-top: -2px; /* Поднимаем оюласть Работ к строке Наряда-родителя. */
  margin-bottom: 8px; /* Расстояние между областью Работ и следующим Нарядом. */
  margin-left: 34px; /* Отодвигаем фон области Работ под название Наряда. */
}

/* Выводится, если строка Наряда раскрыта. */
.page__jobplan--card .order__line.open + .order__box--jobprices {
  display: grid; /* Области определяются в role-foreman-layout.css */
}

/*--- Область замечаний ---*/

/*
	Изначально область скрыта.
	Выводится в двух случаях:
		•	Статус «Разбивка выполнена», режим редактирования
			(в статусной строке выбран пункт «Замечания по разбивке»).
		•	Статус «Замечания по разбивке», по Наряду есть замечание
			(замечания только просматриваются).
*/

.page__jobplan--card .order__area--adjust {
  display: none;
}

.page__jobplan--card.status__jobplan--done.mode__edit .order__area--adjust,
.page__jobplan--card .status__order--adjust + div .order__area--adjust {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  height: 100%;
  padding-right: 1px;
  padding-left: 8px;
}

/* Заголовок. */
.page__jobplan--card .order__area--adjust > div {
  width: 100%;
  padding: 2px 0 2px;
  text-align: center;
  background: var(--text-head);
  color: var(--back);
  font-family: "thin";
  font-size: 12px;
}

/* Поле ввода текста. */
.page__jobplan--card .order__area--adjust textarea {
  width: 100%;
  height: 100%;
  resize: vertical;

  padding: 2px;
  background: var(--back);
  font-family: "thin";
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
}
.page__jobplan--card .order__area--adjust textarea:focus {
  background: var(--gray-light);
  cursor: text;
}

/*
	Поле ввода текста недоступно:
	у тега атрибут «readonly».
*/
.page__jobplan--card .order__area--adjust textarea:read-only {
  cursor: not-allowed;
}
.page__jobplan--card .order__area--adjust textarea:read-only:focus {
  background: var(--gray-93);
  cursor: default;
}

/*-------/
  Работа
/-------*/

/*--- Компоновка ---*/

.page__jobplan--card .jobprice__line {
  display: grid;
  grid-template-columns: 8px auto 68px 50px 60px 70px;
  column-gap: 8px;
  grid-template-rows: max-content;
  grid-template-areas: "child-1 child-2 child-3 child-4 child-5 child-6";
  align-items: center;
}

.page__jobplan--card .jobprice__line > :nth-child(1) {
  grid-area: child-1;
  align-self: start;
} /* Маркер. */
.page__jobplan--card .jobprice__line > :nth-child(2) {
  grid-area: child-2;
} /* Работа. */
.page__jobplan--card .jobprice__line > :nth-child(3) {
  grid-area: child-3;
  justify-self: end;
} /* Количество. */
.page__jobplan--card .jobprice__line > :nth-child(4) {
  grid-area: child-4;
} /* Единица. */
.page__jobplan--card .jobprice__line > :nth-child(5) {
  grid-area: child-5;
  justify-self: end;
} /* Цена. */
.page__jobplan--card .jobprice__line > :nth-child(6) {
  grid-area: child-6;
  justify-self: end;
} /* Стоимость. */

/*--- Работа: Строка ---*/

.page__jobplan--card .jobprice__line {
  padding: 6px 0;
  font-size: 12px;
}

.page__jobplan--card .jobprice__line div {
  /* margin-bottom: 8px; */
  line-height: 14px;
}
