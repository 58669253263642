.header {
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 12px 240px auto 300px auto 300px 12px;
  grid-template-rows: 42px auto;
  grid-template-areas:
		". area-1 area-1 area-1 area-1 area-1 ."
		". area-2 .      area-3 .      area-4 .";

  & > *:nth-child(1) { grid-area: area-1; }   /* Заголовки. */
  & > *:nth-child(2) { grid-area: area-2; }   /* Даты. */
  & > *:nth-child(3) { grid-area: area-3; }   /* Перечислено. */
  & > *:nth-child(4) { grid-area: area-4; }   /* Получено. */

  /* Панель заголовков. */
  & > *:nth-child(1) {
    margin-bottom: 6px;
    border-bottom: solid 1px var(--back-nav);
  }
}

.headerCaptions {
  display: grid;
  grid-template-columns: 240px auto 300px auto 300px;
  /* grid-template-rows: auto; */
  grid-template-areas:
		"area-1 . area-2 . area-3";
  align-items: center;
  justify-items: center;

  & > *:nth-child(1) { grid-area: area-1; }   /* «Период». */
  & > *:nth-child(2) { grid-area: area-2; }   /* «Перечислено». */
  & > *:nth-child(3) { grid-area: area-3; }   /* «Получено». */

  /* Заголовки. */
  & > div {
    font-family: 'thin';
    font-size: 16px;
  }
}

.headerPeriod {
  display: grid;
  grid-template-columns: 52px 170px;
  grid-template-rows: auto auto;
  grid-template-areas:
		"area-1 area-2"
		"area-3 area-4";
  align-items: center;

  & > *:nth-child(1) { grid-area: area-1; }   /* «С». */
  & > *:nth-child(2) { grid-area: area-2; }   /* Дата. */
  & > *:nth-child(3) { grid-area: area-3; }   /* «По». */
  & > *:nth-child(4) { grid-area: area-4; }   /* Дата. */

  /* Надписи к полям. */
  & > *:nth-child(1),
  & > *:nth-child(3) {
    justify-self: end;
  }

  /* Даты. */
  & > *:nth-child(2),
  & > *:nth-child(4) {
    justify-self: center;
  }

  /* Поля дат. */
  &Field {
    width: 120px;
  }
}

.headerTransfers {
  display: grid;
  grid-template-columns: 190px 110px;
  grid-template-rows: repeat(3, 20px) 24px;
  grid-template-areas:
		"area-1 area-2"
		"area-3 area-4"
		"area-5 area-6"
		"area-7 area-8";
  align-items: center;
  justify-items: end;
  
  & > *:nth-child(1) { grid-area: area-1; }   /* «Оплата …» */
  & > *:nth-child(2) { grid-area: area-2; }   /* Оплата. */
  & > *:nth-child(3) { grid-area: area-3; }   /* «Аванс …» */
  & > *:nth-child(4) { grid-area: area-4; }   /* Аванс. */
  & > *:nth-child(5) { grid-area: area-5; }   /* «Аванс …» */
  & > *:nth-child(6) { grid-area: area-6; }   /* Аванс. */
  & > *:nth-child(7) { grid-area: area-7; }   /* «Итого». */
  & > *:nth-child(8) { grid-area: area-8; }   /* Итого. */

  /* Строка «Итого». */
  & > *:nth-child(7),
  & > *:nth-child(8) {
    align-self: end;
    font-family: 'bold';
  }
}

.headerReceipts {
  display: grid;
  grid-template-columns: 190px 110px;
  grid-template-rows: repeat(3, 20px) 24px;
  grid-template-areas:
		"area-1 area-2"
		"area-3 area-4"
		"area-5 area-6"
		"area-7 area-8";
  align-items: center;
  justify-items: end;

  & > *:nth-child(1) { grid-area: area-1; }   /* «Услуги …» */
  & > *:nth-child(2) { grid-area: area-2; }   /* Услуги. */
  & > *:nth-child(3) { grid-area: area-3; }   /* «Закрыто …» */
  & > *:nth-child(4) { grid-area: area-4; }   /* Закрыто. */
  & > *:nth-child(5) { grid-area: area-5; }   /* «Возвраты …» */
  & > *:nth-child(6) { grid-area: area-6; }   /* Возвраты. */
  & > *:nth-child(7) { grid-area: area-7; }   /* «Итого». */
  & > *:nth-child(8) { grid-area: area-8; }   /* Итого. */

  /* Строка «Итого». */
  & > *:nth-child(7),
  & > *:nth-child(8) {
    align-self: end;
    font-family: 'bold';
  }
}

.logo {
  display: inline-flex;
  vertical-align: middle;
  margin-top: -2px;

  img {
    width: 18px;
    height: auto;
  }
}