.fileSizeErrorView {
    width: 450px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

.button {
    margin-bottom: 5px;
}

.messageContainer {
    margin: 5px;
}