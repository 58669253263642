/*================================/
  Домашняя страница (.page__home)
/================================*/

/*-----------------------------/
  Компоновка содержания (main)
/-----------------------------*/

/*
	3-я строка (574px) не даёт фоновой картинке вылезать под футером
	при прокрутке в маленьком по высоте окне браузера.
*/

.page__home > main {
  display: grid;
  grid-template-columns: 356px auto;
  grid-template-rows: 52px 52px 574px auto;
  grid-template-areas:
    ".       .      "
    "child-1 child-2"
    "child-1 child-3"
    "child-1 .      ";
}

.page__home > main :nth-child(1) {
  grid-area: child-1;
} /* Текст баннера. */
.page__home > main :nth-child(2) {
  grid-area: child-2;
  align-self: center;
  justify-self: end;
}
.page__home > main :nth-child(3) {
  grid-area: child-3;
  align-self: start;
  justify-self: end;
}

/*--- Позиционирование ---*/

/* Потомки <main> позиционирются внутри области «main». */
.page__home > main {
  position: relative;
}

/*---------/
  Элементы
/---------*/

/*--- Фоновая картинка ---*/

.page__home .pic__back {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 120px;
  mix-blend-mode: multiply;
}

.page__home .pic__back img {
  width: 100%;
}

/*--- Рекламный баннер ---*/

.page__home > main > :nth-child(1) {
  text-align: center;
}

.page__home > main > :nth-child(1) > :nth-child(1) {
  font-size: 32px;
  line-height: 32px;
}

.page__home > main > :nth-child(1) > :nth-child(2) {
  font-size: 17px;
  line-height: 19px;
}

/*--- Кнопки ---*/

.page__home button {
  width: 172px;
}
