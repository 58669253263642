.statusBar {
  position: sticky;
  z-index: 1;
  width: 1240px;
  border-top: solid 1px var(--back);
  border-bottom: solid 1px var(--back);
  color: var(--back);
  display: grid;
  grid-template-columns: 182px 325px 95px 70px 10px auto 10px 70px;
  column-gap: 8px;
  grid-template-rows: 36px;
  grid-template-areas:
		"child-1 child-2 child-3 child-4 child-5 child-6 child-7 child-8";
  align-items: center;
  top: 121px;
  margin-bottom: 10px;
  border-bottom: solid 2px var(--back);
  background: var(--text-head);

  & > *:nth-child(1) { grid-area: child-1; }
  & > *:nth-child(2) { grid-area: child-2; }
  & > *:nth-child(3) { grid-area: child-3; justify-self: end;    }
  & > *:nth-child(4) { grid-area: child-4; justify-self: center; }
  & > *:nth-child(5) { grid-area: child-5; justify-self: start; }
  & > *:nth-child(6) { grid-area: child-6; justify-self: center; }
  & > *:nth-child(7) { grid-area: child-7; justify-self: end; }
  & > *:nth-child(8) { grid-area: child-8; justify-self: center; }
  

  & > *:nth-child(1) {
    margin-left: 6px;
    border: solid 1px;
    padding: 1px 6px 2px;
    text-align: center;
    font-family: 'bold';
  }

  & > *:nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  & a {
    display: inline-block;
    margin-bottom: 4px;
    border-bottom: dotted 1px;
    font-family: 'thin';
    font-size: 12px;
  }

  & > *:nth-child(3) {
    font-family: 'bold';
  }

  & > *:nth-child(4),
  & > *:nth-child(8) {
    font-family: 'thin';
  }

  .boundProblem{
    width: 12px;
    height: 18px;
    background: var(--red-site);
    text-align: center;
    font-family: "bold";
    font-size: 12px;
    color: var(--back);
  }

  & > *:nth-child(6) {
    display: flex;
    gap: 20px;
  }
}
.noChange {
  font-size: 12px;
  color: rgb(196, 196, 196);
  font-family: 'thin';
}
