.infoPage {
  margin-left: 20px;
}

.caption {
  margin-top: 40px;
}

.link {
  display: block;
  margin-top: 20px;
}

.qr {
  margin-top: 40px;
  background: url('./qr.png');
  height: 280px;
  width: 280px;
}