.areaDataRight{
    grid-area: area-2;
    display: grid;
    grid-template-columns: 140px auto;
    grid-template-rows: 24px 48px auto;
    grid-template-areas:
        "area-1 area-2"
        "area-3 area-3"
        "area-4 area-4";
    align-items: center;
}

.areaDataRight > :nth-child(1) {
    font-family: 'thin';
    grid-area: area-1;
}

.areaDataRight > :nth-child(2) {
    grid-area: area-2;
}

.areaDataRight > :nth-child(3) {
    grid-area: area-3;
    align-self: end;
    width: 100%;
    background: var(--back);
    padding: 3px 0 4px;
    text-align: center;
    font-family: 'thin';
    font-size: 16px;
}

.areaDataRight > :nth-child(4) {
    grid-area: area-4;
}

.foreman_row {
    display: grid;
    grid-template-columns: auto 240px 140px;
    grid-template-rows: repeat(2, 18px);
    grid-template-areas:
        "area-1 area-3 area-4"
        "area-2 area-3 area-4";
}

.foreman_row:not(:last-child) {
    border-bottom: solid 1px var(--back);
    padding-bottom: 4px;
}

.foreman_row > :nth-child(1) {
    grid-area: area-1;
    align-self: end;
    line-height: 10px;
}

.foreman_row > :nth-child(2) {
    grid-area: area-2;
    align-self: start;
    font-size: 12px;
}

.foreman_row > :nth-child(3) {
    grid-area: area-3;
    align-self: center;
    justify-self: center;
}

.foreman_row > :nth-child(4) {
    grid-area: area-4;
    align-self: center;
    justify-self: end;
}

