.imageView{
    width: 90vw;
    height: 90vh;
}

.imgContainer {
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: auto;
}

.toolsContainer, .zoomTools {
    display: flex;
}

.toolsContainer {
    justify-content: space-between;
    margin: 8px;
}

.zoomTools > button {
    width: 40px;
}

.pageLabel {
    line-height: 29px;
    margin-right: 10px;
}

.pageNumInput {
    width: 50px;
}