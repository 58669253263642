/*=================================================/
  Страница списка Объектов (.page__building--list)
/=================================================*/

/*------------------------/
  Строка Объекта свёрнута
/------------------------*/

/*--- Данные Объекта ---*/

.page__building--list .building__box:not(.open) .data-building__area {
  display: grid;
  grid-template-columns: 16px 224px 170px repeat(5, auto);
  /* grid-template-rows: auto; */
  grid-template-areas:
    "child-1 child-2 child-4 child-5 child-6 child-7 child-8 child-9"
    ".       child-3 child-4 child-5 child-6 child-7 child-8 child-9";
  align-items: center;
}

.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(1) {
  grid-area: child-1;
} /* Переключатель. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(2) {
  grid-area: child-2;
} /* Название. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(3) {
  grid-area: child-3;
} /* Адрес. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(4) {
  grid-area: child-4;
} /* Даты. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(5) {
  grid-area: child-5;
} /* Сумма: Всего. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(6) {
  grid-area: child-6;
} /* Сумма: Остаток. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(7) {
  grid-area: child-7;
} /* Сумма: В работе. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(8) {
  grid-area: child-8;
} /* Сумма: Выполнено. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(9) {
  grid-area: child-9;
} /* Сметы. */

/*--- child-4: Даты (вложенный grid) ---*/

.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(4) {
  display: grid;
  grid-template-columns: auto 30px auto;
  grid-template-areas: "child-1 child-2 child-3";
  align-items: center;
}

.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(4)
  > :nth-child(1) {
  grid-area: child-1;
  justify-self: end;
} /* Дата. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(4)
  > :nth-child(2) {
  grid-area: child-2;
  justify-self: center;
} /* Тире. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(4)
  > :nth-child(3) {
  grid-area: child-3;
  justify-self: start;
} /* Дата. */

/*--- child-5 … child-8: Суммы (вложенный grid) ---*/

.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(n + 5):not(:nth-child(n + 9)) {
  display: grid;
  /* grid-template-columns: max-content min-content auto; */
  grid-template-areas:
    "child-1"
    "child-2";
  align-items: center;
  justify-items: center;
}

.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(n + 5):not(:nth-child(n + 9))
  > :nth-child(1) {
  grid-area: child-1;
} /* Надпись. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(n + 5):not(:nth-child(n + 9))
  > :nth-child(2) {
  grid-area: child-2;
} /* Сумма. */

/*--- child-9: Смет (вложенный grid) ---*/

.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(9) {
  display: grid;
  grid-template-columns: auto 40px auto;
  grid-template-areas:
    "child-1 child-1 child-1"
    "child-2 child-3 child-4";
  align-items: center;
  justify-items: center;
}

.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(9)
  > :nth-child(1) {
  grid-area: child-1;
} /* Надпись. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(9)
  > :nth-child(2) {
  grid-area: child-2;
  justify-self: end;
} /* Число. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(9)
  > :nth-child(3) {
  grid-area: child-3;
} /* Черта. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  > :nth-child(9)
  > :nth-child(4) {
  grid-area: child-4;
  justify-self: start;
} /* Число. */

/*--------------------------/
  Строка Объекта развёрнута
/--------------------------*/

/*--- Бокс всего Объекта ---*/

/* Весь Бокс делится на две области-колонки: данные Объекта / список Смет. */
.page__building--list .building__box.open {
  display: grid;
  grid-template-columns: 240px 12px auto;
  grid-template-rows: auto;
  grid-template-areas: "child-1 . child-2";
  align-items: start;
  justify-content: start;
}

.page__building--list .building__box.open > :nth-child(1) {
  grid-area: child-1;
} /* Данные Объекта. */
.page__building--list .building__box.open > :nth-child(2) {
  grid-area: child-2;
} /* Список Смет. */

/*--- Данные Объекта ---*/

.page__building--list .building__box.open .data-building__area {
  display: grid;
  grid-template-columns: 16px 222px; /* Всего: <= 240px*/
  grid-template-rows: auto auto 40px 16px 16px 16px 16px 40px 24px auto;
  grid-template-areas:
    "child-1 child-2 "
    ".       child-3 "
    ".       child-4 "
    ".       child-5 "
    ".       child-6 "
    ".       child-7 "
    ".       child-8 "
    ".       child-9 "
    ".       child-10"
    ".       .       ";
  align-items: center;
}

.page__building--list .building__box.open .data-building__area > :nth-child(1) {
  grid-area: child-1;
} /* Переключатель. */
.page__building--list .building__box.open .data-building__area > :nth-child(2) {
  grid-area: child-2;
} /* Название. */
.page__building--list .building__box.open .data-building__area > :nth-child(3) {
  grid-area: child-3;
} /* Адрес. */
.page__building--list .building__box.open .data-building__area > :nth-child(4) {
  grid-area: child-4;
} /* Даты. */
.page__building--list .building__box.open .data-building__area > :nth-child(5) {
  grid-area: child-5;
} /* Сумма: Всего. */
.page__building--list .building__box.open .data-building__area > :nth-child(6) {
  grid-area: child-6;
} /* Сумма: Остаток. */
.page__building--list .building__box.open .data-building__area > :nth-child(7) {
  grid-area: child-7;
} /* Сумма: В работе. */
.page__building--list .building__box.open .data-building__area > :nth-child(8) {
  grid-area: child-8;
} /* Сумма: Выполнено. */
.page__building--list .building__box.open .data-building__area > :nth-child(9) {
  grid-area: child-9;
} /* Сметы. */
.page__building--list
  .building__box.open
  .data-building__area
  > :nth-child(10) {
  grid-area: child-10;
} /* Кнопка «Новая смета». */

/*--- child-4: Даты (вложенный grid) ---*/

.page__building--list .building__box.open .data-building__area > :nth-child(4) {
  display: grid;
  grid-template-columns: 90px 44px 70px;
  grid-template-areas: "child-1 child-2 child-3";
  align-items: center;
  justify-items: end;
}

.page__building--list
  .building__box.open
  .data-building__area
  > :nth-child(4)
  > :nth-child(1) {
  grid-area: child-1;
} /* Дата. */
.page__building--list
  .building__box.open
  .data-building__area
  > :nth-child(4)
  > :nth-child(2) {
  grid-area: child-2;
  justify-self: center;
} /* Тире. */
.page__building--list
  .building__box.open
  .data-building__area
  > :nth-child(4)
  > :nth-child(3) {
  grid-area: child-3;
} /* Дата. */

/*--- child-5 … child-8: Суммы (вложенный grid) ---*/

.page__building--list
  .building__box.open
  .data-building__area
  > :nth-child(n + 5):not(:nth-child(n + 9)) {
  display: grid;
  grid-template-columns: 90px 114px;
  grid-template-areas: "child-1 child-2";
  align-items: center;
  justify-items: end;
}

.page__building--list
  .building__box.open
  .data-building__area
  > :nth-child(n + 5):not(:nth-child(n + 9))
  > :nth-child(1) {
  grid-area: child-1;
} /* Надпись. */
.page__building--list
  .building__box.open
  .data-building__area
  > :nth-child(n + 5):not(:nth-child(n + 9))
  > :nth-child(2) {
  grid-area: child-2;
} /* Сумма. */

/*--- child-9: Смет (вложенный grid) ---*/

.page__building--list .building__box.open .data-building__area > :nth-child(9) {
  display: grid;
  grid-template-columns: 90px 69px 20px 25px;
  grid-template-areas: "child-1 child-2 child-3 child-4";
  align-items: center;
  justify-items: end;
}

.page__building--list
  .building__box.open
  .data-building__area
  > :nth-child(9)
  > :nth-child(1) {
  grid-area: child-1;
} /* Надпись. */
.page__building--list
  .building__box.open
  .data-building__area
  > :nth-child(9)
  > :nth-child(2) {
  grid-area: child-2;
} /* Число. */
.page__building--list
  .building__box.open
  .data-building__area
  > :nth-child(9)
  > :nth-child(3) {
  grid-area: child-3;
} /* Черта. */
.page__building--list
  .building__box.open
  .data-building__area
  > :nth-child(9)
  > :nth-child(4) {
  grid-area: child-4;
} /* Число. */

/*----------------------------------/
  Строка «жёлтой» Сметы (Расчёт КП)
/----------------------------------*/

/*--- status__estimate--offer-{статус} ---*/

.page__building--list .estimate__line[class*="--offer-"] {
  display: grid;
  grid-template-columns: 240px 210px 260px;
  /* grid-template-rows: *px; */
  grid-template-areas:
    "child-1 child-3 child-5"
    "child-2 child-4 child-5";
}

.page__building--list .estimate__line[class*="--offer-"] > :nth-child(1) {
  grid-area: child-1;
} /* Название. */
.page__building--list .estimate__line[class*="--offer-"] > :nth-child(2) {
  grid-area: child-2;
} /* Примечание. */
.page__building--list .estimate__line[class*="--offer-"] > :nth-child(3) {
  grid-area: child-3;
} /* Тип. */
.page__building--list .estimate__line[class*="--offer-"] > :nth-child(4) {
  grid-area: child-4;
} /* Статус. */
.page__building--list .estimate__line[class*="--offer-"] > :nth-child(5) {
  grid-area: child-5;
  align-self: center;
} /* Информация. */

/*--- child-5: Информация (вложенный grid): Статус «КП на согласовании» ---*/

.page__building--list
  .estimate__line[class*="--offer-to-ratify"]
  > :nth-child(5) {
  display: grid;
  grid-template-columns: 140px auto;
  grid-template-areas:
    "child-1 child-3"
    "child-2 child-4";
  justify-items: center;
}

.page__building--list
  .estimate__line[class*="--offer-to-ratify"]
  > :nth-child(5)
  > :nth-child(1) {
  grid-area: child-1;
  align-self: end;
} /* Надпись. */
.page__building--list
  .estimate__line[class*="--offer-to-ratify"]
  > :nth-child(5)
  > :nth-child(2) {
  grid-area: child-2;
  align-self: start;
} /* Сумма. */
.page__building--list
  .estimate__line[class*="--offer-to-ratify"]
  > :nth-child(5)
  > :nth-child(3) {
  grid-area: child-3;
  align-self: end;
} /* Кнопка. */
.page__building--list
  .estimate__line[class*="--offer-to-ratify"]
  > :nth-child(5)
  > :nth-child(4) {
  grid-area: child-4;
  align-self: start;
} /* Кнопка. */

/*---
	child-5: Информация (вложенный grid).
	Статусы: «Запрос КП», «Расчёт КП», «Запрос КП (повторно)», «КП принято», «КП отклонено».
---*/

.page__building--list .estimate__line[class*="--offer-query"] > :nth-child(5),
.page__building--list .estimate__line[class*="--offer-calc"] > :nth-child(5),
.page__building--list .estimate__line[class*="--offer-requery"] > :nth-child(5),
.page__building--list .estimate__line[class*="--offer-accept"] > :nth-child(5),
.page__building--list .estimate__line[class*="--offer-cancel"] > :nth-child(5) {
  display: grid;
  /* grid-template-columns: auto; */
  grid-template-areas:
    "child-1"
    "child-2";
  justify-items: center;
}

.page__building--list
  .estimate__line[class*="--offer-query"]
  > :nth-child(5)
  > :nth-child(1),
.page__building--list
  .estimate__line[class*="--offer-calc"]
  > :nth-child(5)
  > :nth-child(1),
.page__building--list
  .estimate__line[class*="--offer-requery"]
  > :nth-child(5)
  > :nth-child(1),
.page__building--list
  .estimate__line[class*="--offer-accept"]
  > :nth-child(5)
  > :nth-child(1),
.page__building--list
  .estimate__line[class*="--offer-cancel"]
  > :nth-child(5)
  > :nth-child(1) {
  grid-area: child-1;
  align-self: end;
} /* Надпись. */

.page__building--list
  .estimate__line[class*="--offer-query"]
  > :nth-child(5)
  > :nth-child(2),
.page__building--list
  .estimate__line[class*="--offer-calc"]
  > :nth-child(5)
  > :nth-child(2),
.page__building--list
  .estimate__line[class*="--offer-requery"]
  > :nth-child(5)
  > :nth-child(2),
.page__building--list
  .estimate__line[class*="--offer-accept"]
  > :nth-child(5)
  > :nth-child(2),
.page__building--list
  .estimate__line[class*="--offer-cancel"]
  > :nth-child(5)
  > :nth-child(2) {
  grid-area: child-2;
  align-self: start;
} /* Дата/Сумма. */

/*----------------------------------/
  Строка «зелёной» Сметы (Разбивка)
/----------------------------------*/

/*--- status__jobplan--{статус} ---*/

.page__building--list .estimate__line[class*="__jobplan--"] {
  display: grid;
  grid-template-columns: 240px 210px repeat(4, 80px) 208px;
  /* grid-template-rows: *px; */
  grid-template-areas:
    "child-1 child-4 child-7 child-8 child-9 child-10 child-11"
    "child-2 child-5 child-7 child-8 child-9 child-10 child-11"
    "child-3 child-6 child-7 child-8 child-9 child-10 child-11";
  align-items: center;
}

/* (1) смета; (2) примечание; */
.page__building--list .estimate__line[class*="__jobplan--"] > :nth-child(1) {
  grid-area: child-1;
} /* Название. */
.page__building--list .estimate__line[class*="__jobplan--"] > :nth-child(2) {
  grid-area: child-2;
} /* Примечание. */
.page__building--list .estimate__line[class*="__jobplan--"] > :nth-child(3) {
  grid-area: child-3;
} /* Прораб. */

.page__building--list .estimate__line[class*="__jobplan--"] > :nth-child(4) {
  grid-area: child-4;
} /* Тип. */
.page__building--list .estimate__line[class*="__jobplan--"] > :nth-child(5) {
  grid-area: child-5;
} /* Статус. */
.page__building--list .estimate__line[class*="__jobplan--"] > :nth-child(6) {
  grid-area: child-6;
} /* Даты. */

.page__building--list .estimate__line[class*="__jobplan--"] > :nth-child(7) {
  grid-area: child-7;
} /* Сумма: Всего. */
.page__building--list .estimate__line[class*="__jobplan--"] > :nth-child(8) {
  grid-area: child-8;
} /* Сумма: Остаток. */
.page__building--list .estimate__line[class*="__jobplan--"] > :nth-child(9) {
  grid-area: child-9;
} /* Сумма: В работе. */
.page__building--list .estimate__line[class*="__jobplan--"] > :nth-child(10) {
  grid-area: child-10;
} /* Сумма: Выполнено. */

.page__building--list .estimate__line[class*="__jobplan--"] > :nth-child(11) {
  grid-area: child-11;
} /* Информация. */

/*--- child-7 … child-10: Суммы (вложенный grid) ---*/

.page__building--list
  .estimate__line[class*="__jobplan--"]
  > :nth-child(n + 7):not(:nth-child(11)) {
  display: grid;
  /* grid-template-columns: max-content min-content auto; */
  grid-template-areas:
    "child-1"
    "child-2";
  justify-items: center;
}

.page__building--list
  .estimate__line[class*="__jobplan--"]
  > :nth-child(n + 7):not(:nth-child(11))
  > :nth-child(1) {
  grid-area: child-1;
  align-self: end;
} /* Надпись. */
.page__building--list
  .estimate__line[class*="__jobplan--"]
  > :nth-child(n + 7):not(:nth-child(11))
  > :nth-child(2) {
  grid-area: child-2;
  align-self: start;
} /* Сумма. */

/*--- child-11: Информация (вложенный grid): Статус «Разбивка выполнена» ---*/

.page__building--list .estimate__line[class*="jobplan--done"] > :nth-child(11) {
  display: grid;
  grid-template-columns: 38px 60px auto;
  grid-template-areas: "child-1 child-2 child-3";
  align-items: center;
}

.page__building--list
  .estimate__line[class*="jobplan--done"]
  > :nth-child(11)
  > :nth-child(1) {
  grid-area: child-1;
} /* Надпись. */
.page__building--list
  .estimate__line[class*="jobplan--done"]
  > :nth-child(11)
  > :nth-child(2) {
  grid-area: child-2;
} /* Дата. */
.page__building--list
  .estimate__line[class*="jobplan--done"]
  > :nth-child(11)
  > :nth-child(3) {
  grid-area: child-3;
  justify-self: end;
} /* Кнопка. */

/*---
	child-11: Информация (вложенный grid).
	Статусы: «Запрос разбивки», «Запрос разбивки (оплачено)», «Разбивка утверждена».
---*/

.page__building--list .estimate__line[class*="jobplan--query"] > :nth-child(11),
.page__building--list
  .estimate__line[class*="jobplan--query-pay"]
  > :nth-child(11),
.page__building--list
  .estimate__line[class*="jobplan--accept"]
  > :nth-child(11) {
  display: grid;
  /* grid-template-columns: max-content min-content auto; */
  grid-template-areas:
    "child-1"
    "child-2";
  justify-items: center;
}

.page__building--list
  .estimate__line[class*="jobplan--query"]
  > :nth-child(11)
  > :nth-child(1),
.page__building--list
  .estimate__line[class*="jobplan--query-pay"]
  > :nth-child(11)
  > :nth-child(1),
.page__building--list
  .estimate__line[class*="jobplan--accept"]
  > :nth-child(11)
  > :nth-child(1) {
  grid-area: child-1;
  align-self: end;
} /* Надпись. */

.page__building--list
  .estimate__line[class*="jobplan--query"]
  > :nth-child(11)
  > :nth-child(2),
.page__building--list
  .estimate__line[class*="jobplan--query-pay"]
  > :nth-child(11)
  > :nth-child(2),
.page__building--list
  .estimate__line[class*="jobplan--accept"]
  > :nth-child(11)
  > :nth-child(2) {
  grid-area: child-2;
  align-self: start;
} /* Дата. */

/*----------------------------/
  Бокс (строка) всего Объекта
/----------------------------*/

/*--- Строка Объекта ---*/

.page__building--list .building__box {
  margin-bottom: 8px;
  border: solid 1px var(--gray-67);
  border-left-width: 8px;
  background: var(--back-building-jobplan);
  padding-top: 8px;
  padding-bottom: 8px;
}

.page__building--list .building__box.no-jobplan {
  background: var(--back-building-close);
}

/* Строка Объекта развёрнута. */
.page__building--list .building__box.open {
  border-left-color: var(--back-nav);
  background: var(--back-building-open);
  padding-bottom: 0;
}

/*--- Индикатор наличия уведомлений ---*/

/* .page__building--list .alarm::before {
  position: absolute;
  width: 12px;
  height: 18px;
  background: var(--red-site);
  text-align: center;
  font-family: "bold";
  font-size: 12px;
  color: var(--back);
  content: "!";
} */

/* Позиционируем относительно строки Объекта и строки Сметы. */
.page__building--list .building__box,
.page__building--list .open .estimate__line {
  position: relative;
  cursor: pointer;
}

/* В развёрнутой строке Объекта не выводим (выводим в строках Смет). */
.page__building--list .building__box.open.alarm::before {
  content: none;
}

/* Позиционируем индикатор в строке Объекта. */
.page__building--list .building__box.alarm::before {
  top: 18px;
  left: -10px;
}

/* Позиционируем индикатор в строке Сметы. */
.page__building--list .open .estimate__line.alarm::before {
  top: 0;
  left: -12px;
}

/*--- Кнопки в боксе Объекта ---*/

/* Строка Объекта свёрнута — не выводим. */
.page__building--list .building__box button.bttn__small {
  display: none;
}

/* Строка Объекта развёрнута. */
.page__building--list .building__box.open button.bttn__small {
  display: flex; /* flex — возвращаем исходное значение. */
  width: 92px;
}

/*-----------------------/
  Область данных Объекта
/-----------------------*/

/*--- Название: ссылка ---*/

/* Делаем подчёркивание видимым. */
.page__building--list .data-building__area > :nth-child(2) {
  padding-bottom: 1px;
}

/* Подчёркивание. */
.page__building--list .data-building__area > :nth-child(2) a {
  border-bottom: dotted 1px;
}
.page__building--list .data-building__area > :nth-child(2) a:hover {
  border-bottom-style: solid;
}

/*--- Адрес ---*/

.page__building--list .data-building__area > :nth-child(3) {
  font-family: "thin";
  font-size: 12px;
}

/*--- Строка свёрнута ---*/

/* Ячейки, начиная с 5-й: Убираем двоеточие. */
.page__building--list
  .building__box:not(.open)
  .data-building__area
  .symbol__colon--after::after {
  display: none;
}

/*--- Строка развёрнута ---*/

/* Название Объекта и Адрес (выводим весь текст). */
.page__building--list
  .building__box.open
  .data-building__area
  > .limit__text--length {
  white-space: normal;
  padding-right: 0;
}

/* Адрес (расстояние между строк). */
.page__building--list .building__box.open .data-building__area > :nth-child(3) {
  margin-top: 8px;
  line-height: 14px;
}

/* Кнопка «Новая смета». */
.page__building--list .building__box.open .data-building__area > :last-child {
  margin-bottom: 8px;
}

/*--- Кнопка-переключатель (треугольник в круге) ---*/

/* Положение в области данных Объекта. */
.page__building--list .data-building__area span.circle-triangle {
  top: -14px;
  left: -13px;
}

/*--------------------/
  Область списка Смет
/--------------------*/

/* Изначально область скрыта (строка Объекта свёрнута). */
.page__building--list .estimate__area {
  display: none;
}

/* Строка Объекта развёрнута. */
.page__building--list .open .estimate__area {
  display: block;
}

/*--------------------/
  Cтрока Сметы: Общее
/--------------------*/

.page__building--list .open .estimate__line {
  margin-bottom: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
}

/* Все области. */
.page__building--list .open .estimate__line > :nth-child(n) {
  padding-right: 6px;
  padding-left: 6px;
}

/* Название Сметы («зелёная»): ссылка. */
.page__building--list .estimate__line > :nth-child(1) a {
  border-bottom: dotted 1px;
  color: var(--link-hover);
}
.page__building--list .estimate__line:hover > :nth-child(1) a {
  color: var(--link-normal-bright);
  text-shadow: none;
}
.page__building--list .estimate__line > :nth-child(1) a:hover {
  border-bottom-style: solid;
  color: var(--link-hover-light);
}
.page__building--list .estimate__line > :nth-child(1) a:active {
  border-bottom-style: solid;
  color: var(--link-active);
}

/* Адрес. */
.page__building--list .estimate__line > :nth-child(2) {
  font-family: "thin";
}

/* Статус. */
.page__building--list .estimate__line[class*="--offer-"] > :nth-child(4),
.page__building--list .estimate__line[class*="__jobplan--"] > :nth-child(5) {
  font-family: "bold";
}

/*-----------------------/
  Cтрока Сметы: «Жёлтая»
/-----------------------*/

/* Строка «Жёлтых» статусов: status__estimate--offer-{статус} */

/* Фон всей строки.*/
.page__building--list [class*="--offer-"] {
  width: max-content;
  background: var(--back-estimate-offer);
}

/* Все области, цвет границы (где она есть). */
.page__building--list
  .estimate__line[class*="--offer-"]
  > :nth-child(n):not(:last-child) {
  border-right: solid 1px var(--gray-87);
}

/*--- Область информации по статусам (child-5) ---*/

/* Для статусов: «Дозапрос», «Отклонено НД» (текст сообщения от Сметчика). */
.page__building--list [class*="--offer-add-data"] > :nth-child(5),
.page__building--list [class*="--offer-no-data"] > :nth-child(5) {
  font-family: "thin-italic";
  font-size: 11px;
}

/* Для статуса: «КП на согласовании», выделение кнопок. */
.page__building--list [class*="--offer-to-ratify"] > :nth-child(5) button {
  border: solid 1px var(--gray-87);
}

/*------------------------/
  Cтрока Сметы: «Зелёная»
/------------------------*/

/*--- Строка: «Зелёных» статусов: status__jobplan--{статус} ---*/

/* Фон всей строки.*/
.page__building--list [class*="__jobplan--"] {
  background: var(--back-estimate-jobplan);
}

/* Граница справа. */
.page__building--list
  .estimate__line[class*="__jobplan--"]
  > :nth-child(n):not(:nth-child(11)) {
  border-right: solid 1px var(--gray-91);
}
/* Граница во всю высоту строки Сметы. */
.page__building--list
  .estimate__line[class*="__jobplan--"]
  > :nth-child(n + 7):not(:nth-child(11)) {
  height: 100%;
}

/*--- Область Информации (child-11) ---*/

/* Для статуса: «Замечания по разбивке» (текст сообщения от Подрядчика). */
.page__building--list [class*="__jobplan--adjust"] > :nth-child(11) {
  font-family: "thin-italic";
  font-size: 11px;
}

/* Для статуса: «Разбивка выполнена», выделение кнопок. */
.page__building--list [class*="__jobplan--done"] > :nth-child(11) button {
  border: solid 1px var(--gray-87);
}
