.createAccountModal {
  width: 480px;

  &Notification {
    width: 100%;
    border-bottom: solid 1px var(--gray-83);
    background: var(--blue48_89);
    padding-top: 12px;
    padding-bottom: 6px;
    text-align: center;
  }
  
  &Content {
    display: grid;
    grid-template-columns: 152px auto;
    grid-template-rows: 24px repeat(3, 42px) auto 42px 24px 10px repeat(1, auto);
    grid-template-areas:
		"area-1  area-1 "
		"area-2  area-2 "
		"area-3  area-4 "
		"area-5  area-6 "
    "area-7  area-7 "
		"area-8  area-9 "
		"area-10 area-11"
		"area-12 area-12"
		"area-13 area-14";
    align-items: center;
    padding: 16px 12px;

    & > *:nth-child(1)  { grid-area: area-1;  }   /* «Банк …» */
    & > *:nth-child(2)  { grid-area: area-2;  }   /* Банк. */
    & > *:nth-child(3)  { grid-area: area-3;  }   /* «БИК». */
    & > *:nth-child(4)  { grid-area: area-4;  }   /* БИК. */
    & > *:nth-child(5)  { grid-area: area-5;  }   /* «… счёт». */
    & > *:nth-child(6)  { grid-area: area-6;  }   /* Счёт. */
    & > *:nth-child(7)  { grid-area: area-7;  }   /* Ошибка */
    & > *:nth-child(8)  { grid-area: area-8;  }   /* «… счёт». */
    & > *:nth-child(9)  { grid-area: area-9;  }   /* Счёт. */
    & > *:nth-child(10)  { grid-area: area-10;  }   /* «Эскроу-счёт». */
    & > *:nth-child(11) { grid-area: area-11; }   /* Флаг. */

    & > *:nth-child(12) { grid-area: area-12; }   /* Линия. */

    & > *:nth-child(13) { grid-area: area-13; }   /* Кнопка. */
    & > *:nth-child(14) { grid-area: area-14; }   /* Кнопка. */

    /* Поля по ширине окна. */
    & input {
      width: auto;
      &.error {
        border: 1px solid red;
      }
    }

    /* Надписи банка и счетов. */
    & > *:nth-child(1),
    & > *:nth-child(3),
    & > *:nth-child(5) {
      line-height: 15px;
    }

    /* «Банк …» */
    & > *:nth-child(1) {
      justify-self: center;
      text-align: center;
    }

    /* Банк. */
    & > *:nth-child(2) {
      text-align: center;
    }

    & > *:nth-child(7) {
      color: red;
      text-align: right;
    }

    & > *:nth-child(12) {
      border-top: 1px solid lightgray;
    }

    /* Кнопка «Отмена». */
    & > *:nth-child(14) {
      justify-self: end;
    }

    /* Ширина кнопок. */
    & button {
      width: 136px;
    }

    /* Выравниваем чекбокс относительно подписи. */
    & [type="checkbox"] {
      margin-top: 10px;
    }
  }
}