.container {
  display: flex;
  flex-direction: column;
  background: #eae8e6;
  height: 480px;
  max-height: 480px;
  max-width: 780px;
  width: 780px;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: 360px;
  max-height: 117px;
  display: flex;
  flex-direction: column;
  margin-left: 14px;
}

.lagendName {
  font-family: 'thin';
  font-size: 14px;
  margin-bottom: 4px;
  width: 100%;
  background: var(--back__head-section);
  padding-top: 2px;
  padding-bottom: 3px;
  padding-left: 6px;
  color: var(--text__head-section);
}

.label {
  color: var(--text);
  font: inherit;
  margin-left: 6px;
  font-size: 14px;
}

.description {
  width: 100%;
  margin-bottom: 16px;
  border-bottom: solid 1px var(--text-head);
  text-align: center;
  background: #d5e8f0;
  padding: 10px 20px;
}

.title {
  width: 100%;
  background: var(--back-nav);
  padding-top: 6px;
  padding-bottom: 7px;
  text-align: center;
  color: var(--back);
  text-shadow: var(--shadow-contour);
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin: 26px 10px 0 10px;
}
