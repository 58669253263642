.tabs {
  display: flex;
  position: relative;
}

.tab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
  height: 34px;
  width: 100%;
  border: solid 2px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background: var(--text-head);
  border-color: var(--back);
  font-family: 'thin';
  color: var(--back);
  border-radius: 0;
  text-shadow: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
  
  &:hover:not(:disabled) {
    background: var(--back__tab-hover);
  }

  &.isActive {
    background: var(--back-main);
    border-color: var(--blue__48-53);
    font-family: 'bold';
    color: var(--text-head);
    cursor: default;

    &:hover {
      background: var(--back-main);
      border-color: var(--blue__48-53);
      color: var(--text-head);
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -4px;
      height: 4px;
      background: var(--back-main);
    }

    .statusText {
      opacity: 1;
      visibility: visible;
      transform: translateX(-50%);
    }
  }
}