.bar__status {
    display: grid;
    grid-template-columns: 12px 490px 220px auto 12px;
    grid-template-rows: 42px;
    grid-template-areas: ". area-1 area-2 . .";
    align-items: center;
    margin-bottom: 8px;
    background: var(--text-head);
    color: var(--back);
}

.bar__status > :nth-child(1) {
    grid-area: area-1;
    width: 172px;
}

.bar__status > :nth-child(2) {
    grid-area: area-2;
    
}

.statusText {
    justify-self: center;
    border: solid 1px var(--back);
    padding: 1px 6px 2px;
}

.statusConfirm{
    border-color: var(--accept-light);
}

.statusOncheck {
    background: var(--back);
    border-color: var(--error-light-2);
    color: var(--error-dark);
}

.statusUnconfirm {
    border-color: var(--error-light-2);
    background: var(--error-dark);
    color: var(--back);
}


