/* Переменные. */

/*
	Базовая цветовая схема:
	https://colorscheme.ru/#3u40ToXGWw0w0
		Основной цвет hsl(198, 68%, 51%); #2da4d7; rgb(18%, 64%, 84%);
		Тетрада; Оттенок: 219deg; Угол: -33deg.

	hsl(198, 68%, 51%); Основной тон        ~ Лазурный Крайола.
	hsl( 32, 72%, 49%); Основная инверсия   ~ Охра.
	hsl(138, 48%, 43%); Вторичный «А»       ~ Умеренно-зелёное море.
	hsl(  8, 62%, 47%); Вторичный «Б»       ~ Персидский красный.
	hsl(242, 64%, 43%); Ссылка              ~ Фиолетово-синий Крайола.

*/

:root {

  /*--- Базовые тона (hue, deg) ---*/

  --blue:   198;
  --orange:  32;
  --green:  138;
  --red:      8;
  --link:   242;

  /*--- Базовые цвета (тон, насыщенность) ---*/

  --blue-basic:   var(--blue),   68%;
  --orange-basic: var(--orange), 72%;
  --green-basic:  var(--green),  48%;
  --red-basic:    var(--red),    62%;
  --link-basic:   var(--link),   64%;

  /*--- Фирменные цвета сайта ---*/

  --blue-site:   hsl(var(--blue-basic),   51%);
  --orange-site: hsl(var(--orange-basic), 49%);
  --green-site:  hsl(var(--green-basic),  43%);
  --red-site:    hsl(var(--red-basic),    47%);
  --link-site:   hsl(var(--link-basic),   43%);



  /*--- Монохром ---*/

  --gray:   0, 0%;

  --gray-dark:  hsl(var(--gray),  7%);
  --gray-27:    hsl(var(--gray), 27%);
  --gray-37:    hsl(var(--gray), 37%);
  --gray-47:    hsl(var(--gray), 47%);
  --gray-57:    hsl(var(--gray), 57%);
  --gray-67:    hsl(var(--gray), 67%);
  --gray-73:    hsl(var(--gray), 73%);
  --gray-77:    hsl(var(--gray), 77%);
  --gray-81:    hsl(var(--gray), 81%);
  --gray-83:    hsl(var(--gray), 83%);
  --gray-85:    hsl(var(--gray), 85%);
  --gray-87:    hsl(var(--gray), 87%);
  --gray-89:    hsl(var(--gray), 89%);
  --gray-91:    hsl(var(--gray), 91%);
  --gray-93:    hsl(var(--gray), 93%);
  --gray-light: hsl(var(--gray), 97%);



  /*--- Цвета: Синий ---*/

  --blue08_95:   hsl(var(--blue),  8%, 95%);   /* --back-main */

  --blue12_77:   hsl(var(--blue), 12%, 77%);
  --blue12_81:   hsl(var(--blue), 12%, 81%);
  --blue12_87:   hsl(var(--blue), 12%, 87%);
  --blue12_89:   hsl(var(--blue), 12%, 89%);

  --blue24_51:   hsl(var(--blue), 24%, 51%);
  --blue24_83:   hsl(var(--blue), 24%, 83%);
  --blue24_89:   hsl(var(--blue), 24%, 89%);

  --blue48_89:   hsl(var(--blue), 48%, 89%);



  /*--- Цвета: Оранжевый ---*/

  --orange08_91:   hsl(var(--orange),  8%, 91%);   /* --back */
  --orange08_93:   hsl(var(--orange),  8%, 93%);

  --orange12_89:   hsl(var(--orange), 12%, 89%);

  --orange24_51:   hsl(var(--orange), 24%, 51%);
  --orange24_83:   hsl(var(--orange), 24%, 83%);

  /*--- Цвета: Зелёный ---*/

  --green24_51:   hsl(var(--green), 24%, 51%);
  --green24_89:   hsl(var(--green), 24%, 89%);



  /*--- Цвета: Красный ---*/

  --red24_51:   hsl(var(--red), 24%, 51%);
  --red24_89:   hsl(var(--red), 24%, 89%);

  --red48_51:   hsl(var(--red), 48%, 51%);







  /*--- Фон ---*/

  --back:        var(--orange08_91);   /* Основной светлый фон. */
  --back-main:   var(--blue08_95);     /* Основной светлый фон «рабочей» области. */
  --back-nav:    hsl(var(--blue-basic), 43%);   /* Фон навигационной панели. */

  --back-building-close:   hsl(var(--blue),  12%, 87%);   /* Строка Объекта свёрнута. */
  --back-building-open:    hsl(var(--blue),  12%, 93%);   /* Строка Объекта развёрнута. */
  --back-building-jobplan: hsl(var(--green), 24%, 89%);   /* Строка Объекта свёрнута, есть Разбивка. */

  --back-estimate-jobplan: hsl(var(--green),  24%, 81%);
  --back-estimate-offer:   hsl(var(--orange), 34%, 81%);



  /*--- Разбивка ---*/

  --back-chain-graph: hsl(var(--green), 14%, 69%);   /* Цепочка, полоса диаграммы. */
  --back-order-graph: hsl(var(--green), 14%, 83%);   /* Наряд, полоса диаграммы. */
  --back-order-row:  hsl(var(--green), 12%, 93%);   /* Строка Наряда. */
  --back-jobplan-box: hsl(var(--green), 14%, 89%);   /* Область Работ. */

  --back-order-launch:  hsl(var(--orange), 68%, 91%);   /* Строка Наряда в статусе «К запуску». */
  --back-order-overdue: var(--error-light);             /* Строка Наряда в статусе «Просрочен». */




  /*--- Текст ---*/

  --text:        hsl(var(--blue-basic),  19%);   /* Основной тёмный текст. */
  --text-head:   hsl(var(--blue-basic),  39%);   /* Заголовки на светлом. */
  --text-logo:   hsl(var(--orange), 46%, 87%);   /* Слова логотипа на синем. */
  --text-accept: hsl(var(--green),  96%, 31%);




  /* Строка заголовка раздела/сектора. */
  --back__head-section: var(--back-building-close);
  --text__head-section: var(--text);





  /*--- Строка списка ---*/

  /* Наведение. */
  --back__row-hover: hsl(var(--blue), 28%, 51%);
  --text__row-hover: var(--back);

  /* Выделена. */
  --back__row-highlight: hsl(var(--blue-basic), 33%);
  --text__row-highlight: var(--back-main);

  /*--- Вкладки ---*/

  /*	--back__tab-normal:  var(--text-head);   = текст заголовков. */
  /*	--back__tab-current: var(--back);        = основной фон. */
  --back__tab-normal:  hsl(var(--blue) 38% 51%);      /* Не текущая. */
  --back__tab-hover:   var(--back__row-highlight);   /* Не текущая, наведение. */

  --blue__48-53: hsl(var(--blue) 48% 53%);


  /*--- Тень ---*/

  --shadow:         1px 1px 0   var(--gray-dark);
  --shadow-light:   1px 1px 0   var(--gray-light);
  --shadow-logo:    2px 2px 4px var(--gray-dark);
  --shadow-contour: 1px 1px var(--text), -1px -1px var(--text);
  --shadow-modal:   5px 5px 11px var(--gray-27), -1px -1px var(--gray-57);


  --shadow__TR-1-dark:  1px -1px var(--gray-dark);
  --shadow__TL-1-dark: -1px -1px var(--gray-dark);
  --shadow__BR-1-dark:  1px  1px var(--gray-dark);
  --shadow__BL-1-dark: -1px  1px var(--gray-dark);

  --shadow__contour-1-dark:
          var(--shadow__TR-1-dark),
          var(--shadow__TL-1-dark),
          var(--shadow__BR-1-dark),
          var(--shadow__BL-1-dark);

  --shadow__TR-1-light:  1px -1px var(--gray-light);
  --shadow__TL-1-light: -1px -1px var(--gray-light);
  --shadow__BR-1-light:  1px  1px var(--gray-light);
  --shadow__BL-1-light: -1px  1px var(--gray-light);

  --shadow__contour-1-light:
          var(--shadow__TR-1-light),
          var(--shadow__TL-1-light),
          var(--shadow__BR-1-light),
          var(--shadow__BL-1-light);



  /*--- Граница ---*/

  /* --border: solid 1px var(--gray-57); */



  /*--- Error (красный) ---*/

  --error-dark:    var(--red-site);
  --error-light:   hsl(var(--red), 96%, 93%);
  --error-light-2: hsl(var(--red), 98%, 85%);



  /*--- Accept (зелёный) ---*/

  --accept-dark:  hsl(var(--green), 46%, 37%);
  --accept-light: hsl(var(--green), 96%, 93%);



  /*--- Элементы ---*/

  /* Данные Пользователя в верхней панели. */
  --user-box: hsl(var(--orange), 88%, 77%);

  /* Поля ввода данных. */

  --input-bord-normal: var(--gray-73);                 /* Также текст placeholder */
  --input-back-normal: hsl(var(--green-basic), 97%);
  --input-text-normal: hsl(var(--green-basic), 33%);

  --input-bord-hover: var(--gray-67);
  --input-back-hover: hsl(var(--green-basic), 95%);

  /*	--input-bord-focus: hsl(var(--green-basic), 33%);   = input-text-normal */
  /*	--input-back-focus: hsl(var(--green-basic), 97%);   = input-back-normal */

  --input-bord-disable: var(--gray-87);
  --input-back-disable: var(--gray-93);



  /* Переключатели (input radio/checkbox). */

  /*	--switch-bord-normal: var(--gray-67);   = input-bord-hover */

  /*	--switch-bord-disable:       var(--gray-77);   = input-bord-normal */
  /*	--switch-back-check-disable: var(--gray-77);   = input-bord-normal */

  --switch-shade-normal:        0 0 0 1px var(--green-site) inset;
  --switch-shade-check:         0 0 0 2px hsl(var(--green-basic), 67%) inset;
  --switch-shade-check-disable: 0 0 0 2px var(--gray-93) inset;



  /* Подпись к недоступному полю. */

  /*	--label-input-disable: var(--gray-67);   = input-bord-hover */



  /* Кнопки (вид button). */

  --button-back-normal: var(--orange-site);
  --button-text-normal: var(--gray-light);
  --button-text-normal-shade: var(--shadow);

  --button-back-hover:  hsl(var(--orange), 86%, 59%);

  --button-back-active: hsl(var(--orange-basic), 43%);

  /*	--button-back-disable: var(--gray-87);   = input-bord-disable */
  --button-text-disable: var(--gray-47);
  --button-text-disable-shade: var(--shadow-light);



  /* Ссылки: --link-… */

  --link-normal:        hsl(var(--link), 46%, 47%);   /* Обычная. */
  --link-normal-light:  hsl(var(--blue), 96%, 93%);   /* Обычная, светлая на тёмном. */
  --link-normal-bright: hsl(var(--blue), 96%, 77%);   /* Обычная, светлая на тёмном, насыщенная. */
  --link-hover:         hsl(var(--link), 96%, 57%);   /* Наведение. */
  /* --link-hover-light:   hsl(var(--link), 96%, 87%);   /* Наведение, светлая на тёмном. * / */
  --link-hover-light:   hsl(var(--link), 96%, 93%);   /* Наведение, светлая на тёмном. */
  --link-active:        hsl(var(--link), 96%, 77%);   /* Нажатие. */

}